<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>
<script>
const default_layout = "default";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout || default_layout) + "-layout";
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (to.meta.title) {
                    document.title = to.meta.title + " | Kalkin-Tourisme";
                } else {
                    document.title = "Kalkin-Tourisme";
                }
            },
        },
    },

    mounted() {
        // Used to removed potential deadlock within notification stack
        this.$store.dispatch("notify/ackMessage");
    },
};
</script>
