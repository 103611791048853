import authService from "../../services/auth";
import userService from "../../services/user";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    state: {
        loggingIn: false,
        loggedIn: false,
        user: null,
        access_token: null,
        refresh_token: null,
    },

    actions: {
        async login({ commit }, { mail, password }) {
            commit("loginRequest", { mail });
            try {
                const data = await authService.login(mail, password);
                commit("loginSuccess", data);
                notifier.success(
                    `Connecté<br/>Bienvenue ${data.user.firstName} &#x1F603`,
                );
                return true;
            } catch (error) {
                commit("loginFailure");
                APIError.handle(error);
            }
        },

        async logout({ commit }) {
            try {
                commit("logout");
            } catch (error) {
                APIError.handle(error);
            }
        },

        async refreshToken({ commit, dispatch }, token) {
            commit("refreshToken", token);
            await dispatch("getMe");
        },

        async refreshMe({ state, dispatch }) {
            if (state.access_token) {
                if (await dispatch("getMe")) {
                    return true;
                }
            }
            return false;
        },

        async resetPassword(context, { mail }) {
            try {
                await userService.resetPassword(mail);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async resetPasswordValidate(context, { mail, password, token }) {
            try {
                await userService.resetPasswordValidate(mail, password, token);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMe({ commit }) {
            try {
                const user = await userService.getMe();
                commit("setConnectedUser", user);
                return user;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMyProjectCreds(context, { projectId }) {
            try {
                return await userService.getMyProjectCreds(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getUser(context, { id }) {
            try {
                return await userService.getUser(id);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createUser(
            context,
            { mail, firstName, lastName, phone, credentials },
        ) {
            try {
                const newUser = await userService.createUser(
                    mail,
                    firstName,
                    lastName,
                    phone,
                    credentials,
                );
                notifier.success(`L'utilisateur a bien été créé`);
                return newUser;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllUsers() {
            try {
                return await userService.getAllUsers();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPUsers(context, { filter }) {
            try {
                return await userService.countFSPUsers(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPUsers(context, { filter, sortby, limit, offset }) {
            try {
                return await userService.getFSPUsers(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllFiltred(context, { filterValue }) {
            try {
                return await userService.getAllFiltered(filterValue);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteUser(context, { id }) {
            try {
                await userService.deleteUser(id);
                notifier.success(`L'utilisateur a bien été supprimé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updatePassword(context, { oldPassword, password }) {
            try {
                await userService.updateMe(oldPassword, password);
                return true;
            } catch (error) {
                APIError.handle(error);
                return false;
            }
        },

        async updateUser(
            context,
            { id, firstName, lastName, phone, credentials },
        ) {
            try {
                await userService.updateUser(
                    id,
                    firstName,
                    lastName,
                    phone,
                    credentials,
                );
                notifier.success(`L'utilisateur a bien été modifié`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMyProfile({ commit }, { firstName, lastName, phone }) {
            try {
                await userService.updateMe(
                    undefined,
                    undefined,
                    firstName,
                    lastName,
                    phone,
                );
                commit("updateConnectedUser", {
                    firstName,
                    lastName,
                    phone,
                });
                notifier.success(`Le profil a bien été mis à jour`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMyPassword(context, { oldPassword, newPassword }) {
            try {
                await userService.updateMe(oldPassword, newPassword);
                notifier.success(`Le mot de passe a bien été modifié`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPUserProjects(context, { userId, filter }) {
            try {
                return await userService.countFSPUserProjects(userId, filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPUserProjects(
            context,
            { userId, filter, sortby, limit, offset },
        ) {
            try {
                return await userService.getFSPUserProjects(
                    userId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getLastUsers(context, { n }) {
            try {
                return await userService.getLastUsers(n);
            } catch (error) {
                APIError.handle(error);
            }
        },
    },

    mutations: {
        loginRequest(state, user) {
            state.loggingIn = true;
            state.loggedIn = false;
            state.user = user;
        },
        loginSuccess(state, { user, access_token, refresh_token }) {
            state.loggingIn = false;
            state.loggedIn = true;
            state.user = user;
            state.access_token = access_token;
            state.refresh_token = refresh_token;
        },
        loginFailure(state) {
            state.loggingIn = false;
            state.loggedIn = false;
            state.user = null;
            state.access_token = null;
            state.refresh_token = null;
        },
        logout(state) {
            state.loggingIn = false;
            state.loggedIn = false;
            state.user = null;
            state.access_token = null;
            state.refresh_token = null;
        },
        refreshToken(state, token) {
            state.access_token = token;
        },
        setConnectedUser(state, user) {
            state.user = user;
        },
        updateConnectedUser(state, data) {
            Object.assign(state.user, data);
        },
    },
};
