<template>
    <div class="project-menu-item">
        <template v-if="!collapsed">
            <div
                class="help"
                v-if="
                    !projectName &&
                        (isCurrentPage('Home') || isCurrentPage('Projects'))
                "
            >
                Sélectionnez un projet ci-contre pour accéder aux différents
                paramètres de l’application
            </div>
            <div v-if="projectName">
                <div class="title">PROJET SÉLECTIONNÉ</div>
                <div
                    class="row-item"
                    :class="{
                        'link-after': configName,
                        selected: isCurrentPage('Project'),
                    }"
                    @click="goProject"
                >
                    <b-icon-folder2-open class="icon" />
                    {{ projectName }}
                </div>
                <div
                    class="row-item link-before"
                    :class="{ selected: isCurrentPage('Config') }"
                    v-if="configName"
                    @click="goConfig"
                >
                    <b-icon-display class="icon" /> {{ configName }}
                </div>
            </div>
        </template>

        <template v-else>
            <div v-if="projectName">
                <div
                    class="row-item"
                    :class="{
                        'link-after': configName,
                        selected: isCurrentPage('Project'),
                    }"
                    @click="goProject"
                >
                    <b-icon-folder2-open class="icon" />
                </div>
                <div
                    class="row-item link-before"
                    :class="{ selected: isCurrentPage('Config') }"
                    v-if="configName"
                    @click="goConfig"
                >
                    <b-icon-display class="icon" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        collapsed: Boolean,
    },

    computed: {
        projectName() {
            const currentProjectId = this.$route.params.projectId;
            const project = this.$store.state.project.project;

            if (project && project.id == currentProjectId && !this.isAdmin) {
                return project.name;
            }
            return null;
        },

        configName() {
            const currentConfigId = this.$route.params.configId;
            const project = this.$store.state.project.project;

            if (project && currentConfigId && !this.isAdmin) {
                const config = project.ApplicationConfigs.find(
                    ac => ac.id == currentConfigId,
                );
                if (config) {
                    return config.name;
                }
            }
            return null;
        },
        isAdmin() {
            return this.$route.path.includes("/admin");
        },
    },

    methods: {
        isCurrentPage(name) {
            const { route } = this.$router.resolve({ name });
            return route.path === this.$route.path;
        },

        goProject() {
            this.$router.push({
                name: "Project",
                params: {
                    projectId: this.$route.params.projectId,
                },
            });
        },

        goConfig() {
            this.$router.push({
                name: "Config",
                params: {
                    projectId: this.$route.params.projectId,
                    configId: this.$route.params.configId,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.project-menu-item {
    border-width: 1px 0 1px 0px;
    border-color: $secondary-color;
    border-style: solid;

    background-color: $theme-bg-3;
    color: $theme-fg-4;

    .title {
        margin: 10px;
        border-bottom: 1px solid $theme-fg-4;
    }

    .selected {
        box-shadow: 5px 0px 0px 0px $secondary-color inset;
    }

    .row-item {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        width: 100%;
        min-height: 25px;
        padding: 10px;
        cursor: pointer;
        .icon {
            width: 25px;
            height: 25px;
            border: 1px solid $primary-color-darker;
            border-radius: 4px;
            padding: 2px;
            background: $primary-color;
            z-index: 1;
        }
    }
    .row-item:hover {
        background-color: darken($theme-bg-3, 5%);
    }

    .link-line {
        background: $primary-color;
        width: 3px;
        height: 100%;
        position: absolute;
        left: 21px;
        z-index: 0;
    }
    .link-after::after {
        content: "";
        @extend .link-line;
        top: 50%;
    }
    .link-before::before {
        content: "";
        @extend .link-line;
        top: -50%;
    }

    .help {
        margin: 15px 10px;
        text-align: center;
        font-style: italic;
        font-size: 12px;
    }
}
</style>
