import _ from "lodash";
import axios from "axios";
import config from "../config";
import store from "../store";
import router from "../router";
import APIError from "../tools/APIError";

const HTTP = axios.create({
    baseURL: `${config.api}`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

HTTP.interceptors.request.use(function(config) {
    let access_token = _.get(store, "state.user.access_token");
    config.headers["Authorization"] = "Bearer " + access_token;
    return config;
});

HTTP.interceptors.response.use(
    response => response,
    async err => {
        const { status, errcode, details, error } = _parseHTTPError(err);

        // Pas de body pour les head, donc pas de errcode
        if (err.config.method.toLowerCase() === "head") {
            if (err.response && status === 401) {
                return await _requestNewToken(err);
            }
        }

        // On gère tous les autres cas
        switch (errcode) {
            case "expired_token":
                return await _requestNewToken(err);
            case "invalid_token":
                store.dispatch("user/logout");
                router.push({ name: "Login" }).catch(() => {});
                return Promise.reject(); //On renvoit une erreur vide
            case "credentials_too_low":
                router.push({ name: "Forbidden" }).catch(() => {});
                return Promise.reject(); //On renvoit une erreur vide
            case "missing_header":
                if (details.header === "Authorization") {
                    store.dispatch("user/logout");
                    router.push({ name: "Login" }).catch(() => {});
                }
                break; // On renvoit l'erreur originale
            default:
                if (!errcode && error.message === "Network Error") {
                    return Promise.reject(new APIError(503, "api_down"));
                }
                if (!errcode && status === 413) {
                    return Promise.reject(new APIError(413, "too_large"));
                }
                break; // On renvoit l'erreur originale
        }
        return Promise.reject(error);
    },
);

export default HTTP;

function _parseHTTPError(error) {
    const status = _.get(error, "response.status");
    const errcode = _.get(error, "response.data.meta.error");
    const details = _.get(error, "response.data.meta.details") || {};
    if (errcode) {
        error = new APIError(status, errcode, details);
    }
    return { status, errcode, details, error };
}

async function _requestNewToken(err) {
    try {
        const refreshResp = await axios({
            method: "post",
            url: `${config.api}/token/refresh`,
            data: {
                token: _.get(store, "state.user.refresh_token"),
            },
        });
        const token = _.get(refreshResp, "data.data.access_token");
        if (token) {
            await store.dispatch("user/refreshToken", token);
            err.config.headers["Authorization"] = `Bearer ${token}`;
            try {
                const redoResp = await axios(err.config);
                return Promise.resolve(redoResp);
            } catch (e) {
                const err = _parseHTTPError(e).error;
                return Promise.reject(err); // Erreur d'echec redo
            }
        }
    } catch (e) {
        const err = _parseHTTPError(e).error;
        store.dispatch("user/logout");
        router.push({ name: "Login" }).catch(() => {});
        return Promise.reject(err); // Erreur d'echec refresh
    }
}
