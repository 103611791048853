var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-menu-item"},[(!_vm.collapsed)?[(
                !_vm.projectName &&
                    (_vm.isCurrentPage('Home') || _vm.isCurrentPage('Projects'))
            )?_c('div',{staticClass:"help"},[_vm._v(" Sélectionnez un projet ci-contre pour accéder aux différents paramètres de l’application ")]):_vm._e(),(_vm.projectName)?_c('div',[_c('div',{staticClass:"title"},[_vm._v("PROJET SÉLECTIONNÉ")]),_c('div',{staticClass:"row-item",class:{
                    'link-after': _vm.configName,
                    selected: _vm.isCurrentPage('Project'),
                },on:{"click":_vm.goProject}},[_c('b-icon-folder2-open',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.projectName)+" ")],1),(_vm.configName)?_c('div',{staticClass:"row-item link-before",class:{ selected: _vm.isCurrentPage('Config') },on:{"click":_vm.goConfig}},[_c('b-icon-display',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.configName)+" ")],1):_vm._e()]):_vm._e()]:[(_vm.projectName)?_c('div',[_c('div',{staticClass:"row-item",class:{
                    'link-after': _vm.configName,
                    selected: _vm.isCurrentPage('Project'),
                },on:{"click":_vm.goProject}},[_c('b-icon-folder2-open',{staticClass:"icon"})],1),(_vm.configName)?_c('div',{staticClass:"row-item link-before",class:{ selected: _vm.isCurrentPage('Config') },on:{"click":_vm.goConfig}},[_c('b-icon-display',{staticClass:"icon"})],1):_vm._e()]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }