import HTTP from "./http-common";

export default {
    async createArboSnapshot(projectId, acaId, description) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}/arbosnapshots`,
            data: { description },
        });
        return response.data.data;
    },

    async getArboSnapshots(projectId, acaId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}/arbosnapshots`,
            params: { sortby: "-createdAt" },
        });
        return response.data.data;
    },

    async updateArboSnapshot(projectId, id, description, command) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/arbosnapshot/${id}`,
            data: { description, command },
        });
        return response.data.data;
    },

    async deleteArboSnapshot(projectId, id) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/arbosnapshot/${id}`,
        });
        return response.data.data;
    },

    async getArboSnapshot(projectId, id) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/arbosnapshot/${id}`,
        });
        return response.data.data;
    },

    async createArboBuild(projectId, builderId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/arbobuilder/${builderId}/arbobuilds`,
        });
        return response.data.data;
    },
};
