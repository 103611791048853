<template>
    <b-modal
        :id="uid"
        header-text-variant="light"
        header-bg-variant="danger"
        body-text-variant="danger"
        :title="title"
        hide-header-close
        @shown="focusConfirmButton"
    >
        <div class="d-block text-center">
            {{ message }}
        </div>
        <small class="d-block text-center">
            {{ details }}
        </small>
        <template #modal-footer>
            <b-button variant="outline-secondary" @click="onCancelled">
                Annuler
            </b-button>
            <b-button ref="confirmButton" variant="danger" @click="onConfirmed">
                Confirmer
            </b-button>
        </template>
    </b-modal>
</template>
<script>
export default {
    props: {
        uid: { type: String, default: "ModalDanger" },
        title: { type: String, default: "Attention!" },
        message: {
            type: String,
            default: "Voulez-vous vraiment effectuer cette action",
        },
        details: {
            type: String,
            default: "",
        },
    },

    methods: {
        onCancelled() {
            this.$bvModal.hide(this.uid);
            this.$emit("cancelled");
        },
        onConfirmed() {
            this.$bvModal.hide(this.uid);
            this.$emit("confirmed");
        },
        focusConfirmButton() {
            // Focus on the "Confirmer" button when the modal is shown
            this.$nextTick(() => {
                this.$refs.confirmButton.focus();
            });
        },
    },
};
</script>
