export default function statusToLabel(state) {
    return [
        "Vierge",
        "En cours",
        "Echec",
        "Terminée",
        "A supprimer",
        "Partielle",
    ][state];
}
