import datasourceService from "../../services/datasource";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async getDataSourceTypes() {
            try {
                return await datasourceService.getDataSourceTypes();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPDataSourceTypes(context, { filter }) {
            try {
                return await datasourceService.countFSPDataSourceTypes(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSourcesForDataSourceTypes(context, { dataSourceTypeId }) {
            try {
                return await datasourceService.getDataSourcesForDataSourceTypes(
                    dataSourceTypeId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPDataSourceTypes(
            context,
            { filter, sortby, limit, offset },
        ) {
            try {
                return await datasourceService.getFSPDataSourceTypes(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createDataSource(
            context,
            { projectId, name, dataSourceTypeId, data },
        ) {
            try {
                await datasourceService.createDataSource(
                    projectId,
                    name,
                    dataSourceTypeId,
                    data,
                );
                notifier.success(`La source <b>${name}</b> a bien été créée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSources(context, { projectId, includeSelections }) {
            try {
                return await datasourceService.getDataSources(
                    projectId,
                    includeSelections,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSource(context, { projectId, datasourceId }) {
            try {
                return await datasourceService.getDataSource(
                    projectId,
                    datasourceId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteDataSource(context, { projectId, datasourceId }) {
            try {
                await datasourceService.deleteDataSource(
                    projectId,
                    datasourceId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSelections(context, { projectId, datasourceId }) {
            try {
                return await datasourceService.getDataSelections(
                    projectId,
                    datasourceId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createDataSelection(
            context,
            { projectId, datasourceId, name, prefilter, data, langId },
        ) {
            try {
                await datasourceService.createDataSelection(
                    projectId,
                    datasourceId,
                    name,
                    prefilter,
                    data,
                    langId,
                );
                notifier.success(
                    `La sélection <b>${name}</b> a bien été créée`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateDataSelection(
            context,
            { projectId, dataselectionId, name, prefilter, data, langId },
        ) {
            try {
                await datasourceService.updateDataSelection(
                    projectId,
                    dataselectionId,
                    name,
                    prefilter,
                    data,
                    langId,
                );
                notifier.success(
                    `La sélection <b>${name}</b> a bien été mise à jour`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteDataSelection(context, { projectId, dataselectionId }) {
            try {
                await datasourceService.deleteDataSelection(
                    projectId,
                    dataselectionId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async associateDataSelection(
            context,
            { projectId, menuId, dataselectionId },
        ) {
            try {
                await datasourceService.associateDataSelection(
                    projectId,
                    menuId,
                    dataselectionId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSelectionMenu(context, { projectId, menuId }) {
            try {
                return await datasourceService.getDataSelectionMenu(
                    projectId,
                    menuId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMenuDataSelection(
            context,
            { projectId, menuId, dataselectionId },
        ) {
            try {
                await datasourceService.deleteMenuDataSelection(
                    projectId,
                    menuId,
                    dataselectionId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMenuDataSelection(
            context,
            { projectId, menuId, dataselectionId },
        ) {
            try {
                return await datasourceService.getMenuDataSelection(
                    projectId,
                    menuId,
                    dataselectionId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMenuDataSelection(
            context,
            { projectId, menuId, dataselectionId, config, filter },
        ) {
            try {
                await datasourceService.updateMenuDataSelection(
                    projectId,
                    menuId,
                    dataselectionId,
                    config,
                    filter,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async editDataSource(context, { projectId, datasourceId, name, data }) {
            try {
                await datasourceService.editDataSource(
                    projectId,
                    datasourceId,
                    name,
                    data,
                );
                notifier.success(
                    `La source <b>${name}</b> a bien été mise à jour`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSelection(context, { projectId, dataselectionId }) {
            try {
                return await datasourceService.getDataSelection(
                    projectId,
                    dataselectionId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDataSelectionPreview(
            context,
            { projectId, dataselectionId, refresh },
        ) {
            try {
                return await datasourceService.getDataSelectionPreview(
                    projectId,
                    dataselectionId,
                    refresh,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
