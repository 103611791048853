import HTTP from "./http-common";

export default {
    async countFSPCronjobs(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: "/admin/cronjobs",
            params: { filter },
        });
        return response.headers["pagination-count"];
    },

    async getFSPCronjobs(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/cronjobs",
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async createCronJob(name, description, enabled, time, action) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/cronjobs`,
            data: { name, description, enabled, time, action },
        });
        return response.data.data;
    },

    async updateCronJob(id, name, description, enabled, time, action) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/cronjob/${id}`,
            data: { name, description, enabled, time, action },
        });
        return response.data.data;
    },

    async deleteCronJob(id) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/cronjob/${id}`,
        });
        return response.data.data;
    },
};
