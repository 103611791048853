<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <b-input-group size="sm" style="flex:2;min-width:300px;">
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-search />
                    </b-input-group-text>
                </template>
                <b-form-input
                    placeholder="Rechercher un fichier"
                    v-model="filter"
                    debounce="500"
                />
            </b-input-group>

            <b-input-group size="sm" style="flex:1;min-width:300px;">
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-sort-alpha-down />
                    </b-input-group-text>
                </template>
                <b-form-select v-model="sortby" :options="sortOptions" />
            </b-input-group>
        </div>

        <GridCardBasic :data="medias" empty="Aucun fichier" minCardSize="300px">
            <template v-slot="{ data }">
                <CardMedia :media="data" @select="$emit('select', $event)" />
            </template>
        </GridCardBasic>

        <div class="k-flex-row">
            <b-pagination
                class="flex-grow-1"
                size="sm"
                align="center"
                v-model="currentPage"
                :total-rows="mediaCount"
                :per-page="perPage"
            />
        </div>
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardMedia from "./CardMedia";

export default {
    components: {
        GridCardBasic,
        CardMedia,
    },

    props: {
        context: { type: Object },
    },

    data: function() {
        return {
            mediaCount: 0,
            medias: [],
            filter: "",
            perPage: 12,
            currentPage: 1,
            sortby: "createdAt",
            sortOptions: [
                { value: "createdAt", text: "Trier par date de création" },
                { value: "updatedAt", text: "Trier par date de modification" },
                { value: "name", text: "Trier par nom de fichier" },
            ],
        };
    },

    watch: {
        async filter() {
            await this.countMedias();
            await this.fetchMedias();
        },

        async currentPage() {
            await this.fetchMedias();
        },

        async sortby() {
            await this.fetchMedias();
        },
    },

    methods: {
        async countMedias() {
            if (this.context) {
                this.mediaCount = await this.$store.dispatch(
                    this.context.target + "/countFSPMedias",
                    {
                        ...this.context,
                        filter: `metadata substring ${this.filter} or name substring ${this.filter}`,
                    },
                );
            }
        },

        async fetchMedias() {
            if (this.context) {
                this.medias = await this.$store.dispatch(
                    this.context.target + "/getFSPMedias",
                    {
                        ...this.context,
                        filter: `metadata substring ${this.filter} or name substring ${this.filter}`,
                        offset: this.perPage * (this.currentPage - 1),
                        limit: this.perPage,
                        sortby: this.sortby,
                    },
                );
            }
        },
    },

    async beforeMount() {
        await this.countMedias();
        await this.fetchMedias();
    },
};
</script>

<style></style>
