const MODULES = {
    media: {
        name: "Hébergement de médias",
        description:
            "Ce module permet l'hébergement de fichiers tels que des images et des pdf qui peuvent ensuite être utilisés en tant que données dans l'application.",
    },
};

const MODULE_STATES = [
    {
        label: "Obsolète",
        value: "-1",
        description: "Le module est obsolète, il ne doit plus être utilisé.",
    },
    {
        label: "Nouveau",
        value: "0",
        description:
            "Le module est en cours de développement, il ne peut pas encore être utilisé.",
    },
    {
        label: "Interne",
        value: "1",
        description: "Le module est interne, seul Kalkin y a accès.",
    },
    {
        label: "Protégé caché",
        value: "2",
        description:
            "Le module est protégé, seul Kalkin peut l'ajouter et le supprimer. Les membres pourront uniquement le configurer s'il est actif, sinon il ne sera pas visible.",
    },
    {
        label: "Protégé public",
        value: "3",
        description:
            "Le module est protégé, seul Kalkin peut l'ajouter et le supprimer. Les membres pourront le voir dans la liste. Ils pourront le configurer s'il est actif.",
    },
    {
        label: "Public",
        value: "4",
        description:
            "Le module est public, les membres peuvent l'ajouter, le configurer et le supprimer à volonté.",
    },
];

const MODULE_TARGETS = [
    {
        type: "Project",
        label: "Le projet dans son ensemble",
        name: "Projet",
        action: {
            project: "project/getProject",
            config: "project/getProject",
        },
    },
    {
        type: "ApplicationConfig",
        label: "Une ou plusieurs configurations",
        name: "Configuration",
        action: {
            project: "application/getApplicationConfigs",
            config: "application/getApplicationConfig",
        },
    },
    {
        type: "Installation",
        label: "Une ou plusieurs installations",
        name: "Installation",
        action: {
            project: "project/getInstallationsNoTV",
            config: "application/getInstallations",
        },
    },
];

module.exports = {
    getModuleInfos(type) {
        return MODULES[type] || { name: "Inconnu" };
    },

    getModuleStateOptions() {
        return MODULE_STATES.map(s => ({ text: s.label, value: s.value }));
    },

    getModuleStateHelp() {
        return MODULE_STATES.reduce(
            (acc, cur) => ((acc[cur.value] = cur.description), acc),
            {},
        );
    },

    getModuleStateName() {
        return MODULE_STATES.reduce(
            (acc, cur) => ((acc[cur.value] = cur.label), acc),
            {},
        );
    },

    getModuleTargets() {
        return MODULE_TARGETS;
    },

    getModuleTarget(type) {
        return MODULE_TARGETS.find(t => t.type === type);
    },
};
