import HTTP from "./http-common";

export default {
    async getMedias(collectionId, collectionName) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/medias/${collectionId}/${collectionName}`,
        });
        return response.data.data;
    },

    async createCollection(name, type, description, mime) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/medias/collections`,
            data: { name, type, description, mime },
        });
        return response.data.data;
    },

    async countFSPCollections(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/medias/collections`,
            params: { filter },
        });
        return response.headers["pagination-count"];
    },

    async getFSPCollections(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/medias/collections`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getCollection(collectionId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/medias/collection/${collectionId}`,
        });
        return response.data.data;
    },

    async updateCollection(collectionId, name, type, description, mime) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/medias/collection/${collectionId}`,
            data: { name, type, description, mime },
        });
        return response.data.data;
    },

    async countFSPMedias(collectionId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/medias/collection/${collectionId}/medias`,
            params: { filter },
        });
        return response.headers["pagination-count"];
    },

    async getFSPMedias(collectionId, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/medias/collection/${collectionId}/medias`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async createMedia(collectionId, file, name, metadata) {
        let bodyFormData = new FormData();
        bodyFormData.set("name", name);
        if (metadata) {
            bodyFormData.set("metadata", JSON.stringify(metadata));
        }
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "POST",
            url: `/admin/medias/collection/${collectionId}/medias`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },

    async updateMedia(collectionId, mediaId, file, metadata) {
        let bodyFormData = new FormData();
        bodyFormData.set("metadata", JSON.stringify(metadata));
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "PUT",
            url: `/admin/medias/collection/${collectionId}/media/${mediaId}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },

    async deleteCollection(collectionId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/medias/collection/${collectionId}`,
        });
        return response.data.data;
    },

    async deleteMedia(collectionId, mediaId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/medias/collection/${collectionId}/media/${mediaId}`,
        });
        return response.data.data;
    },

    async getMedia(collectionId, mediaId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/medias/collection/${collectionId}/media/${mediaId}`,
        });
        return response.data.data;
    },
};
