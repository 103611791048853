<template>
    <div class="w-100 k-flex-row k-flex-fill align-items-stretch">
        <div>
            <div class="k-flex-center text-kalkin-1 font-weight-bold mb-2">
                Médias disponibles
                <b-badge class="ml-1">{{ mediasCount }}</b-badge>
            </div>

            <b-list-group class="media-list">
                <b-list-group-item
                    class="k-flex-row"
                    v-for="media in Object.values(medias)"
                    :variant="media.selected() ? 'light' : ''"
                    :key="media.id"
                >
                    <ImagePreview
                        width="60px"
                        height="60px"
                        :url="media.url + '?notrack=true'"
                    />
                    <span style="max-width:50%">
                        {{ media.metadata.title || media.name }}
                    </span>
                    <b-button
                        class="text-kalkin-1"
                        v-if="!media.selected()"
                        @click="addMedia(media.id)"
                    >
                        Ajouter <b-icon-box-arrow-in-right />
                    </b-button>
                    <b-button v-else @click="addMedia(media.id)" disabled>
                        Ajouté <b-icon-check2-square />
                    </b-button>
                </b-list-group-item>
            </b-list-group>
        </div>

        <div>
            <div class="k-flex-center text-kalkin-1 font-weight-bold mb-2">
                Médias ajoutés
                <b-badge class="ml-1">
                    {{ value.length }}
                </b-badge>
            </div>

            <b-list-group class="media-list" v-if="mediasCount">
                <template v-for="(mediaId, index) in value">
                    <b-list-group-item
                        class="k-flex-row"
                        variant="kalkin-2"
                        v-if="medias[mediaId]"
                        :key="mediaId"
                    >
                        <ImagePreview
                            width="60px"
                            height="60px"
                            :url="medias[mediaId].url + '?notrack=true'"
                        />
                        <span style="max-width:50%">
                            {{
                                medias[mediaId].metadata.title ||
                                    medias[mediaId].name
                            }}
                        </span>
                        <div>
                            <b-button
                                size="xs"
                                :class="{ invisible: !index }"
                                @click="move(index, -1)"
                            >
                                <b-icon-arrow-up-circle />
                            </b-button>
                            <b-button
                                :class="{
                                    invisible: index == value.length - 1,
                                }"
                                @click="move(index, 1)"
                            >
                                <b-icon-arrow-down-circle />
                            </b-button>
                            <b-button @click="removeAt(index)">
                                Retirer <b-icon-box-arrow-in-left />
                            </b-button>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item
                        class="k-flex-row"
                        variant="danger"
                        v-else
                        :key="mediaId"
                    >
                        Média non trouvé dans la collection
                        <b-button @click="removeAt(index)">
                            Supprimer <b-icon-trash />
                        </b-button>
                    </b-list-group-item>
                </template>
            </b-list-group>
            <div
                class="h-25 k-flex-center font-italic"
                v-if="value.length === 0"
            >
                Aucun média ajouté
            </div>
        </div>
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import { get as _get } from "lodash";
import ImagePreview from "@/components/ui/item/ImagePreview.vue";

export default {
    components: {
        ImagePreview,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            medias: {},
            selectedMedias: [],
            collection: _get(
                this,
                "formOptions.payload.datasource.data.collection",
            ),
        };
    },

    computed: {
        mediasCount() {
            return Object.keys(this.medias).length;
        },
    },

    methods: {
        addMedia(id) {
            this.value.push(id);
        },

        removeAt(idx) {
            this.value.splice(idx, 1);
        },

        move(idx, offset) {
            const id = this.value[idx];
            this.value.splice(idx, 1);
            this.value.splice(idx + offset, 0, id);
        },
    },

    async beforeMount() {
        this.value = this.value || [];

        if (this.collection) {
            const medias = await this.$store.dispatch("project/getMedias", {
                projectId: this.projectId,
                serviceId: this.collection.service,
                collectionId: this.collection.id,
            });
            if (medias) {
                this.medias = medias.reduce((acc, m) => {
                    acc[m.id] = m;
                    m.selected = () => this.value.includes(m.id);
                    return acc;
                }, {});
            }
        }
    },

    mixins: [abstractField],
};
</script>

<style lang="scss" scoped>
.media-list {
    max-height: 300px;
    overflow: auto;
}
</style>
