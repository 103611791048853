<template>
    <div class="d-flex align-items-center justify-content-center">
        <div class="image-block" :style="imageBlockStyle">
            <div class="bubble extension" v-if="extension">
                {{ extension }}
            </div>
            <img
                :src="url"
                alt="err"
                @error="onError"
                v-if="!hasError"
                :style="imageStyle"
            />
            <span v-else>Aucun aperçu</span>
            <div class="bubble bg-help" v-show="!hasError">
                <div
                    style="background-color:#000"
                    @mouseenter.prevent="bgColor = '#000'"
                    @mouseleave.prevent="bgColor = defaultBackground"
                />
                <div
                    style="background-color:#fff"
                    @mouseenter.prevent="bgColor = '#fff'"
                    @mouseleave.prevent="bgColor = defaultBackground"
                />
            </div>
            <div class="overlay" v-show="downloadable" @click="download"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        url: { type: String, required: true },
        width: { type: String, default: "50px" },
        height: { type: String, default: "50px" },
        fontSize: { type: String, default: "10px" },
        defaultBackground: { type: String, default: "#88888850" },
        extension: String,
        downloadable: { type: Boolean, default: false },
    },

    data: function() {
        return {
            bgColor: this.defaultBackground,
            hasError: false,
        };
    },

    watch: {
        url() {
            this.hasError = false;
        },
    },

    computed: {
        imageBlockStyle() {
            return (
                `width:${this.width};` +
                `height:${this.height};` +
                `font-size:${this.fontSize};`
            );
        },

        imageStyle() {
            return `background-color:${this.bgColor};`;
        },
    },

    methods: {
        download() {
            window.open(this.url);
        },

        onError() {
            this.hasError = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.image-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    border: 3px solid $secondary-color;
    border-radius: 3px;
    padding: 3px;
    box-shadow: 0 0 3px $secondary-color;

    background-color: lighten($secondary-color, 42);
    text-align: center;

    & > img {
        max-height: 100%;
        max-width: 100%;
        border: 1px dashed #00000030;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.bubble {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;

    background: $secondary-color;
    color: white;

    margin: -3px;
    border-radius: 3px;
    z-index: 10;
}

.extension {
    left: 0;
    top: 0;
    padding: 0 5px;
    font-size: 12px;
}

.bg-help {
    right: 0;
    bottom: 0;
    height: 16px;
    width: 28px;
    padding: 3px;
}

.bg-help > div {
    width: 10px;
    height: 10px;
    border-radius: 2px;
}
.bg-help > div:hover {
    width: 12px;
    height: 12px;
}
</style>
