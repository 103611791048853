import HTTP from "./http-common";

export default {
    // ---------------------------------------------------------------- Activity

    async getActivity() {
        const response = await HTTP({
            method: "GET",
            url: `/admin/activity`,
        });
        return response.data.data;
    },

    // ----------------------------------------------------------- Installations

    async getInstallations() {
        const response = await HTTP({
            method: "GET",
            url: `/admin/installations`,
        });
        return response.data.data;
    },

    // -------------------------------------------------------------- ArboBuilds

    async countFSPArboBuilds(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: "/admin/arbobuilds",
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPArboBuilds(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/arbobuilds",
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getArboBuild(id) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/arbobuild/${id}`,
        });
        return response.data.data;
    },

    // ----------------------------------------------------------------- Modules

    async getModules() {
        const response = await HTTP({
            method: "GET",
            url: "/admin/modules",
        });
        return response.data.data;
    },

    async createModule(name, description, config, state, type) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/modules`,
            data: { name, description, config, state, type },
        });
        return response.data.data;
    },

    async getModule(moduleId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/module/${moduleId}`,
        });
        return response.data.data;
    },

    async updateModule(
        moduleId,
        name,
        description,
        config,
        state,
        type,
        version,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/module/${moduleId}`,
            data: { name, description, config, state, type, version },
        });
        return response.data.data;
    },

    async deleteModule(moduleId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/module/${moduleId}`,
        });
        return response.data.data;
    },

    // ----------------------------------------------------------Project Modules

    async getProjectModules(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/project/${projectId}/modules`,
        });
        return response.data.data;
    },

    async createProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/project/${projectId}/modules`,
            data: { moduleId },
        });
        return response.data.data;
    },

    async getProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/project/${projectId}/module/${moduleId}`,
        });
        return response.data.data;
    },

    async updateProjectModule(
        projectId,
        moduleId,
        target,
        config,
        data,
        enabled,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/project/${projectId}/module/${moduleId}`,
            data: { target, config, data, enabled },
        });
        return response.data.data;
    },

    async deleteProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/project/${projectId}/module/${moduleId}`,
        });
        return response.data.data;
    },

    // ----------------------------------------------------------Module Projects

    async countFSPProjectModules(moduleId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/module/${moduleId}/projects`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPProjectModules(moduleId, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/module/${moduleId}/projects`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    // -------------------------------------------------------------- TeamViewer

    async getTeamViewerGroups() {
        const response = await HTTP({
            method: "GET",
            url: `/admin/teamviewer/groups`,
        });
        return response.data.data;
    },

    async getTeamViewerDevices(groupid) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/teamviewer/devices`,
            params: { groupid },
        });
        return response.data.data;
    },

    async getTeamViewerDevice(deviceId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/teamviewer/device/${deviceId}`,
        });
        return response.data.data;
    },

    // --------------------------------------------------------------- AdvancedSearch

    async advancedSearchGetAll(search) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/quicksearch`,
            params: { search },
        });
        return response.data.data;
    },

    // ------------------------------------------------------------------- Tools

    async cloneArbo(config) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/tools/clone/arbo`,
            data: config,
        });
        return response.data.data;
    },

    async cloneProject(config) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/tools/clone/project`,
            data: config,
        });
        return response.data.data;
    },
};
