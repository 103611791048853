<template>
    <sidebar-menu
        :width="width"
        :widthCollapsed="widthCollapsed"
        theme="kalkin-theme"
        :showOneChild="true"
        :relative="true"
        :menu="menu"
        :collapsed="isSidebarCollapsed"
        @toggle-collapse="onToggleCollapse"
    >
        <template v-slot:header>
            <div class="header">
                <template v-if="isSidebarCollapsed">
                    <img src="@/assets/favicon-kalkin.png" />
                </template>

                <template v-else>
                    <div class="title">BACK-OFFICE</div>
                    <div class="subtitle">
                        by <img class="ml-1" src="@/assets/logo_white.png" />
                    </div>
                </template>
                <div class="separator" />
            </div>
        </template>

        <template v-slot:footer>
            <b-dropdown
                class="k-sidebar-footer"
                menu-class="footer-menu"
                variant="kalkin-3"
                boundary="viewport"
                dropright
                no-caret
            >
                <template v-slot:button-content>
                    <b-icon-person-circle />
                    <template v-if="!isSidebarCollapsed">
                        {{ user.firstName }} {{ user.lastName }}
                    </template>
                </template>
                <b-dropdown-item @click="goProfile">
                    <b-icon-person-fill />
                    Profil
                </b-dropdown-item>
                <b-dropdown-item class="custom-text-danger" @click="logout">
                    <b-icon-door-closed-fill />
                    Déconnexion
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item class="text-center" disabled>{{
                    getVersion()
                }}</b-dropdown-item>
            </b-dropdown>
        </template>

        <template v-slot:toggle-icon>
            <b-icon-chevron-bar-right v-if="isSidebarCollapsed" />
            <b-icon-chevron-bar-left v-else />
        </template>

        <template v-slot:dropdown-icon>
            <b-icon-chevron-right />
        </template>
    </sidebar-menu>
</template>
<script>
import { SidebarMenu } from "vue-sidebar-menu";
import ProjectMenuItem from "@/components/misc/sidebar/ProjectMenuItem";

export default {
    components: {
        SidebarMenu,
    },

    props: {
        width: { type: String, required: true },
        widthCollapsed: { type: String, required: true },
    },

    data() {
        return {
            isSidebarCollapsed: false,
        };
    },

    computed: {
        currentProjectId() {
            return this.$route.params.projectId;
        },

        currentConfigId() {
            return this.$route.params.configId;
        },

        isAdmin() {
            return this.$route.path.includes("/admin");
        },

        user() {
            return this.$store.state.user.user || {};
        },

        displayProject() {
            return (
                this.currentProjectId && this.currentConfigId && !this.isAdmin
            );
        },

        displayKalkin() {
            return this.user.credentials >= 5;
        },

        displayAdmin() {
            return this.user.credentials >= 10;
        },

        menu() {
            return [
                {
                    href: { name: "Home" },
                    title: "Accueil",
                    icon: { element: "b-icon-house-fill" },
                },
                {
                    component: ProjectMenuItem,
                    props: {
                        collapsed: this.isSidebarCollapsed,
                    },
                },
                // ----------------------------------------- PROJECT
                {
                    title: "Installations",
                    href: {
                        name: "ProjectInstallations",
                        params: {
                            projectId: this.$route.params.projectId || 1,
                            configId: this.$route.params.configId || 1,
                        },
                    },
                    icon: { element: "b-icon-geo-alt" },
                    hidden: !this.displayProject,
                },
                {
                    title: "Statistiques",
                    href: {
                        name: "Stats",
                        params: {
                            projectId: this.$route.params.projectId || 1,
                            configId: this.$route.params.configId || 1,
                        },
                    },
                    icon: { element: "b-icon-bar-chart-line" },
                    alias: "/project/*/stats*",
                    hidden: !this.displayProject,
                },
                {
                    title: "Gestion de projet",
                    icon: { element: "b-icon-list-task" },
                    hidden: !this.displayProject,
                    child: [
                        {
                            title: "Configuration",
                            icon: { element: "b-icon-gear" },
                            hidden: !this.displayProject,
                            disabled: true,
                        },
                        {
                            title: "Modules",
                            href: {
                                name: "ProjectListModules",
                                params: {
                                    projectId:
                                        this.$route.params.projectId || 1,
                                    configId: this.$route.params.configId || 1,
                                },
                            },
                            icon: { element: "b-icon-layout-wtf" },
                            alias: "/project/*/config/*/module*",
                            hidden: !this.displayProject,
                        },
                        {
                            title: "Menus",
                            icon: { element: "b-icon-menu-button-wide" },
                            hidden: !this.displayProject,
                            disabled: true,
                        },
                        {
                            title: "Données",
                            icon: { element: "b-icon-file-earmark-text" },
                            hidden: !this.displayProject,
                            disabled: true,
                        },
                        {
                            title: "Utilisateurs",
                            icon: { element: "b-icon-people" },
                            hidden: !this.displayProject,
                            disabled: true,
                        },
                    ],
                },

                // ----------------------------------------- ADMIN
                {
                    header: true,
                    title: "Administration",
                    hiddenOnCollapse: true,
                    hidden: !this.displayKalkin,
                },
                {
                    href: { name: "Admin" },
                    title: "Tableau de bord",
                    icon: { element: "b-icon-toggles" },
                    hidden: !this.displayKalkin,
                    child: [
                        {
                            href: { name: "AdminListProjects" },
                            title: "Projets",
                            icon: { element: "b-icon-folder" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/project/*",
                        },
                        {
                            href: { name: "AdminListUsers" },
                            title: "Utilisateurs",
                            icon: { element: "b-icon-people-fill" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/user/*",
                        },
                        {
                            href: { name: "AdminListContacts" },
                            title: "Contacts",
                            icon: { element: "b-icon-card-heading" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/contact/*",
                        },
                        {
                            href: { name: "AdminMapInstallations" },
                            title: "Installations",
                            icon: { element: "b-icon-geo-alt-fill" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/installation/*",
                        },
                    ],
                },
                {
                    title: "Outils",
                    icon: { element: "b-icon-tools" },
                    hidden: !this.displayKalkin,
                    child: [
                        {
                            href: { name: "AdminListArbobuilds" },
                            title: "Historique de génération",
                            icon: { element: "b-icon-lightning-fill" },
                            hidden: !this.displayKalkin,
                        },
                        {
                            href: { name: "Medias" },
                            title: "Médias",
                            icon: { element: "b-icon-images" },
                            hidden: !this.displayKalkin,
                            alias: "/medias/*",
                        },
                        {
                            href: { name: "KBSIT" },
                            title: "SIT Kalkin",
                            icon: { element: "b-icon-cloud-fill" },
                            hidden: !this.displayKalkin,
                            alias: "/kbsit/*",
                        },
                        {
                            href: { name: "AdminCloningTool" },
                            title: "Outils de duplication",
                            icon: {
                                element: "b-icon",
                                attributes: {
                                    icon: "diagram2-fill",
                                    rotate: "270",
                                },
                            },
                            hidden: !this.displayKalkin,
                        },
                        {
                            href: { name: "AdminListSit" },
                            title: "Utilisation des SIT",
                            icon: {
                                element: "b-icon",
                                attributes: {
                                    icon: "server",
                                    rotate: "270",
                                },
                            },
                            hidden: !this.displayKalkin,
                        },
                    ],
                },
                {
                    title: "Configuration avancée",
                    icon: { element: "b-icon-gear-fill" },
                    hidden: !this.displayKalkin,
                    child: [
                        {
                            href: { name: "AdminListApplications" },
                            title: "Applications",
                            icon: { element: "b-icon-collection-play" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/application/*",
                        },
                        {
                            href: { name: "AdminListModules" },
                            title: "Modules",
                            icon: { element: "b-icon-layout-wtf" },
                            hidden: !this.displayKalkin,
                            alias: "/admin/module/*",
                        },
                        {
                            href: { name: "CronJobs" },
                            title: "Tâches programmées",
                            icon: { element: "b-icon-clock-history" },
                            hidden: !this.displayAdmin,
                        },
                    ],
                },
            ];
        },
    },

    methods: {
        onToggleCollapse(collapsed) {
            this.isSidebarCollapsed = collapsed;
            this.$emit("collapse", this.isSidebarCollapsed);
        },

        onResize() {
            this.isSidebarCollapsed = window.innerWidth <= 900;
            this.$emit("collapse", this.isSidebarCollapsed);
        },

        goProfile: function() {
            this.$router.push({ name: "Profile" });
        },

        logout: function() {
            this.$store.dispatch("user/logout");
            this.$router.push({ name: "Login" });
        },
        getVersion: function() {
            return `Version ${this.$store.getters.appVersion}`;
        },
    },

    beforeMount() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 10px 10px;
    text-align: center;
    font-family: "Roboto", Helvetica, Arial, sans-serif;

    .title {
        font-size: 32px;
        font-weight: 700;
        color: $secondary-color;
        overflow: hidden;
        white-space: nowrap;
    }
    .subtitle {
        font-size: 10px;
        color: white;
        text-align: right;
        margin-right: 10px;
    }

    .separator {
        margin: 15px auto 0 auto;
        max-width: 80px;
        border-bottom: 1px solid $ternary-color;
    }
}
</style>

<style lang="scss">
.k-sidebar-footer {
    button {
        border-radius: 0;
        background-color: $theme-bg-3 !important;
        color: $theme-fg-3 !important;
    }
    ul {
        background-color: $theme-bg-4;

        li {
            color: $theme-fg-4 !important;
        }
    }
    .dropdown-item {
        color: $theme-fg-4;
    }
    .dropdown-item:hover {
        background-color: darken($theme-bg-4, 5%);
    }
}
</style>
