import axios from "axios";
const HTTP = axios.create({
    baseURL: `http://localhost:3050/`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export default {
    async getAllProjects() {
        const response = await HTTP({
            method: "GET",
            url: "/projects",
        });
        return response.data.data;
    },

    async getAllProjectFiches(projectId, offset, limit) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/fiches`,
            params: { limit, offset },
        });
        return response.data.data;
    },

    async getAllProjectFichesFiltered(projectId, filter) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/fiches`,
            params: {
                filter: `name substring ${filter}`,
            },
        });
        return response.data.data;
    },

    async getFicheDispos(ficheId) {
        const response = await HTTP({
            method: "GET",
            url: `/fiche/${ficheId}/dispos`,
        });
        return response.data.data;
    },

    async getDispo(ficheId, date) {
        const response = await HTTP({
            method: "GET",
            url: `/fiche/${ficheId}/dispo/${date}`,
        });
        return response.data.data;
    },

    async createDispo(ficheId, date, dispo, description) {
        const response = await HTTP({
            method: "POST",
            url: `/fiche/${ficheId}/dispo`,
            data: { date, dispo, description },
        });
        return response.data.data;
    },

    async updateDispo(ficheId, date, dispo, description) {
        const response = await HTTP({
            method: "PUT",
            url: `/fiche/${ficheId}/dispo/${date}`,
            data: { dispo, description },
        });
        return response.data.data;
    },

    async createFiche(
        projectId,
        name,
        originalId,
        adress,
        phone,
        typeId,
        mail,
        ranking,
        prices,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/fiche`,
            data: {
                name,
                originalId,
                adress,
                phone,
                typeId,
                mail,
                ranking,
                prices,
            },
        });
        return response.data.data;
    },

    async getAllTypes(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/types`,
        });
        return response.data.data;
    },

    async createType(projectId, label) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/type`,
            data: { label },
        });
        return response.data.data;
    },

    async deleteType(projectId, typeId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/type/${typeId}`,
        });
        return response.data.data;
    },

    async deleteFiche(projectId, ficheId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/fiche/${ficheId}`,
        });
        return response.data.data;
    },

    async updateFiche(
        projectId,
        ficheId,
        name,
        originalId,
        adress,
        phone,
        typeId,
        mail,
        ranking,
        prices,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/fiche/${ficheId}`,
            data: {
                name,
                originalId,
                adress,
                phone,
                typeId,
                mail,
                ranking,
                prices,
            },
        });
        return response.data.data;
    },

    async getDispoOfProject(projectId, typeId, startDate, endDate) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/dispos/${typeId}`,
            params: { startDate, endDate },
        });
        return response.data.data;
    },

    async getFiche(projectId, ficheId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/fiche/${ficheId}`,
        });
        return response.data.data;
    },
};
