<template>
    <ArrayInput
        default=""
        v-model="array"
        :min="schema.min"
        :max="schema.max"
        @validate="isArrayValid = $event"
    >
        <template v-slot="{ item, onInput, index }">
            <div class="k-url-input">
                <label>URL</label>
                <b-form-input
                    :ref="'input-' + index"
                    type="text"
                    :value="item"
                    @change="onInput"
                />
                <template v-if="context">
                    <b-button @click="$bvModal.show(uid + index)">
                        <b-icon-files /> Mes fichiers
                    </b-button>
                    <ModalMediaBank
                        :modal-id="uid + index"
                        :context="context"
                        @select="onMediaSelected($event, onInput, index)"
                /></template>
            </div>
        </template>
    </ArrayInput>
</template>

<script>
import { abstractField } from "vue-form-generator";
import ArrayInput from "./common/ArrayInput.vue";
import { get as _get } from "lodash";
import ModalMediaBank from "@/components/misc/media-bank/ModalMediaBank.vue";
import notifier from "@/tools/notifier";

export default {
    components: {
        ArrayInput,
        ModalMediaBank,
    },

    data: function() {
        return {
            isArrayValid: true,
            uid: this.getFieldID(this.schema, true),
            context: this.createContext(this.formOptions),
        };
    },

    computed: {
        array: {
            get() {
                return this.value;
            },
            set(v) {
                this.value = v.map(item => String(item));
            },
        },

        isContentValid() {
            return this.value.every(
                r =>
                    r === "" ||
                    r.match(/^https?:\/\//) ||
                    (r.startsWith("[media]") && r.endsWith("[/media]")),
            );
        },
    },

    watch: {
        formOptions(v) {
            this.context = this.createContext(v);
        },
    },

    methods: {
        createContext(formOptions) {
            switch (this.schema.target) {
                case "project":
                    var collection = _get(
                        formOptions,
                        "payload.project.data.medias.assets",
                        {},
                    );
                    return {
                        target: "project",
                        projectId: _get(formOptions, "payload.project.id"),
                        collectionId: collection.id,
                        serviceId: collection.service,
                        mime: this.schema.accept || collection.mime,
                    };
                case "kbsit":
                    return {
                        target: "kbsit",
                        projectId: _get(formOptions, "payload.project.id"),
                        collectionId: this.schema.collection,
                        sit: _get(formOptions, "payload.sit"),
                        mime: this.schema.accept,
                    };
                default:
                    return null;
            }
        },

        onMediaSelected(media, onInput, index) {
            if (media && media.url) {
                onInput(media.url);
                this.$refs["input-" + index].$el.value = media.url; //trick
            } else {
                notifier.error("Erreur lors de la sélection du fichier");
            }
            this.$bvModal.hide(this.uid + index);
        },

        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (this.value) {
                if (!this.isArrayValid) {
                    isValid = false;
                    this.errors.push(
                        this.schema.errorText ||
                            "La taille du tableau est incorrecte!",
                    );
                }
                if (!this.isContentValid) {
                    isValid = false;
                    this.errors.push(
                        this.schema.errorText ||
                            "Au moins une URL est invalide",
                    );
                }
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>
