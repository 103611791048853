<template>
    <div class="k-card-3">
        <div class="text-center">
            <h5 class="text-kalkin-2">{{ name }}</h5>
        </div>
        <div class="flex-grow-1" />
        <ImagePreview
            width="200px"
            height="200px"
            fontSize="15px"
            :url="url"
            :extension="extension"
            :downloadable="true"
        />
        <div class="flex-grow-1" />
        <div class="k-flex-center" v-if="tags && tags.length">
            <b-badge
                class="mx-1"
                variant="secondary"
                v-for="tag in tags"
                :key="tag"
            >
                {{ tag }}
            </b-badge>
        </div>
        <b-button
            variant="kalkin-1"
            size="xs"
            @click="$emit('update')"
            v-if="canEdit"
        >
            <b-icon-pencil-fill /> Modifier le média
        </b-button>
        <small v-else class="text-center font-italic">
            Droits insuffisants pour la modification
        </small>
    </div>
</template>

<script>
import ImagePreview from "@/components/ui/item/ImagePreview.vue";
export default {
    components: {
        ImagePreview,
    },

    props: {
        media: Object,
    },

    data: function() {
        return {
            canEdit: this.$store.getters["project/hasWriterCredentials"],
        };
    },

    computed: {
        name() {
            return (
                (this.media.metadata && this.media.metadata.title) ||
                this.media.name
            );
        },

        extension() {
            return this.media.name.split(".").pop();
        },

        url() {
            return this.media.url + "?notrack=true&time=" + Date.now();
        },

        tags() {
            return this.media.metadata && this.media.metadata.tags;
        },
    },

    methods: {},
};
</script>

<style></style>
