import dispoService from "../../services/dispo";
import APIError from "../../tools/APIError";

export default {
    namespaced: true,

    actions: {
        async getAllProjects() {
            try {
                return await dispoService.getAllProjects();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllProjectFiches(context, { projectId, page, limit, filter }) {
            try {
                return await dispoService.getAllProjectFiches(
                    projectId,
                    page,
                    limit,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllProjectFichesFiltered(context, { projectId, filter }) {
            try {
                return await dispoService.getAllProjectFichesFiltered(
                    projectId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFicheDispos(context, { ficheId }) {
            try {
                return await dispoService.getFicheDispos(ficheId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDispo(context, { ficheId, date }) {
            try {
                return await dispoService.getDispo(ficheId, date);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createDispo(context, { ficheId, date, dispo, description }) {
            try {
                return await dispoService.createDispo(
                    ficheId,
                    date,
                    dispo,
                    description,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateDispo(context, { ficheId, date, dispo, description }) {
            try {
                return await dispoService.updateDispo(
                    ficheId,
                    date,
                    dispo,
                    description,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createFiche(context, payload) {
            const { projectId, originalId, name } = payload;
            const { adress, phone, typeId, mail, ranking, prices } = payload;
            try {
                await dispoService.createFiche(
                    projectId,
                    name,
                    originalId,
                    adress,
                    phone,
                    typeId,
                    mail,
                    ranking,
                    prices,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllTypes(context, { projectId }) {
            try {
                return await dispoService.getAllTypes(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createType(context, { projectId, label }) {
            try {
                const type = await dispoService.createType(projectId, label);
                return type;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteType(context, { projectId, typeId }) {
            try {
                await dispoService.deleteType(projectId, typeId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteFiche(context, { projectId, ficheId }) {
            try {
                await dispoService.deleteFiche(projectId, ficheId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateFiche(context, payload) {
            const { projectId, originalId, ficheId, name } = payload;
            const { adress, phone, typeId, mail, ranking, prices } = payload;
            try {
                await dispoService.updateFiche(
                    projectId,
                    ficheId,
                    name,
                    originalId,
                    adress,
                    phone,
                    typeId,
                    mail,
                    ranking,
                    prices,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getDispoOfProject(context, payload) {
            const { projectId, typeId, startDate, endDate } = payload;
            try {
                return await dispoService.getDispoOfProject(
                    projectId,
                    typeId,
                    startDate,
                    endDate,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFiche(context, { projectId, ficheId }) {
            try {
                return await dispoService.getFiche(projectId, ficheId);
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
