import configService from "../../services/config";
import APIError from "../../tools/APIError";

export default {
    namespaced: true,

    state: {
        filters: [],
        moduleTypes: [],
    },

    getters: {
        allFilters: state => state.filters,
        publicFilters: state => state.filters.filter(f => f.isPublic),
        allModuleTypes: state => state.moduleTypes,
        findModuleType: state => value => {
            return state.moduleTypes.find(m => m.value === value) || {};
        },
    },

    mutations: {
        setFilters: (state, value) => (state.filters = value),
        setModuleTypes: (state, value) => (state.moduleTypes = value),
    },

    actions: {
        async getFilters({ commit, state }) {
            if (state.filters.length === 0) {
                try {
                    const filters = await configService.getFilters();
                    commit("setFilters", filters);
                } catch (error) {
                    APIError.handle(error);
                }
            }
        },

        async getModuleTypes({ commit, state }) {
            if (state.moduleTypes.length === 0) {
                try {
                    const types = await configService.getModuleTypes();
                    commit("setModuleTypes", types);
                } catch (error) {
                    APIError.handle(error);
                }
            }
        },
    },
};
