<template>
    <ArrayInput
        :default="genericFormDefault"
        v-model="array"
        :min="schema.min"
        :max="schema.max"
        @validate="isArrayValid = $event"
        @validate-content="validateContent"
    >
        <template v-slot="{ item, onInput, onValidate }">
            <GenericForm
                class="k-sub-form"
                :class="{ vertical: schema.vertical }"
                :schema="genericFormSchema"
                :model="item"
                :payload="payload"
                @updated="onInput"
                @validated="onValidate"
            />
        </template>
    </ArrayInput>
</template>

<script>
import { abstractField } from "vue-form-generator";
import ArrayInput from "./common/ArrayInput.vue";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";

export default {
    components: {
        ArrayInput,
        GenericForm,
    },

    data: function() {
        return {
            isArrayValid: true,
            isContentValid: true,
            payload: this.formOptions.payload,
        };
    },

    watch: {
        formOptions(v) {
            this.payload = v.payload;
        },
    },

    computed: {
        array: {
            get() {
                return this.value;
            },
            set(v) {
                this.value = v;
            },
        },

        genericFormSchema() {
            let schema = [];
            try {
                schema = {
                    ...Formatter.convertCOREtoFORM(this.schema.config),
                };
            } catch (error) {
                //nothing
            }
            return schema;
        },

        genericFormDefault() {
            let d = {};
            try {
                d = Formatter.createDefaultFORMObject(this.genericFormSchema);
            } catch (error) {
                //nothing
            }
            return d;
        },
    },

    methods: {
        validateContent(state) {
            this.isContentValid = state;
            this.validate();
        },

        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (this.value) {
                if (!this.isArrayValid) {
                    isValid = false;
                    this.errors.push(
                        this.schema.errorText ||
                            "La taille du tableau est incorrecte!",
                    );
                }
                if (!this.isContentValid) {
                    isValid = false;
                    this.errors.push(
                        this.schema.errorText || "Erreur(s) dans le tableau",
                    );
                }
            }

            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>

<style lang="scss" scoped>
::v-deep .k-sub-form {
    display: flex !important;

    width: 100%;
    border: 1px solid $secondary-color;
    border-radius: 6px;
    border-left-width: 4px;
    padding: 10px;
    margin: 5px;
    background: lighten($secondary-color, 45);

    & fieldset {
        display: flex;
        flex: 1;
    }
    &.vertical fieldset {
        flex-direction: column;
    }
}
</style>
