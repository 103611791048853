const UNDEF = "Inconnu";

export default function projectRoleFromCreds(value) {
    switch (value) {
        case 1:
            return "Lecture";
        case 5:
            return "Écriture";
        case 10:
            return "Administration";
        case 100:
            return "Aucun";
        default:
            return UNDEF;
    }
}
