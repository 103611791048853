<template>
    <div class="k-url-input">
        <label>URL</label>
        <b-form-input ref="input" type="text" v-model="url" />
        <template v-if="context">
            <b-button @click="$bvModal.show(uid)">
                <b-icon-files /> Mes fichiers
            </b-button>
            <ModalMediaBank
                :modal-id="uid"
                :context="context"
                @select="onMediaSelected"
            />
        </template>
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import { get as _get } from "lodash";
import ModalMediaBank from "@/components/misc/media-bank/ModalMediaBank.vue";
import notifier from "@/tools/notifier";

export default {
    components: {
        ModalMediaBank,
    },

    data: function() {
        return {
            isValid: true,
            uid: this.getFieldID(this.schema, true),
            context: this.createContext(this.formOptions),
        };
    },

    watch: {
        formOptions(v) {
            this.context = this.createContext(v);
        },
    },

    computed: {
        url: {
            get() {
                return this.value;
            },
            set(input) {
                if (
                    (input === "" && !this.schema.required) ||
                    input.match(/^https?:\/\//) ||
                    (input.startsWith("[media]") && input.endsWith("[/media]"))
                ) {
                    this.isValid = true;
                } else {
                    this.isValid = false;
                }
                this.value = input;
            },
        },
    },

    methods: {
        createContext(formOptions) {
            switch (this.schema.target) {
                case "project":
                    var collection = _get(
                        formOptions,
                        "payload.project.data.medias.assets",
                        {},
                    );
                    return {
                        target: "project",
                        projectId: _get(formOptions, "payload.project.id"),
                        collectionId: collection.id,
                        serviceId: collection.service,
                        mime: this.schema.accept || collection.mime,
                    };
                case "kbsit":
                    return {
                        target: "kbsit",
                        projectId: _get(formOptions, "payload.project.id"),
                        collectionId: this.schema.collection,
                        sit: _get(formOptions, "payload.sit"),
                        mime: this.schema.accept,
                    };
                default:
                    return null;
            }
        },

        onMediaSelected(media) {
            if (media && media.url) {
                this.url = media.url;
                this.$refs.input.$el.value = media.url; //trick
            } else {
                notifier.error("Erreur lors de la sélection du fichier");
            }
            this.$bvModal.hide(this.uid);
        },

        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire !",
                );
            } else if (!this.isValid) {
                isValid = false;
                this.errors.push(this.schema.errorText || "URL non valide !");
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>
