<template>
    <div id="container">
        <div id="overlay" v-show="isLoading" class="content text-center">
            <div id="spinner" class="spinner-border" role="status">
                <span class="sr-only">Chargement...</span>
            </div>
        </div>
        <div class="w-100 h-100" :class="{ blur: isLoading }">
            <slot class="content" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
#container {
    height: 100%;
    width: 100%;
    position: relative;
}
.content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
#overlay {
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}
#spinner {
    min-width: 3rem;
    min-height: 3rem;
    width: 3rem;
    height: 3rem;
    color: #199038;
}
.blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}
</style>
