import projectService from "../../services/project";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

const _hasCustomCredentials = (user_creds, project_creds) => (
    state,
    getters,
    rootState,
) => {
    const currentUser = rootState.user.user;

    // Vérification des droits globaux
    let userCredsValid = currentUser.credentials >= user_creds;

    // Vérification des droits projet
    let projectCredsValid = false;
    if (!userCredsValid && state.project) {
        const pu = state.project.Users.find(u => u.id === currentUser.id);
        if (pu) {
            projectCredsValid = pu.ProjectUser.credentials >= project_creds;
        }
    }
    return userCredsValid || projectCredsValid;
};

export default {
    namespaced: true,

    state: {
        project: undefined,
    },

    getters: {
        hasReaderCredentials: _hasCustomCredentials(6, 1),
        hasWriterCredentials: _hasCustomCredentials(7, 5),
        hasAdminCredentials: _hasCustomCredentials(10, 10),
    },

    mutations: {
        init(state, project) {
            state.project = project;
        },
    },

    actions: {
        async loadProject(context, { id }) {
            try {
                const project = await projectService.getProjectMeta(id);
                context.commit("init", project);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMyProjects() {
            try {
                const projects = await projectService.getMyProjects();
                return projects;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMyLastProjects(context, { n }) {
            try {
                const lastProjects = await projectService.getMyLastProjects(n);
                return lastProjects;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getProject(context, { projectId }) {
            try {
                const project = await projectService.getProject(projectId);
                return project;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllProjects() {
            try {
                const projects = await projectService.getAllProjects();
                return projects;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getLastProjects(context, { n }) {
            try {
                const lastProjects = await projectService.getLastProjects(n);
                return lastProjects;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPProjects(context, { filter }) {
            try {
                return await projectService.countFSPProjects(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPProjects(context, { filter, sortby, limit, offset }) {
            try {
                return await projectService.getFSPProjects(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createProject(context, { name, description }) {
            try {
                await projectService.createProject(name, description);
                notifier.success(`Le projet a bien été créé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateProject(
            context,
            { projectId, name, description, data, command },
        ) {
            try {
                await projectService.updateProject(
                    projectId,
                    name,
                    description,
                    data,
                    command,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteProject(context, { projectId }) {
            try {
                await projectService.deleteProject(projectId);
                notifier.success(`Le projet a bien été supprimé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------------------------------- Users

        async getUsers(context, { projectId }) {
            try {
                return await projectService.getUsers(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async addUser(context, { projectId, userId, role, credentials }) {
            try {
                await projectService.addUser(
                    projectId,
                    userId,
                    role,
                    credentials,
                );
                notifier.success(`L'utilisateur a bien été associé au projet`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async editUser(context, { projectId, userId, role, credentials }) {
            try {
                await projectService.editUser(
                    projectId,
                    userId,
                    role,
                    credentials,
                );
                notifier.success(`L'utilisateur a bien été modifié`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async removeUser(context, { projectId, userId }) {
            try {
                await projectService.removeUser(projectId, userId);
                notifier.success(`L'utilisateur a bien été retiré du projet`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------------ Contacts

        async getContacts(context, { projectId }) {
            try {
                return await projectService.getContacts(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async addContact(context, { projectId, contactId }) {
            try {
                await projectService.addContact(projectId, contactId);
                notifier.success(`Le contact a bien été associé au projet`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async removeContact(context, { projectId, contactId }) {
            try {
                await projectService.removeContact(projectId, contactId);
                notifier.success(`Le contact a bien été retiré du projet`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------- Installations

        async getInstallations(context, { projectId, notv }) {
            try {
                const installations = await projectService.getInstallations(
                    projectId,
                    notv,
                );
                return installations;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getInstallationsNoTV(context, { projectId }) {
            try {
                const installations = await projectService.getInstallations(
                    projectId,
                    true,
                );
                return installations;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getInstallation(context, { projectId, installationId }) {
            try {
                const installation = await projectService.getInstallation(
                    projectId,
                    installationId,
                );
                return installation;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createInstallation(
            context,
            {
                projectId,
                name,
                type,
                longitude,
                latitude,
                place,
                specs,
                config,
                deviceId,
            },
        ) {
            try {
                let response = await projectService.createInstallation(
                    projectId,
                    name,
                    type,
                    longitude,
                    latitude,
                    place,
                    specs,
                    config,
                    deviceId,
                );
                notifier.success(`L'installation a bien été créée`);
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateInstallation(
            context,
            {
                projectId,
                installationId,
                name,
                type,
                longitude,
                latitude,
                place,
                specs,
                config,
                deviceId,
            },
        ) {
            try {
                await projectService.updateInstallation(
                    projectId,
                    installationId,
                    name,
                    type,
                    longitude,
                    latitude,
                    place,
                    specs,
                    config,
                    deviceId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteInstallation(context, { projectId, installationId }) {
            try {
                await projectService.deleteInstallation(
                    projectId,
                    installationId,
                );
                notifier.success(`L'installation a bien été supprimée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------------------------------- Arbos

        async getArbos(context, { projectId }) {
            try {
                const arbos = await projectService.getArbos(projectId);
                return arbos;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getArbo(context, { projectId, arboId }) {
            try {
                const arbo = await projectService.getArbo(projectId, arboId);
                return arbo;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createArbo(context, { projectId, name, structure }) {
            try {
                const arbo = await projectService.createArbo(
                    projectId,
                    name,
                    structure,
                );
                notifier.success(`L'arborescence a bien été créée`);
                return arbo;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateArbo(context, { projectId, arboId, name }) {
            try {
                await projectService.updateArbo(projectId, arboId, name);
                notifier.success(`L'arborescence a bien été modifiée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteArbo(context, { projectId, arboId }) {
            try {
                await projectService.deleteArbo(projectId, arboId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------------- Modules

        async getModules(context, { projectId }) {
            try {
                return await projectService.getModules(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getModule(context, { projectId, type }) {
            try {
                return await projectService.getModule(projectId, type);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ---------------------------------------------------------------------
        // ------------------------------------------------------------- Modules
        // ---------------------------------------------------------------------

        async getProjectModules(context, { projectId }) {
            try {
                return await projectService.getProjectModules(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createProjectModule(context, { projectId, moduleId }) {
            try {
                await projectService.createProjectModule(projectId, moduleId);
                notifier.success(`Le module a bien été ajouté`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getProjectModule(context, { projectId, moduleId }) {
            try {
                return await projectService.getProjectModule(
                    projectId,
                    moduleId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateProjectModule(
            context,
            { projectId, moduleId, data, enabled },
        ) {
            try {
                await projectService.updateProjectModule(
                    projectId,
                    moduleId,
                    data,
                    enabled,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteProjectModule(context, { projectId, moduleId }) {
            try {
                let response = await projectService.deleteProjectModule(
                    projectId,
                    moduleId,
                );
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        // -------------------------------------------------------- Module Media

        async getMediaCollection(
            context,
            { projectId, serviceId, collectionId },
        ) {
            try {
                return await projectService.getMediaCollection(
                    projectId,
                    serviceId,
                    collectionId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMedias(context, { projectId, serviceId, collectionId }) {
            try {
                return await projectService.getMedias(
                    projectId,
                    serviceId,
                    collectionId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPMedias(
            context,
            { projectId, serviceId, collectionId, filter },
        ) {
            try {
                return await projectService.countFSPMedias(
                    projectId,
                    serviceId,
                    collectionId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPMedias(
            context,
            {
                projectId,
                serviceId,
                collectionId,
                filter,
                sortby,
                limit,
                offset,
            },
        ) {
            try {
                return await projectService.getFSPMedias(
                    projectId,
                    serviceId,
                    collectionId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createMedia(
            context,
            { projectId, serviceId, collectionId, file, metadata },
        ) {
            try {
                return await projectService.createMedia(
                    projectId,
                    serviceId,
                    collectionId,
                    file,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMedia(
            context,
            { projectId, serviceId, collectionId, mediaId, file, metadata },
        ) {
            try {
                return await projectService.updateMedia(
                    projectId,
                    serviceId,
                    collectionId,
                    mediaId,
                    file,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
