<template>
    <div>
        <template v-for="collection in collections">
            <div class="mb-5" :key="collection.id">
                <h3>
                    <small>Collection</small>
                    {{ collection.name }}
                </h3>
                <GridMedia
                    :project-id="projectId"
                    :service-id="collection.service"
                    :collection-id="collection.id"
                    @update-media="onUpdateMedia(collection, $event)"
                />
            </div>
        </template>

        <ModalUpdateMedia
            :context="context"
            :media="selectedMedia"
            :isAdmin="false"
            @saved="fetchCollections"
        />
    </div>
</template>

<script>
import GridMedia from "../components/media/GridMedia.vue";
import ModalUpdateMedia from "@/components/model/media/ModalUpdateMedia.vue";

export default {
    components: {
        GridMedia,
        ModalUpdateMedia,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        config: { type: Object, required: true },
    },

    data: function() {
        return {
            collections: [],
            selectedCollection: {},
            selectedMedia: {},
        };
    },

    computed: {
        context() {
            return {
                target: "project",
                projectId: this.projectId,
                collectionId: this.selectedCollection.id,
                serviceId: this.selectedCollection.service,
                mime: this.selectedCollection.mime,
            };
        },
    },

    watch: {
        config: {
            handler: async function(value) {
                if (Array.isArray(value.collections)) {
                    await this.fetchCollections();
                }
            },
            immediate: true,
        },
    },

    methods: {
        async fetchCollections() {
            this.collections = [];
            for (const c of this.config.collections) {
                let collection = await this.$store.dispatch(
                    "project/getMediaCollection",
                    {
                        projectId: this.projectId,
                        serviceId: this.config.service,
                        collectionId: c,
                    },
                );
                if (collection) {
                    collection.service = this.config.service;
                    this.collections.push(collection);
                }
            }
        },

        async onUpdateMedia(collection, media) {
            this.selectedCollection = collection;
            this.selectedMedia = media;
            this.$bvModal.show("ModalUpdateMedia");
        },
    },
};
</script>
+
