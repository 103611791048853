<template>
    <div class="k-row">
        <ImagePreview width="70px" height="80px" :url="value" />

        <b-button class="btn" @click="$bvModal.show(uid)">
            Choisir une nouvelle icône
        </b-button>

        <ModalIconBank
            :modal-id="uid"
            :bank-list="[
                {
                    name: 'Collection de base',
                    url: 'https://media.kalkin-tourisme.fr/11/BaseIconPack',
                },
                {
                    name: 'Collection Stick',
                    url: 'https://media.kalkin-tourisme.fr/8/StickIconPack',
                },
                {
                    name: 'Collection Contour',
                    url: 'https://media.kalkin-tourisme.fr/9/EdgeIconPack',
                },
            ]"
            @icon-selected="onIconSelected"
        />
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import ImagePreview from "@/components/ui/item/ImagePreview.vue";
import ModalIconBank from "@/components/icon-bank/ModalIconBank.vue";

export default {
    components: {
        ImagePreview,
        ModalIconBank,
    },

    data: function() {
        return {
            uid: this.getFieldID(this.schema, true),
        };
    },

    methods: {
        onIconSelected(icon) {
            this.value = icon;
        },
    },

    mixins: [abstractField],
};
</script>

<style lang="scss" scoped>
.k-row {
    display: flex !important;
    align-items: center;
    gap: 20px;
    margin-left: 10px;
}

.btn {
    font-size: 14px !important;
    padding: 3px 10px !important;
    color: $secondary-color !important;
    border-color: $secondary-color !important;
}
.btn:hover {
    color: white !important;
    background: $secondary-color !important;
}
</style>
