export default function daysSinceISO(input) {
    const d = new Date(input).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const diffDays = Math.round(Math.abs((today - d) / 86400000));

    switch (diffDays) {
        case 0:
            return "Aujourd'hui";
        case 1:
            return "Hier";
        default:
            return `Il y a ${diffDays} jours`;
    }
}
