<template>
    <b-breadcrumb :items="items" />
</template>

<script>
export default {
    computed: {
        items: function() {
            const router = this.$router;
            function _goBackRoutes(name, { title, parent }, deep = 0) {
                let ancestors = [];
                if (deep < 10 && parent) {
                    // Breadcrumb manuel
                    if (Array.isArray(parent)) {
                        ancestors = parent.map(p => {
                            const to = { name: p };
                            const { route } = router.resolve(to);
                            return { text: route.meta.title, to };
                        });
                    }
                    // Breadcrumb auto
                    else {
                        const { route } = router.resolve({
                            name: parent,
                        });
                        if (route) {
                            ancestors = _goBackRoutes(
                                route.name,
                                route.meta,
                                deep++,
                            );
                        }
                    }
                }
                return [...ancestors, { text: title, to: name && { name } }];
            }
            return _goBackRoutes(null, this.$route.meta);
        },
    },
};
</script>

<style lang="scss">
.breadcrumb {
    background-color: $theme-bg-2 !important;

    border-style: solid;
    border-color: $theme-bg-3;
    border-width: 2px 2px 2px 0px;
    border-radius: 0 6px 6px 0 !important;
    overflow: hidden;
    padding: 0 15px !important;
}
.breadcrumb a {
    color: $secondary-color !important;
    transition: color 0.4s;
}
.breadcrumb a:hover {
    color: $ternary-color !important;
    text-decoration: none;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: "/" !important;
    font-family: "Courier New", Courier, monospace;
    font-size: 50px;
    color: $theme-bg-3 !important;
    margin: 0 -5px;
}

.breadcrumb-item.active {
    color: $theme-fg-2 !important;
}

.breadcrumb-item {
    height: 100%;
    display: flex;
    align-items: center;
}
</style>
