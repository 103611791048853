import HTTP from "./http-common";

export default {
    async getDataSourceTypes() {
        const response = await HTTP({
            method: "GET",
            url: `/datasourcetypes`,
            params: {
                sortby: "name",
            },
        });
        return response.data.data;
    },

    async countFSPDataSourceTypes(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/datasourcetypes`,
            params: { filter },
        });
        return response.headers["pagination-count"];
    },

    async getFSPDataSourceTypes(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/datasourcetypes`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getDataSourcesForDataSourceTypes(dataSourceTypeId) {
        const response = await HTTP({
            method: "GET",
            url: `/datasourcetype/${dataSourceTypeId}/datasources`,
        });
        return response.data.data;
    },

    async createDataSource(projectId, name, dataSourceTypeId, data) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/datasources`,
            data: { name, dataSourceTypeId, data },
        });
        return response.data.data;
    },

    async getDataSources(projectId, includeSelections = false) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/datasources?includeSelections=${includeSelections}`,
        });
        return response.data.data;
    },

    async getDataSource(projectId, datasourceId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/datasource/${datasourceId}`,
        });
        return response.data.data;
    },

    async deleteDataSource(projectId, dataSourceId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/datasource/${dataSourceId}`,
        });
        return response.data.data;
    },

    async getDataSelections(projectId, datasourceId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/datasource/${datasourceId}/dataselections`,
        });
        return response.data.data;
    },

    async createDataSelection(
        projectId,
        datasourceId,
        name,
        prefilter,
        data,
        langId,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/datasource/${datasourceId}/dataselections`,
            data: { name, prefilter, data, langId },
        });
        return response.data.data;
    },

    async updateDataSelection(
        projectId,
        dataselectionId,
        name,
        prefilter,
        data,
        langId,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/dataselection/${dataselectionId}`,
            data: { name, data, prefilter, langId },
        });
        return response.data.data;
    },

    async deleteDataSelection(projectId, dataselectionId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/dataselection/${dataselectionId}`,
        });
        return response.data.data;
    },

    async associateDataSelection(projectId, menuId, dataSelectionId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/menu/${menuId}/dataselections`,
            data: { dataSelectionId },
        });
        return response.data.data;
    },

    async getDataSelectionMenu(projectId, menuId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/menu/${menuId}/dataselections`,
        });
        return response.data.data;
    },

    async deleteMenuDataSelection(projectId, menuId, dataselectionId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/menu/${menuId}/dataselection/${dataselectionId}`,
        });
        return response.data.data;
    },

    async getMenuDataSelection(projectId, menuId, dataselectionId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/menu/${menuId}/dataselection/${dataselectionId}`,
        });
        return response.data.data;
    },

    async updateMenuDataSelection(
        projectId,
        menuId,
        dataselectionId,
        config,
        filter,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/menu/${menuId}/dataselection/${dataselectionId}`,
            data: { config, filter },
        });
        return response.data.data;
    },

    async editDataSource(projectId, datasourceId, name, data) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/datasource/${datasourceId}`,
            data: { name, data },
        });
        return response.data.data;
    },

    async getDataSelection(projectId, dataselectionId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/dataselection/${dataselectionId}`,
        });
        return response.data.data;
    },

    async getDataSelectionPreview(projectId, dataselectionId, refresh) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/dataselection/${dataselectionId}/preview`,
            data: { refresh },
        });
        // Direct data node
        return response.data;
    },
};
