<template>
    <div class="w-100 d-flex">
        <template v-for="index in dimension">
            <div class="d-flex flex-fill k-group" :key="index">
                <label>{{ labels[index - 1] }}</label>
                <b-form-input size="sm" type="number" v-model="raw[index - 1]">
                </b-form-input>
            </div>
        </template>
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

export default {
    data: function() {
        return {
            labels: ["x", "y", "z", "w"],
            dimension: this.schema.dimension || 2,
            raw: this.initRaw(this.schema.dimension),
        };
    },

    computed: {
        isValid() {
            return (
                this.raw.every(n => !isNaN(n) && n.length) ||
                this.raw.every(n => !n.length)
            );
        },
    },

    watch: {
        raw() {
            let content = this.raw.join(",");
            this.value =
                content.length >= this.dimension ? `(${content})` : null;
        },
    },

    methods: {
        initRaw(dimension) {
            if (this.value) {
                try {
                    let array = this.value.slice(1, -1).split(",");
                    if (array.length === dimension) {
                        return array.map(n => (isNaN(n) ? "0" : n));
                    }
                } catch (error) {
                    /* Do nothing */
                }
            }
            return Array.from({ length: dimension }, () => "");
        },

        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire !",
                );
            } else if (this.value && !this.isValid) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText ||
                        "Tous les champs doivent avoir une valeur !",
                );
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>

<style lang="scss" scoped>
.k-group {
    box-sizing: border-box;
    margin: 0 10px;
    background-color: #cccccc;
    border-radius: 5px;
}
.k-group label {
    color: white;
    font-weight: bold;
    margin: auto 8px;
}
</style>
