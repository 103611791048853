<template>
    <div id="main">
        <div id="map-container">
            <MapViewer
                class="map-content"
                v-bind="$props"
                @map-ready="onMapReady"
            />
            <div
                id="map-overlay"
                class="map-content"
                @click="displayOverlay = false"
                v-show="displayOverlay"
            >
                <span>Cliquez ici pour dessiner un rectangle</span>
            </div>
        </div>
        <div id="form">
            <b-form-group id="xmin-group">
                <label>Longitude minimale</label>
                <b-form-input
                    id="xmin"
                    v-model="xmin"
                    step="0.00001"
                    min="-180"
                    max="180"
                    type="number"
                    size="sm"
                    :disabled="!rectangleLayer"
                    @change="onCoordsChange"
                />
            </b-form-group>
            <b-form-group id="xmax-group">
                <label>Longitude maximale</label>
                <b-form-input
                    id="xmax"
                    v-model="xmax"
                    step="0.00001"
                    min="-180"
                    max="180"
                    type="number"
                    size="sm"
                    :disabled="!rectangleLayer"
                    @change="onCoordsChange"
                />
            </b-form-group>
            <b-form-group id="ymin-group">
                <label>Latitude minimale</label>
                <b-form-input
                    id="ymin"
                    v-model="ymin"
                    step="0.00001"
                    min="-90"
                    max="90"
                    type="number"
                    size="sm"
                    :disabled="!rectangleLayer"
                    @change="onCoordsChange"
                />
            </b-form-group>
            <b-form-group id="ymax-group">
                <label>Latitude maximale</label>
                <b-form-input
                    id="ymax"
                    v-model="ymax"
                    step="0.00001"
                    min="-90"
                    max="90"
                    type="number"
                    size="sm"
                    :disabled="!rectangleLayer"
                    @change="onCoordsChange"
                />
            </b-form-group>
        </div>
    </div>
</template>
<script>
import { LExtended as L } from "geoportal-extensions-leaflet";
import MapViewer from "./MapViewer";

const shapeStyle = {
    color: "#41A934",
    fillColor: "#AFCB22",
    fillOpacity: 0.4,
};

const sortNumber = (a, b) => a - b;

export default {
    components: {
        MapViewer,
    },

    props: {
        center: Array,
        zoom: Number,
        value: Object,
    },

    data: function() {
        if (this.value) {
            return {
                map: null,
                displayOverlay: false,
                rectangleLayer: this.createRectangle(
                    this.value.xmin,
                    this.value.xmax,
                    this.value.ymin,
                    this.value.ymax,
                ),
                xmin: this.value.xmin,
                xmax: this.value.xmax,
                ymin: this.value.ymin,
                ymax: this.value.ymax,
            };
        } else {
            return {
                map: null,
                displayOverlay: true,
                rectangleLayer: null,
                xmin: "",
                xmax: "",
                ymin: "",
                ymax: "",
            };
        }
    },

    watch: {
        value() {
            if (this.value) {
                if (
                    this.xmin !== this.value.xmin ||
                    this.xmax !== this.value.xmax ||
                    this.ymin !== this.value.ymin ||
                    this.ymax !== this.value.ymax
                ) {
                    this.xmin = this.value.xmin;
                    this.xmax = this.value.xmax;
                    this.ymin = this.value.ymin;
                    this.ymax = this.value.ymax;
                    this.displayOverlay = false;
                    this.rectangleLayer = this.createRectangle(
                        this.value.xmin,
                        this.value.xmax,
                        this.value.ymin,
                        this.value.ymax,
                    );
                }
            } else {
                this.displayOverlay = true;
                this.xmin = "";
                this.xmax = "";
                this.ymin = "";
                this.ymax = "";
                this.initNewRectangle();
            }
        },
    },

    methods: {
        createRectangle(xmin, xmax, ymin, ymax) {
            let layer = L.rectangle(
                [
                    [Number(ymin), Number(xmin)],
                    [Number(ymax), Number(xmax)],
                ],
                shapeStyle,
            );
            if (this.map) {
                if (this.rectangleLayer) {
                    this.map.removeLayer(this.rectangleLayer);
                }
                layer.addTo(this.map);
                layer.on("pm:markerdragend", this.onRectangleChange);
                layer.pm.enable();
            }
            return layer;
        },

        initNewRectangle() {
            if (this.map) {
                if (this.rectangleLayer) {
                    this.map.removeLayer(this.rectangleLayer);
                    this.rectangleLayer = null;
                }
                this.map.pm.enableDraw("Rectangle", {});
            }
        },

        onMapReady: function(map) {
            this.map = map;
            map.pm.setLang("fr");
            map.pm.setPathOptions(shapeStyle);

            map.on("pm:create", ({ layer }) => {
                this.rectangleLayer = layer;
                this.map.pm.disableDraw();
                this.rectangleLayer.on(
                    "pm:markerdragend",
                    this.onRectangleChange,
                );
                this.rectangleLayer.pm.enable();
                this.onRectangleChange();
            });

            if (this.rectangleLayer) {
                this.rectangleLayer.addTo(map);
                this.rectangleLayer.on(
                    "pm:markerdragend",
                    this.onRectangleChange,
                );
                this.rectangleLayer.pm.enable();
                map.fitBounds(this.rectangleLayer.getBounds(), {
                    padding: [50, 50],
                });
            } else {
                this.initNewRectangle();
            }
        },

        onRectangleChange: function() {
            const coords = this.rectangleLayer.getLatLngs()[0];
            this.xmin = coords[0].lng.toFixed(5);
            this.xmax = coords[2].lng.toFixed(5);
            this.ymin = coords[0].lat.toFixed(5);
            this.ymax = coords[2].lat.toFixed(5);
            [this.xmin, this.xmax] = [this.xmin, this.xmax].sort(sortNumber);
            [this.ymin, this.ymax] = [this.ymin, this.ymax].sort(sortNumber);
            this.onChange();
        },

        onCoordsChange: function() {
            [this.xmin, this.xmax] = [this.xmin, this.xmax].sort(sortNumber);
            [this.ymin, this.ymax] = [this.ymin, this.ymax].sort(sortNumber);
            this.rectangleLayer.setLatLngs([
                [
                    { lat: Number(this.ymin), lng: Number(this.xmin) },
                    { lat: Number(this.ymax), lng: Number(this.xmin) },
                    { lat: Number(this.ymax), lng: Number(this.xmax) },
                    { lat: Number(this.ymin), lng: Number(this.xmax) },
                ],
            ]);
            this.rectangleLayer.pm._initMarkers();
            this.rectangleLayer.pm.enable();
            this.onChange();
        },

        onChange: function() {
            this.$emit("input", {
                system: "wgs84",
                xmin: this.xmin,
                xmax: this.xmax,
                ymin: this.ymin,
                ymax: this.ymax,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#main {
    display: flex;
    justify-content: center;
    align-items: stretch;

    flex-wrap: wrap;
    width: 100%;
}
#map-container {
    flex-grow: 2;
    flex-basis: 400px;
    min-height: 300px;
    height: auto;
    position: relative;
}
.map-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 20px) !important;
    margin: 0 10px;
}
#map-overlay {
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    background: radial-gradient(
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.7) 50%,
        rgba(255, 255, 255, 0.9) 100%
    );
    padding: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#map-overlay:hover {
    cursor: pointer;
}
#form {
    flex-grow: 1;
    flex-basis: 200px;

    padding: 5px 15px;
}
</style>
