import cronjobService from "../../services/cron_job";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async countFSPCronjobs(context, { filter }) {
            try {
                return await cronjobService.countFSPCronjobs(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPCronjobs(context, { filter, sortby, limit, offset }) {
            try {
                return await cronjobService.getFSPCronjobs(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createCronjob(
            context,
            { name, description, enabled, time, action },
        ) {
            try {
                await cronjobService.createCronJob(
                    name,
                    description,
                    enabled,
                    time,
                    action,
                );
                notifier.success(`La tâche a bien été créée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteCronjob(context, { id }) {
            try {
                await cronjobService.deleteCronJob(id);
                notifier.success(`La tâche a bien été supprimée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateCronjob(
            context,
            { id, name, description, enabled, time, action },
        ) {
            try {
                await cronjobService.updateCronJob(
                    id,
                    name,
                    description,
                    enabled,
                    time,
                    action,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
