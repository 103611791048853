import applicationService from "../../services/application";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async getApplicationTypes() {
            try {
                return await applicationService.getApplicationTypes();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createApplication(
            context,
            { name, description, type, template },
        ) {
            try {
                await applicationService.createApplication(
                    name,
                    description,
                    type,
                    template,
                );
                notifier.success(
                    `L'application <b>${name}</b> a bien été créée`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplication(context, { applicationId }) {
            try {
                return await applicationService.getApplication(applicationId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplications() {
            try {
                return await applicationService.getApplications();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getLastApplications(context, { n }) {
            try {
                return await applicationService.getLastApplications(n);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteApplication(context, { applicationId }) {
            try {
                return await applicationService.deleteApplication(
                    applicationId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateApplication(
            context,
            { applicationId, name, description, type, template },
        ) {
            try {
                await applicationService.updateApplication(
                    applicationId,
                    name,
                    type,
                    description,
                    template,
                );
                notifier.success(
                    `L'application <b>${name}</b> a bien été mise à jour`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createApplicationBuild(
            context,
            {
                applicationId,
                version,
                notes,
                config,
                previousId,
                templateConfigId,
            },
        ) {
            try {
                await applicationService.createApplicationBuild(
                    applicationId,
                    version,
                    notes,
                    config,
                    previousId,
                    templateConfigId,
                );
                notifier.success(
                    `Le build d'application <b>${version}</b> a bien été créé`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationBuilds(context, { applicationId }) {
            try {
                return await applicationService.getApplicationBuilds(
                    applicationId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationBuild(context, { applicationBuildId }) {
            try {
                let response = await applicationService.getApplicationBuild(
                    applicationBuildId,
                );
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateApplicationBuild(
            context,
            { applicationBuildId, version, notes, config, templateConfigId },
        ) {
            try {
                await applicationService.updateApplicationBuild(
                    applicationBuildId,
                    version,
                    notes,
                    config,
                    templateConfigId,
                );
                notifier.success(
                    `Le build d'application <b>${version}</b> a bien été mis à jour`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteApplicationBuild(context, { applicationBuildId }) {
            try {
                let response = await applicationService.deleteApplicationBuild(
                    applicationBuildId,
                );
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ---------------------------------------------------- AppBuild Configs

        async countFSPApplicationBuildConfigs(context, { buildId, filter }) {
            try {
                return await applicationService.countFSPApplicationBuildConfigs(
                    buildId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPApplicationBuildConfigs(
            context,
            { buildId, filter, sortby, limit, offset },
        ) {
            try {
                return await applicationService.getFSPApplicationBuildConfigs(
                    buildId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------

        async getAllApplicationFiltred(context, { filter }) {
            try {
                return await applicationService.getAllApplicationFiltred(
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createApplicationConfig(
            context,
            { projectId, buildId, config, style, name },
        ) {
            try {
                await applicationService.createApplicationConfig(
                    projectId,
                    buildId,
                    config,
                    style,
                    name,
                );
                notifier.success(
                    `La configuration <b>${name}</b> a bien été créée`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationConfigs(context, { projectId }) {
            try {
                return await applicationService.getApplicationConfigs(
                    projectId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationConfig(context, { projectId, configId }) {
            try {
                return await applicationService.getApplicationConfig(
                    projectId,
                    configId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationConfigBuildConfig(
            context,
            { projectId, configId },
        ) {
            try {
                return await applicationService.getApplicationConfigBuildConfig(
                    projectId,
                    configId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateApplicationConfig(
            context,
            { projectId, configId, buildId, config, style, name },
        ) {
            try {
                await applicationService.updateApplicationConfig(
                    projectId,
                    configId,
                    buildId,
                    config,
                    style,
                    name,
                );
                notifier.success(`La configuration a bien été mise à jour`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteApplicationConfig(context, { projectId, configId }) {
            try {
                return await applicationService.deleteApplicationConfig(
                    projectId,
                    configId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationConfigArbos(context, { projectId, configId }) {
            try {
                return await applicationService.getApplicationConfigArbos(
                    projectId,
                    configId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async associateArboAppConfig(
            context,
            { projectId, configurationId, arboId },
        ) {
            try {
                await applicationService.associateArboAppConfig(
                    projectId,
                    configurationId,
                    arboId,
                );
                notifier.success(
                    `L'arborescence a bien été associée à la configuration`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateArboAppConfig(
            context,
            { projectId, acaId, currentArboSnapshotId },
        ) {
            try {
                await applicationService.updateArboAppConfig(
                    projectId,
                    acaId,
                    currentArboSnapshotId,
                );
                notifier.success(`L'association a bien été mise à jour`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async removeAssociationArbo(
            context,
            { projectId, configurationId, arboId },
        ) {
            try {
                await applicationService.removeAssociationArbo(
                    projectId,
                    configurationId,
                    arboId,
                );
                notifier.success(
                    `L'arborescence a bien été dissociée de la configuration`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMenusAssociable(context, { projectId, configId, arboId }) {
            try {
                return await applicationService.getMenusAssociable(
                    projectId,
                    configId,
                    arboId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMenuStyle(context, { projectId, configId, menuId }) {
            try {
                return await applicationService.getMenuStyle(
                    projectId,
                    configId,
                    menuId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createMenuStyle(context, { projectId, configId, menuId, style }) {
            try {
                await applicationService.createMenuStyle(
                    projectId,
                    configId,
                    menuId,
                    style,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMenuStyle(context, { projectId, configId, menuId, style }) {
            try {
                await applicationService.updateMenuStyle(
                    projectId,
                    configId,
                    menuId,
                    style,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMenuStyle(context, { projectId, configId, menuId }) {
            try {
                await applicationService.deleteMenuStyle(
                    projectId,
                    configId,
                    menuId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------- InstallationApplicationConfig

        async getInstallations(context, { projectId, configId, teamviewer }) {
            try {
                return await applicationService.getInstallations(
                    projectId,
                    configId,
                    teamviewer,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async addInstallation(
            context,
            { projectId, configId, installationId },
        ) {
            try {
                await applicationService.addInstallation(
                    projectId,
                    configId,
                    installationId,
                );
                notifier.success(
                    `L'installation a bien été associée à la configuration`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async removeInstallation(
            context,
            { projectId, configId, installationId },
        ) {
            try {
                await applicationService.removeInstallation(
                    projectId,
                    configId,
                    installationId,
                );
                notifier.success(
                    `L'installation a bien été retirée de la configuration`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------ TemplateConfig

        async getTemplateConfigs(context, { applicationId }) {
            try {
                return await applicationService.getTemplateConfigs(
                    applicationId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createTemplateConfig(
            context,
            { applicationId, version, description, config },
        ) {
            try {
                await applicationService.createTemplateConfig(
                    applicationId,
                    version,
                    description,
                    config,
                );
                notifier.success(
                    `La configuration de template <b>${version}</b> a bien été créée`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTemplateConfig(context, { templateConfigId }) {
            try {
                return await applicationService.getTemplateConfig(
                    templateConfigId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateTemplateConfig(
            context,
            { templateConfigId, version, description, config },
        ) {
            try {
                await applicationService.updateTemplateConfig(
                    templateConfigId,
                    version,
                    description,
                    config,
                );
                notifier.success(
                    `La configuration de template <b>${version}</b> a bien été mise à jour`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteTemplateConfig(context, { templateConfigId }) {
            try {
                return await applicationService.deleteTemplateConfig(
                    templateConfigId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------- ApplicationConfigArbo CronJob

        async createACACronJob(context, { projectId, acaId }) {
            try {
                await applicationService.createACACronJob(projectId, acaId);
                notifier.success(`Programmation initialisée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateACACronJob(context, { projectId, acaId, time, enabled }) {
            try {
                return await applicationService.updateACACronJob(
                    projectId,
                    acaId,
                    time,
                    enabled,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteACACronJob(context, { projectId, acaId }) {
            try {
                return await applicationService.deleteACACronJob(
                    projectId,
                    acaId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
