<template>
    <div class="tag-input">
        <template v-for="(tag, index) in value">
            <b-badge class="badge" :key="tag" variant="secondary">
                {{ tag }}
                <b-icon-x-circle
                    class="ml-1 pointer"
                    variant="kalkin-2"
                    @click="removeTag(index)"
                />
            </b-badge>
        </template>
        <span class="d-inline-block">
            <b-icon-check-circle-fill
                v-show="tag"
                class="ml-2 pointer"
                variant="kalkin-2"
                @click="addTag"
            />
            <input
                class="ml-1 tag-input-text"
                type="text"
                placeholder="Ajouter un tag"
                v-model="tag"
                @keydown.enter.prevent="addTag"
                @keydown.enter.188.prevent="addTag"
                @blur="addTag"
                trim
            />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] },
    },

    data() {
        return {
            tag: "",
        };
    },

    methods: {
        addTag() {
            if (this.tag.length > 0) {
                if (!this.value.includes(this.tag)) {
                    this.value.push(this.tag);
                    this.$emit("input", this.value);
                }
                this.tag = "";
            }
        },

        removeTag(index) {
            this.value.splice(index, 1);
            this.$emit("input", this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-input {
    width: 100%;
    // height: auto;
    padding: 0 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;

    & .badge {
        padding: 4px 6px 4px 8px;
        margin: 0 2px;
    }
}

.tag-input-text {
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 37px;
    background: none;
}
</style>
