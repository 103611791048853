<template>
    <vue-form-generator
        :schema="expandedSchema"
        :model="localModel"
        :options="formOptions"
        @validated="onValidated"
        @model-updated="onUpdated"
    />
</template>
<script>
import Vue from "vue";

export default {
    props: {
        schema: Object,
        model: Object,
        payload: Object,
    },

    data() {
        return {
            formOptions: {
                validateAfterLoad: true,
                validateAfterChanged: true,
                validateAsync: true,
                payload: this.payload,
            },
        };
    },

    watch: {
        payload(v) {
            this.formOptions = {
                validateAfterLoad: true,
                validateAfterChanged: true,
                validateAsync: true,
                payload: v,
            };
        },
    },

    computed: {
        localModel() {
            return Vue.util.extend({}, this.model);
        },

        expandedSchema() {
            if (this.schema) {
                for (const field of this.schema.fields) {
                    if (field.dependsOn) {
                        const prop = field.dependsOn.prop || "visible";
                        field[prop] = function(model) {
                            const value = field.dependsOn.value;
                            if (Array.isArray(value)) {
                                return (
                                    model &&
                                    value.includes(model[field.dependsOn.field])
                                );
                            } else {
                                return (
                                    model &&
                                    value === model[field.dependsOn.field]
                                );
                            }
                        };
                    }
                }
            }
            return this.schema;
        },
    },

    methods: {
        onValidated(isValid) {
            this.$emit("validated", isValid);
        },
        onUpdated() {
            this.$emit("updated", this.localModel);
        },
    },
};
</script>

<style lang="scss">
.form-group {
    text-align: left;
}
.vue-form-generator .field-switch input:checked ~ .label {
    background: $secondary-color;
}
</style>
