<template>
    <FormMedia :context="context" @saved="onSaved" class="mb-2 h-50" />
</template>

<script>
import FormMedia from "@/components/model/media/FormMedia";
export default {
    components: {
        FormMedia,
    },

    props: {
        context: { type: Object, required: true },
    },

    methods: {
        onSaved(payload) {
            this.$emit("select", payload);
        },
    },
};
</script>
