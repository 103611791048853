import HTTP from "./http-common";

export default {
    async getProject(id) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${id}`,
        });
        return response.data.data;
    },

    async getProjectMeta(id) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${id}/meta`,
        });
        return response.data.data;
    },

    async getMyProjects() {
        const response = await HTTP({
            method: "GET",
            url: `/user/me/projects`,
        });
        return response.data.data;
    },

    async getMyLastProjects(n) {
        const response = await HTTP({
            method: "GET",
            url: `/user/me/projects`,
            params: {
                sortby: "-createdAt",
                limit: n,
            },
        });
        return response.data.data;
    },

    async getAllProjects() {
        const response = await HTTP({
            method: "GET",
            url: "/admin/projects",
        });
        return response.data.data;
    },

    async getLastProjects(n) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/projects",
            params: {
                sortby: "-createdAt",
                limit: n,
            },
        });
        return response.data.data;
    },

    async createProject(name, description) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/projects`,
            data: { name, description },
        });
        return response.data.data;
    },

    async countFSPProjects(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: "/admin/projects",
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPProjects(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/projects",
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async updateProject(projectId, name, description, data, command) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/project/${projectId}`,
            data: { name, description, data, command },
        });
        return response.data.data;
    },

    async deleteProject(projectId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/project/${projectId}`,
        });
        return response.data.data;
    },

    // ------------------------------------------------------------------- Users

    async getUsers(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/users`,
        });
        return response.data.data;
    },

    async addUser(projectId, userId, role, credentials) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/users`,
            data: { userId, role, credentials },
        });
        return response.data.data;
    },

    async editUser(projectId, userId, role, credentials) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/user/${userId}`,
            data: { userId, role, credentials },
        });
        return response.data.data;
    },

    async removeUser(projectId, userId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/user/${userId}`,
        });
        return response.data.data;
    },

    // ---------------------------------------------------------------- Contacts

    async getContacts(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/contacts`,
        });
        return response.data.data;
    },

    async addContact(projectId, contactId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/contacts`,
            data: { contactId },
        });
        return response.data.data;
    },

    async removeContact(projectId, contactId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/contact/${contactId}`,
        });
        return response.data.data;
    },

    // ----------------------------------------------------------- Installations

    async getInstallations(projectId, notv) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/installations`,
            params: { notv },
        });
        return response.data.data;
    },

    async getInstallation(projectId, installationId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/installation/${installationId}`,
        });
        return response.data.data;
    },

    async createInstallation(
        projectId,
        name,
        type,
        longitude,
        latitude,
        place,
        specs,
        config,
        deviceId,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/installations`,
            data: {
                name,
                type,
                longitude,
                latitude,
                place,
                specs,
                config,
                deviceId,
            },
        });
        return { data: response.data.data, type: "success" };
    },

    async updateInstallation(
        projectId,
        installationId,
        name,
        type,
        longitude,
        latitude,
        place,
        specs,
        config,
        deviceId,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/installation/${installationId}`,
            data: {
                name,
                type,
                longitude,
                latitude,
                place,
                specs,
                config,
                deviceId,
            },
        });
        return { data: response.data.data, type: "success" };
    },

    async deleteInstallation(projectId, installationId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/installation/${installationId}`,
        });
        return response.data.data;
    },

    // ------------------------------------------------------------------- Arbos

    async getArbos(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/arbos`,
        });
        return response.data.data;
    },

    async getArbo(projectId, arboId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/arbo/${arboId}`,
        });
        return response.data.data;
    },

    async createArbo(projectId, name, structure) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/arbos`,
            data: { name, structure },
        });
        return response.data.data;
    },

    async updateArbo(projectId, arboId, name) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/arbo/${arboId}`,
            data: { name: name },
        });
        return response.data.data;
    },

    async deleteArbo(projectId, arboId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/arbo/${arboId}`,
        });
        return response.data.data;
    },

    // -------------------------------------------------------------------------
    // ----------------------------------------------------------------- Modules
    // -------------------------------------------------------------------------

    async getProjectModules(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/modules`,
        });
        return response.data.data;
    },

    async createProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/modules`,
            data: { moduleId },
        });
        return response.data.data;
    },

    async getProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/module/${moduleId}`,
        });
        return response.data.data;
    },

    async updateProjectModule(projectId, moduleId, data, enabled) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/module/${moduleId}`,
            data: { data, enabled },
        });
        return response.data.data;
    },

    async deleteProjectModule(projectId, moduleId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/module/${moduleId}`,
        });
        return response.data.data;
    },

    // ------------------------------------------------------------ Module Media

    async getMediaCollection(projectId, serviceId, collectionId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}`,
        });
        return response.data.data;
    },

    async getMedias(projectId, serviceId, collectionId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}/medias`,
        });
        return response.data.data;
    },

    async countFSPMedias(projectId, serviceId, collectionId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}/medias`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPMedias(
        projectId,
        serviceId,
        collectionId,
        filter,
        sortby,
        limit,
        offset,
    ) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}/medias`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async createMedia(projectId, serviceId, collectionId, file, metadata) {
        let bodyFormData = new FormData();
        bodyFormData.set("metadata", JSON.stringify(metadata));
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}/medias`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },

    async updateMedia(
        projectId,
        serviceId,
        collectionId,
        mediaId,
        file,
        metadata,
    ) {
        let bodyFormData = new FormData();
        bodyFormData.set("metadata", JSON.stringify(metadata));
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/media/${serviceId}/collection/${collectionId}/media/${mediaId}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },
};
