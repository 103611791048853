<template>
    <LoadingOverlay class="map-view" :id="id" :isLoading="isLoading">
        <div class="d-flex justify-content-center align-items-center h-100">
            <em class="text-secondary user-select-none" v-if="isLoading">
                Chargement de la carte en cours...
            </em>
            <em
                class="text-danger user-select-none"
                v-if="!isLoading && hasFailed"
            >
                Echec du chargement de la carte. Veuillez réessayer plus tard.
            </em>
        </div>
    </LoadingOverlay>
</template>
<script>
import LoadingOverlay from "@/components/ui/LoadingOverlay";
import { Logger, Services, LExtended as L } from "geoportal-extensions-leaflet";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

let InstanceCounter = 0;

export default {
    components: {
        LoadingOverlay,
    },

    props: {
        id: {
            type: String,
            default: () => "map-" + InstanceCounter++,
        },
        center: {
            type: Array,
            default: () => [46.227638, 2.213749],
        },
        zoom: {
            type: Number,
            default: 5,
        },
    },

    data: function() {
        return {
            isLoading: true,
            hasFailed: false,
            map: null,
            controls: null,
            backupProcess: null,
        };
    },

    methods: {
        initMap() {
            this.map = L.map(this.id, {
                center: this.center,
                zoom: this.zoom,
            });

            this.controls = L.control.layers({});
            this.controls.addTo(this.map);
        },

        initOSM() {
            const layerOSM = L.tileLayer(
                "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
            );
            this.controls.addBaseLayer(layerOSM, "OpenStreetMap");
            this.controls.addTo(this.map);

            this.map.addLayer(layerOSM);

            this.hasFailed = true;
            this.onMapReady();
        },

        async initIGN() {
            Logger.disableAll();
            const config = window && window.Gp && window.Gp.Config;
            if (!config) {
                Services.getConfig({
                    apiKey: "essentiels",
                    onSuccess: this.initIGNLayers,
                });
            } else {
                this.initIGNLayers();
            }
            Logger.disableAll();
        },

        async initIGNLayers() {
            const layerIGNPlan = L.geoportalLayer.WMTS({
                layer: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2",
            });
            const layerIGNOrtho = L.geoportalLayer.WMTS({
                layer: "ORTHOIMAGERY.ORTHOPHOTOS",
            });
            this.controls.addBaseLayer(layerIGNPlan, "IGN Plan");
            this.controls.addBaseLayer(layerIGNOrtho, "IGN Satellite");
            this.controls.addTo(this.map);

            this.map.addLayer(layerIGNPlan);

            this.onMapReady();

            //Annulation de la solution de backup
            clearTimeout(this.backupProcess);
        },

        async onMapReady() {
            setTimeout(this.map.invalidateSize.bind(this.map)); //hack
            this.isLoading = false;
            this.$emit("map-ready", this.map);
        },
    },

    async mounted() {
        // Initialisation de la map, vierge
        this.initMap();

        // Lancement de l'initialisation de la solution de backup
        this.backupProcess = setTimeout(this.initOSM, 6000);

        // Lancement de l'initialisation d'IGN
        await this.initIGN();
    },
};
</script>

<style lang="scss" scoped>
.map-view {
    width: 100%;
    height: 100%;
    border: 1px solid $primary-color;
    box-shadow: 0 0 12px #888;
}
</style>
