var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"k-row"},[_c('ImagePreview',{attrs:{"width":"70px","height":"80px","url":_vm.value}}),_c('b-button',{staticClass:"btn",on:{"click":function($event){return _vm.$bvModal.show(_vm.uid)}}},[_vm._v(" Choisir une nouvelle icône ")]),_c('ModalIconBank',{attrs:{"modal-id":_vm.uid,"bank-list":[
            {
                name: 'Collection de base',
                url: 'https://media.kalkin-tourisme.fr/11/BaseIconPack',
            },
            {
                name: 'Collection Stick',
                url: 'https://media.kalkin-tourisme.fr/8/StickIconPack',
            },
            {
                name: 'Collection Contour',
                url: 'https://media.kalkin-tourisme.fr/9/EdgeIconPack',
            } ]},on:{"icon-selected":_vm.onIconSelected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }