<template>
    <div>
        <div class="text-center text-danger m-5" v-if="!context.projectId">
            <b>Erreur interne</b>
            <div>Formulaire non configuré</div>
        </div>
        <div
            class="text-center text-danger m-5"
            v-else-if="!context.collectionId"
        >
            <b>Erreur interne</b>
            <div>Ressources non initialisées</div>
            <div>
                <small v-if="context.target === 'project'">
                    Aller dans Projet -> Modifier le projet -> Initialiser la
                    collection
                </small>
                <small v-else-if="context.target === 'kbsit'">
                    Aller dans l'onglet Avancé -> Configuration
                </small>
                <small v-else>
                    <b>Erreur interne</b>
                    <div>Contexte non reconnu</div>
                </small>
            </div>
        </div>
        <template v-else>
            <b-card class="border-0" no-body>
                <b-tabs card fill>
                    <b-tab title="Ajouter un nouveau fichier" active>
                        <PanelCreateMedia
                            :context="context"
                            @select="$emit('select', $event)"
                        />
                    </b-tab>
                    <b-tab title="Rechercher un fichier existant" lazy>
                        <PanelFindMedia
                            :context="context"
                            @select="$emit('select', $event)"
                        />
                    </b-tab>
                </b-tabs>
            </b-card>
        </template>
    </div>
</template>

<script>
import PanelCreateMedia from "./PanelCreateMedia";
import PanelFindMedia from "./PanelFindMedia";
export default {
    components: {
        PanelCreateMedia,
        PanelFindMedia,
    },

    props: {
        context: { type: Object },
    },

    data: function() {
        return {
            medias: [],
            filter: "",
        };
    },
};
</script>
