import HTTP from "./http-common";

export default {
    async getActivity(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/activity`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getTouchesTotal(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-all`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getTouchesPerDay(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-day`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getTouchesPerWeekday(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-weekday`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getTouchesPerHour(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-hour`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getSessionsTotal(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/sessions-all`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getSessionsPerDay(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/sessions-day`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getSessionsDuration(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/sessions-duration`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getTouchesOnFiches(projectId, installations, start, end, limit) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-fiche`,
            data: { start, end, installations, limit },
        });
        return response.data.data;
    },

    async getTouchesOnMenus(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/touches-menu`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getLastContact(projectId, installations) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/last-contact`,
            data: { installations },
        });
        return response.data.data;
    },

    async getSatisfaction(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/satisfaction`,
            data: { start, end, installations },
        });
        return response.data.data;
    },

    async getSatisfactionPerDay(projectId, installations, start, end) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/stats/satisfaction-day`,
            data: { start, end, installations },
        });
        return response.data.data;
    },
};
