import HTTP from "./http-common";

export default {
    async getApplicationTypes() {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applications/types`,
        });

        return response.data.data;
    },

    async createApplication(name, description, type, template) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/applications`,
            data: { name, description, type, template },
        });
        return response.data.data;
    },

    async getApplication(applicationId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/application/${applicationId}`,
        });
        return response.data.data;
    },

    async getApplications() {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applications`,
            params: { sortby: "createdAt" },
        });
        return response.data.data;
    },

    async getLastApplications(n) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applications`,
            params: { sortby: "-createdAt", limit: n },
        });
        return response.data.data;
    },

    async deleteApplication(applicationId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/application/${applicationId}`,
        });
        return response.data.data;
    },

    async updateApplication(applicationId, name, type, description, template) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/application/${applicationId}`,
            data: { name, description, type, template },
        });
        return response.data.data;
    },

    async createApplicationBuild(
        applicationId,
        version,
        notes,
        config,
        previousId,
        templateConfigId,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/application/${applicationId}/applicationbuilds`,
            data: { version, notes, config, previousId, templateConfigId },
        });
        return response.data.data;
    },

    async getApplicationBuilds(applicationId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/application/${applicationId}/applicationbuilds`,
            params: { sortby: "-createdAt" },
        });
        return response.data.data;
    },

    async getApplicationBuild(applicationBuildId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applicationbuild/${applicationBuildId}`,
        });
        return response.data.data;
    },

    async updateApplicationBuild(
        applicationBuildId,
        version,
        notes,
        config,
        templateConfigId,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/applicationbuild/${applicationBuildId}`,
            data: { version, notes, config, templateConfigId },
        });
        return response.data.data;
    },

    async deleteApplicationBuild(applicationBuildId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/applicationbuild/${applicationBuildId}`,
        });
        return response.data.data;
    },

    // -------------------------------------------------------- AppBuild Configs

    async countFSPApplicationBuildConfigs(buildId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/applicationbuild/${buildId}/applicationconfigs`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPApplicationBuildConfigs(
        buildId,
        filter,
        sortby,
        limit,
        offset,
    ) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applicationbuild/${buildId}/applicationconfigs`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    // ------------------------------
    async getAllApplicationFiltred(filter) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/applications`,
            params: {
                filter: `type substring ${filter}`,
            },
        });
        return response.data.data;
    },

    async createApplicationConfig(projectId, buildId, config, style, name) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfigs`,
            data: { name, buildId, config, style },
        });
        return response.data.data;
    },

    async getApplicationConfigs(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfigs`,
        });
        return response.data.data;
    },

    async getApplicationConfig(projectId, configId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}`,
        });
        return response.data.data;
    },

    async getApplicationConfigBuildConfig(projectId, configId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}/buildconfig`,
        });
        return response.data.data;
    },

    async updateApplicationConfig(
        projectId,
        configId,
        buildId,
        config,
        style,
        name,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/applicationconfig/${configId}`,
            data: { name, buildId, config, style },
        });
        return response.data.data;
    },

    async deleteApplicationConfig(projectId, configId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/applicationconfig/${configId}`,
        });
        return response.data.data;
    },

    async getApplicationConfigArbos(projectId, configId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}/arbos`,
        });
        return response.data.data;
    },

    async associateArboAppConfig(projectId, configurationId, arboId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfig/${configurationId}/arbos`,
            data: { arboId },
        });
        return response.data.data;
    },

    async updateArboAppConfig(projectId, acaId, currentArboSnapshotId) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}`,
            data: { currentArboSnapshotId },
        });
        return response.data.data;
    },

    async removeAssociationArbo(projectId, configurationId, arboId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/applicationconfig/${configurationId}/arbo/${arboId}`,
        });
        return response.data.data;
    },

    async getMenusAssociable(projectId, configId, arboId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}/menus`,
            params: { arboId },
        });
        return response.data.data;
    },

    async getMenuStyle(projectId, configId, menuId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}/menu/${menuId}`,
        });
        return response.data.data;
    },

    async createMenuStyle(projectId, configId, menuId, style) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfig/${configId}/menus`,
            data: { menuId, style },
        });
        return response.data.data;
    },

    async updateMenuStyle(projectId, configId, menuId, style) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/applicationconfig/${configId}/menu/${menuId}`,
            data: { style },
        });
        return response.data.data;
    },

    async deleteMenuStyle(projectId, configId, menuId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/applicationconfig/${configId}/menu/${menuId}`,
        });
        return response.data.data;
    },

    // ------------------------------------------- InstallationApplicationConfig

    async getInstallations(projectId, configId, teamviewer) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/applicationconfig/${configId}/installations`,
            params: {
                teamviewer,
            },
        });
        return response.data.data;
    },

    async addInstallation(projectId, configId, installationId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfig/${configId}/installations`,
            data: { installationId },
        });
        return response.data.data;
    },

    async removeInstallation(projectId, configId, installationId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/applicationconfig/${configId}/installation/${installationId}`,
        });
        return response.data.data;
    },

    // ---------------------------------------------------------- TemplateConfig

    async getTemplateConfigs(applicationId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/application/${applicationId}/templateconfigs`,
        });
        return response.data.data;
    },

    async createTemplateConfig(applicationId, version, description, config) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/application/${applicationId}/templateconfigs`,
            data: { version, description, config },
        });
        return response.data.data;
    },

    async getTemplateConfig(templateConfigId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/templateconfig/${templateConfigId}`,
        });
        return response.data.data;
    },

    async updateTemplateConfig(templateConfigId, version, description, config) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/templateconfig/${templateConfigId}`,
            data: { version, description, config },
        });
        return response.data.data;
    },

    async deleteTemplateConfig(templateConfigId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/templateconfig/${templateConfigId}`,
        });
        return response.data.data;
    },

    // ------------------------------------------- ApplicationConfigArbo CronJob

    async createACACronJob(projectId, acaId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}/cronjob`,
        });
        return response.data.data;
    },

    async updateACACronJob(projectId, acaId, time, enabled) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}/cronjob`,
            data: { time, enabled },
        });
        return response.data.data;
    },

    async deleteACACronJob(projectId, acaId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/applicationconfigarbo/${acaId}/cronjob`,
        });
        return response.data.data;
    },
};
