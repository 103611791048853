<template>
    <div class="w-100">
        <div class="header">
            <div>JSON</div>
            <div class="text-warning" v-if="hasFocus">
                <b-icon-pencil-fill /> Edition en cours
            </div>
            <div class="text-kalkin-2" v-else-if="isJSONValid">
                <b-icon-check-circle-fill /> JSON valide
            </div>
            <div
                class="text-danger"
                v-else
                v-b-popover.hover.topleft.v-danger="error"
            >
                <b-icon-exclamation-circle-fill /> JSON
                <span style="text-decoration: underline dotted;">
                    non valide
                </span>
            </div>
        </div>
        <b-form-textarea
            class="textarea"
            :class="{ error: error && !hasFocus }"
            v-model="jsonDisplayed"
            :rows="rows"
            :max-rows="maxRows"
            :placeholder="placeholder"
            :readonly="readonly"
            spellcheck="false"
            @focus="hasFocus = true"
            @blur="onBlur"
        ></b-form-textarea>
    </div>
</template>

<script>
export default {
    props: {
        value: String,
        placeholder: { type: String, default: "Données au format JSON" },
        readonly: { type: Boolean, default: false },
        rows: { type: Number, default: 3 },
        maxRows: { type: Number, default: 8 },
    },

    data: function() {
        return {
            isJSONValid: true,
            hasFocus: false,
            hasChanged: false,
            json: this.formatJSON(this.value),
            error: null,
        };
    },

    computed: {
        jsonDisplayed: {
            get() {
                if (this.json === "null") {
                    return "";
                }
                return this.json;
            },
            set(value) {
                const old = this.json;
                if (value === "") {
                    this.json = "null";
                } else {
                    this.json = value;
                }
                this.hasChanged = old !== this.json;
            },
        },
    },

    watch: {
        value() {
            this.json = this.formatJSON(this.value);
        },
    },

    methods: {
        formatJSON(text) {
            try {
                const json = JSON.stringify(JSON.parse(text), null, 2);
                this.isJSONValid = true;
                this.error = null;
                return json;
            } catch (error) {
                this.isJSONValid = false;
                this.error = error.message;
                return text;
            }
        },

        onBlur() {
            this.json = this.formatJSON(this.json);
            this.hasFocus = false;
            if (this.hasChanged) {
                this.$emit("validate", this.isJSONValid);
                this.$emit("input", this.json);
            }
            this.hasChanged = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.textarea {
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 0 0 0 6px !important;

    font-family: monospace;
    font-size: 10px;
    transition: background 0.4s;
}
.textarea:focus {
    box-shadow: none;
    background: #eee;
}
.textarea.error {
    background: #fdd;
}

.header {
    display: flex;
    justify-content: space-between;

    padding-right: 10px;

    font-size: 10px;
}

.header > :first-child {
    padding: 0 10px;
    border-radius: 6px 6px 0 0;
    background-color: #bbb;

    color: white;
}
</style>
