<template>
    <div id="container">
        <div id="image"></div>
        <div id="content">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    components: {},
};
</script>
<style scoped>
#container {
    height: 100vh;
}
#container > div {
    height: 100%;
}
#image {
    width: 500px;
    background-image: url("../assets/connexion.png");
    background-size: cover;
    background-position: center;
    position: fixed;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
}
#content {
    width: calc(100% - 500px);
    margin-left: 500px;
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#content div {
    flex: 1 1 auto;
}

@media screen and (max-width: 1000px) {
    #content {
        width: calc(100% - 20%);
        margin-left: 20%;
    }
    #image {
        width: 20%;
    }
}

@media screen and (max-width: 600px) {
    #content {
        width: 100%;
        margin-left: 0;
    }
    #image {
        width: 0;
    }
}
</style>
