import HTTP from "./http-common";

export default {
    async createTranslation(projectId, arboId, langId) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/arbo/${arboId}/langs`,
            data: { langId },
        });
        return response.data.data;
    },

    async deleteTranslation(projectId, arboId, langId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/arbo/${arboId}/lang/${langId}`,
        });
        return response.data.data;
    },

    async getMenu(projectId, menuId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/menu/${menuId}`,
        });
        return response.data.data;
    },

    async getMenus(projectId, arboId, includeData = false) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/arbo/${arboId}/menus?includeData=${includeData}`,
        });
        return response.data.data;
    },

    async updateMenu(
        projectId,
        menuId,
        name,
        notes,
        config,
        sort,
        isVisible,
        templateId,
    ) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/menu/${menuId}`,
            data: { name, notes, config, sort, isVisible, templateId },
        });
        return response.data.data;
    },

    async moveMenu(projectId, menuId, order, parentId) {
        const response = await HTTP({
            method: "PUT",
            url: `project/${projectId}/menu/${menuId}`,
            data: { order, parentId },
        });
        return response.data.data;
    },

    async addRootMenu(
        projectId,
        arboId,
        name,
        notes,
        config,
        sort,
        isVisible,
        templateId,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/arbo/${arboId}/menus`,
            data: { name, notes, config, sort, isVisible, templateId },
        });
        return response.data.data;
    },

    async addChildMenu(
        projectId,
        menuId,
        name,
        notes,
        config,
        sort,
        isVisible,
        templateId,
    ) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/menu/${menuId}/menus`,
            data: { name, notes, config, sort, isVisible, templateId },
        });
        return response.data.data;
    },

    async deleteMenu(projectId, menuId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/menu/${menuId}`,
        });
        return response.data.data;
    },

    async getMenuTranslations(projectId, menuId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/menu/${menuId}/langs`,
        });
        return response.data.data;
    },

    async createMenuTranslation(projectId, menuId, langId, name) {
        const response = await HTTP({
            method: "POST",
            url: `/project/${projectId}/menu/${menuId}/langs`,
            data: { langId, name },
        });
        return response.data.data;
    },

    async updateMenuTranslation(projectId, menuId, langId, name) {
        const response = await HTTP({
            method: "PUT",
            url: `/project/${projectId}/menu/${menuId}/lang/${langId}`,
            data: { name },
        });
        return response.data.data;
    },

    async deleteMenuTranslation(projectId, menuId, langId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/project/${projectId}/menu/${menuId}/lang/${langId}`,
        });
        return response.data.data;
    },

    async getApplicationConfigs(projectId, arboId) {
        const response = await HTTP({
            method: "GET",
            url: `/project/${projectId}/arbo/${arboId}/applicationconfigs`,
        });
        return response.data.data;
    },
};
