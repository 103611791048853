<template>
    <JSONTextArea v-model="jsonAsString" @validate="isJSONValid = $event" />
</template>

<script>
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import { abstractField } from "vue-form-generator";

export default {
    components: {
        JSONTextArea,
    },

    data: function() {
        return {
            isJSONValid: true,
        };
    },

    computed: {
        jsonAsString: {
            get() {
                return JSON.stringify(this.value);
            },
            set(value) {
                this.value = JSON.parse(value);
            },
        },
    },

    methods: {
        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (this.value && !this.isJSONValid) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Le format JSON est invalide!",
                );
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>
