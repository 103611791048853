import arbosnapshotService from "../../services/arbosnapshot";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async createArboSnapshot(context, { projectId, acaId, description }) {
            try {
                await arbosnapshotService.createArboSnapshot(
                    projectId,
                    acaId,
                    description,
                );
                notifier.success(`L'instantané a bien été créé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getArboSnapshots(context, { projectId, acaId }) {
            try {
                return await arbosnapshotService.getArboSnapshots(
                    projectId,
                    acaId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateArboSnapshot(context, { projectId, id, description }) {
            try {
                await arbosnapshotService.updateArboSnapshot(
                    projectId,
                    id,
                    description,
                );
                notifier.success(`L'instantané a bien été modifié`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async buildArboSnapshot(context, { projectId, id }) {
            try {
                await arbosnapshotService.updateArboSnapshot(
                    projectId,
                    id,
                    undefined,
                    "build",
                );
                notifier.success(`L'instantané a bien été initialisé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async zipArboSnapshot(context, { projectId, id }) {
            try {
                await arbosnapshotService.updateArboSnapshot(
                    projectId,
                    id,
                    undefined,
                    "zip",
                );
                notifier.success(`L'instantané a bien été archivé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async unzipArboSnapshot(context, { projectId, id }) {
            try {
                return await arbosnapshotService.updateArboSnapshot(
                    projectId,
                    id,
                    undefined,
                    "unzip",
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteArboSnapshot(context, { projectId, id }) {
            try {
                return await arbosnapshotService.deleteArboSnapshot(
                    projectId,
                    id,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getArboSnapshot(context, { projectId, id }) {
            try {
                return await arbosnapshotService.getArboSnapshot(projectId, id);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createArboBuild(context, { projectId, builderId }) {
            try {
                await arbosnapshotService.createArboBuild(projectId, builderId);
                notifier.success(
                    `La demande de génération a bien été prise en compte`,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
