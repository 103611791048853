const translations = {
    fieldIsRequired: "Ce champ est obligatoire!",
    invalidFormat: "Format invalide!",

    numberTooSmall: "Ce nombre est trop petit! Minimum: {0}",
    numberTooBig: "Ce nombre est trop grand! Maximum: {0}",
    invalidNumber: "Nombre invalide",
    invalidInteger: "La valeur n'est pas un entier",

    textTooSmall: "Ce texte est trop court! Actuel: {0}, Minimum: {1}",
    textTooBig: "Ce texte est trop long! Actuel: {0}, Maximum: {1}",
    thisNotText: "Ce n'est pas du texte!",

    thisNotArray: "Ce n'est pas un tableau!",

    selectMinItems: "Sélectionner au moins {0} éléments!",
    selectMaxItems: "Sélectionner au plus {0} éléments!",

    invalidDate: "Invalid date!",
    dateIsEarly: "The date is too early! Current: {0}, Minimum: {1}",
    dateIsLate: "The date is too late! Current: {0}, Maximum: {1}",

    invalidEmail: "Adresse email invalide!",
    invalidURL: "URL invalide!",

    invalidCard: "Invalid card format!",
    invalidCardNumber: "Invalid card number!",

    invalidTextContainNumber:
        "Invalid text! Cannot contains numbers or special characters",
    invalidTextContainSpec: "Invalid text! Cannot contains special characters",
};

export default {
    translate(VueFormGenerator) {
        Object.assign(VueFormGenerator.validators.resources, {
            ...VueFormGenerator.validators.resources,
            ...translations,
        });
    },
};
