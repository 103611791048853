import HTTP from "./http-common";

export default {
    async login(mail, password) {
        const response = await HTTP({
            method: "POST",
            url: `/login`,
            data: { mail, password },
        });
        return response.data.data;
    },
};
