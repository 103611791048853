vue
<template>
    <canvas :id="canvasId" />
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
    props: {
        canvasId: { type: String, required: true },
        data: { type: Object },
        total: { type: Number },
        title: { type: String },
    },

    data: function() {
        return {
            chart: null,
            chartOptions: {
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: !!this.title,
                        text: this.title,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    datalabels: {
                        color: "#92bb3f",
                        anchor: "end",
                        align: "end",
                        font: {
                            size: 12,
                            style: "italic",
                        },
                        formatter: function(value) {
                            if (value > 1) {
                                return `${value} évaluations`;
                            } else if (value == 1) {
                                return `${value} évaluation`;
                            } else {
                                return "-";
                            }
                        },
                    },
                },
                scales: {
                    xAxes: {
                        display: false,
                    },
                    yAxes: {
                        display: true,
                    },
                },
                layout: {
                    padding: {
                        right: 25,
                    },
                },
            },
        };
    },

    watch: {
        data() {
            this.updateChartData();
        },
    },

    methods: {
        updateChartData() {
            this.chart.data = {
                labels: Object.keys(this.data).map(k => `${k}★`),
                datasets: [
                    {
                        backgroundColor: "#00817b",
                        data: Object.values(this.data),
                    },
                ],
            };
            this.chart.options.scales.xAxes.max = Math.max(this.total * 1.2, 1);
            this.chart.update();
        },
    },

    mounted() {
        this.chart = new Chart(document.getElementById(this.canvasId), {
            type: "bar",
            options: this.chartOptions,
            data: {},
            plugins: [ChartDataLabels],
        });
        if (this.data) {
            this.updateChartData();
        }
    },
};
</script>
