import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

Vue.use(VueRouter);

// Handle navigation errors (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(error => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw error;
        }
    });
};

const routes = [
    // #########################################################################
    // ########################################################## Routes racines
    // #########################################################################
    {
        path: "/",
        name: "Home",
        component: function() {
            return import("../views/main/Home.vue");
        },
        meta: {
            title: "Accueil",
            layout: "no-breadcrumb",
            member: true,
        },
    },
    {
        path: "/admin",
        name: "Admin",
        component: function() {
            return import("../views/main/admin/Admin.vue");
        },
        meta: {
            title: "Administration",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/cronjobs",
        name: "CronJobs",
        component: function() {
            return import("../views/main/admin/cronjobs/CronJobs.vue");
        },
        meta: {
            title: "Tâches programmées",
            layout: "no-breadcrumb",
            admin: true,
        },
    },
    // #########################################################################
    // ######################################################## Admin MEDIAS
    // #########################################################################
    {
        path: "/medias",
        name: "Medias",
        component: function() {
            return import("../views/main/media/Collections.vue");
        },
        meta: {
            title: "Médias",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/medias/collections/create",
        name: "CreateMediaCollection",
        component: function() {
            return import("../views/main/media/CreateCollection.vue");
        },
        meta: {
            title: "Créer une collection de médias",
            parent: "Medias",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/medias/collection/:collectionId",
        name: "MediaCollection",
        component: function() {
            return import("../views/main/media/Collection.vue");
        },
        meta: {
            title: "Collection de médias",
            parent: "Medias",
            kalkin: true,
            admin: true,
        },
    },
    // #########################################################################
    // ######################################################## Admin KBSIT
    // #########################################################################
    {
        path: "/kbsit",
        name: "KBSIT",
        component: function() {
            return import("../views/main/kbsit/KalkinBasicSIT.vue");
        },
        meta: {
            title: "Administration SIT Kalkin",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/kbsit/:sit/projects",
        name: "KBSITProjects",
        component: function() {
            return import("../views/main/kbsit/Projects.vue");
        },
        meta: {
            title: "Liste des projets SIT",
            parent: "KBSIT",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/kbsit/:sit/project/:projectId",
        name: "KBSITProject",
        component: function() {
            return import("../views/main/kbsit/Project.vue");
        },
        meta: {
            title: "Projet SIT",
            parent: "KBSITProjects",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    // #########################################################################
    // ######################################################## Routes monopages
    // #########################################################################
    {
        path: "/welcome",
        name: "LandingPage",
        component: function() {
            return import("../views/LandingPage.vue");
        },
        meta: {
            title: "Bienvenue",
            layout: "light",
            guest: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/main/Login.vue"),
        meta: {
            title: "Connexion",
            layout: "light",
            guest: true,
        },
    },
    {
        path: "/user/updatepassword",
        name: "UpdatePassword",
        component: function() {
            return import("../views/main/UpdatePassword.vue");
        },
        meta: {
            title: "Modifier mon mot de passe",
            layout: "light",
            new: true,
        },
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        component: function() {
            return import("../views/main/ResetPassword.vue");
        },
        meta: {
            title: "Mot de passe oublié",
            layout: "light",
            all: true,
        },
    },
    {
        path: "/resetpassword/:token",
        component: function() {
            return import("../views/main/ResetPasswordToken.vue");
        },
        meta: {
            title: "Réinitialiser mot de passe",
            layout: "light",
            all: true,
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: function() {
            return import("../views/main/Contact.vue");
        },
        meta: {
            title: "Contact",
            layout: "light",
            all: true,
        },
    },
    {
        path: "/legalnotice",
        name: "LegalNotice",
        component: function() {
            return import("../views/main/LegalNotice.vue");
        },
        meta: {
            title: "Mentions légales",
            layout: "light",
            all: true,
        },
    },
    // #########################################################################
    // #################################################### Routes partie membre
    // #########################################################################

    // ------------------------------------ Routes globales --------------------

    {
        path: "/profile",
        name: "Profile",
        component: function() {
            return import("../views/main/Profile.vue");
        },
        meta: {
            title: "Mon profil",
            parent: "Home",
            layout: "no-breadcrumb",
            member: true,
        },
    },
    {
        path: "/projects",
        name: "Projects",
        component: function() {
            return import("../views/main/member/Projects.vue");
        },
        meta: {
            title: "Mes projets",
            parent: "Home",
            layout: "no-breadcrumb",
            member: true,
        },
    },

    // ------------------------------------ Routes projet ----------------------

    {
        path: "/project/:projectId",
        name: "Project",
        component: function() {
            return import("../views/main/member/Project.vue");
        },
        meta: {
            title: "Projet",
            parent: "Projects",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },

    // ------------------------------------ Routes projet + config -----------

    {
        path: "/project/:projectId/config/:configId",
        name: "Config",
        component: function() {
            return import("../views/main/member/Config.vue");
        },
        meta: {
            title: "Configuration",
            parent: "Project",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },
    {
        path: "/project/:projectId/config/:configId/installations",
        name: "ProjectInstallations",
        component: function() {
            return import("../views/main/member/config/Installations.vue");
        },
        meta: {
            title: "Mes installations",
            parent: "Config",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },
    {
        path: "/project/:projectId/config/:configId/modules",
        name: "ProjectListModules",
        component: function() {
            return import("../views/main/member/config/ListModules.vue");
        },
        meta: {
            title: "Liste des modules",
            parent: "Config",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },
    {
        path: "/project/:projectId/config/:configId/module/:moduleId",
        name: "ProjectModule",
        component: function() {
            return import("../views/main/member/config/Module.vue");
        },
        meta: {
            title: "Détails du module",
            parent: "ProjectListModules",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },
    {
        path: "/project/:projectId/config/:configId/stats",
        name: "Stats",
        component: function() {
            return import("../views/main/member/config/Stats.vue");
        },
        meta: {
            title: "Statistiques",
            parent: "Config",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },
    {
        path: "/project/:projectId/config/:configId/stats/:installId",
        name: "StatsDetails",
        component: function() {
            return import("../views/main/member/config/StatsDetails.vue");
        },
        meta: {
            title: "Statistiques détaillées",
            parent: "Stats",
            layout: "no-breadcrumb",
            member: true,
            project: true,
        },
    },

    // #########################################################################
    // ##################################################### Routes partie admin
    // #########################################################################

    {
        path: "/admin/installations",
        name: "AdminMapInstallations",
        component: function() {
            return import("../views/main/admin/Installations.vue");
        },
        meta: {
            title: "Carte des installations",
            parent: "Admin",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/duplication",
        name: "AdminCloningTool",
        component: function() {
            return import("../views/main/admin/CloningTool.vue");
        },
        meta: {
            title: "Outils de duplication",
            parent: "Admin",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },

    // ---------------------------------------- Module USER --------------------
    {
        path: "/admin/users",
        name: "AdminListUsers",
        component: function() {
            return import("../views/main/admin/user/ListUsers.vue");
        },
        meta: {
            title: "Liste des utilisateurs",
            parent: "Admin",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/users/create",
        name: "AdminCreateUser",
        component: function() {
            return import("../views/main/admin/user/CreateUser.vue");
        },
        meta: {
            title: "Créer un utilisateur",
            parent: "AdminListUsers",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/users/edit/:userId",
        name: "AdminEditUser",
        component: function() {
            return import("../views/main/admin/user/EditUser.vue");
        },
        meta: {
            title: "Editer un utilisateur",
            parent: "AdminUser",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/user/:userId",
        name: "AdminUser",
        component: function() {
            return import("../views/main/admin/user/User.vue");
        },
        meta: {
            title: "Utilisateur",
            parent: "AdminListUsers",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    // ------------------------------------- Module CONTACT --------------------
    {
        path: "/admin/contacts",
        name: "AdminListContacts",
        component: function() {
            return import("../views/main/admin/contact/ListContacts.vue");
        },
        meta: {
            title: "Liste des contacts",
            parent: "Admin",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/contacts/create",
        name: "AdminCreateContact",
        component: function() {
            return import("../views/main/admin/contact/CreateContact.vue");
        },
        meta: {
            title: "Créer un contact",
            parent: "AdminListContacts",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/contact/:contactId",
        name: "AdminContact",
        component: function() {
            return import("../views/main/admin/contact/Contact.vue");
        },
        meta: {
            title: "Contact",
            parent: "AdminListContacts",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    // ------------------------------------- Module PROJECT --------------------
    {
        path: "/admin/projects",
        name: "AdminListProjects",
        component: function() {
            return import("../views/main/admin/project/ListProjects.vue");
        },
        meta: {
            title: "Liste des projets",
            parent: "Admin",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/projects/create",
        name: "AdminCreateProject",
        component: function() {
            return import("../views/main/admin/project/CreateProject.vue");
        },
        meta: {
            title: "Créer un projet",
            parent: "AdminListProjects",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId",
        name: "AdminProject",
        component: function() {
            return import("../views/main/admin/project/Project.vue");
        },
        meta: {
            title: "Projet",
            parent: "AdminListProjects",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/arbos",
        name: "AdminProjectListArbos",
        component: function() {
            return import("../views/main/admin/project/arbo/ListArbos.vue");
        },
        meta: {
            title: "Liste des arborescences",
            parent: "AdminProject",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/installations",
        name: "AdminProjectListInstallations",
        component: function() {
            return import("../views/main/admin/project/ListInstallations.vue");
        },
        meta: {
            title: "Liste des installations",
            parent: "AdminProject",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/modules",
        name: "AdminProjectListModules",
        component: function() {
            return import("../views/main/admin/project/module/ListModules.vue");
        },
        meta: {
            title: "Liste des modules",
            parent: "AdminProject",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/module/:moduleId/config",
        name: "AdminProjectModuleConfig",
        component: function() {
            return import("../views/main/admin/project/module/Config.vue");
        },
        meta: {
            title: "Configuration du module",
            parent: "AdminProjectListModules",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/module/:moduleId",
        name: "AdminProjectModule",
        component: function() {
            return import("../views/main/admin/project/module/Module.vue");
        },
        meta: {
            title: "Détails du module",
            parent: "AdminProjectListModules",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },

    // --------------------------------- Module APPLICATION --------------------
    {
        path: "/admin/applications",
        name: "AdminListApplications",
        component: function() {
            return import(
                "../views/main/admin/application/ListApplications.vue"
            );
        },
        meta: {
            title: "Liste des applications",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/application/:applicationId",
        name: "AdminApplication",
        component: function() {
            return import("../views/main/admin/application/Application.vue");
        },
        meta: {
            title: "Application",
            parent: "AdminListApplications",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/application/:applicationId/build/:buildId",
        name: "AdminApplicationBuild",
        component: function() {
            return import(
                "../views/main/admin/application/ApplicationBuild.vue"
            );
        },
        meta: {
            title: "Build",
            parent: "AdminApplication",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/application/:applicationId/template/:templateId",
        name: "AdminTemplateConfig",
        component: function() {
            return import("../views/main/admin/application/TemplateConfig.vue");
        },
        meta: {
            title: "Configuration du template",
            parent: "AdminApplication",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },

    // --------------------------------- Module MODULE -------------------------
    {
        path: "/admin/modules",
        name: "AdminListModules",
        component: function() {
            return import("../views/main/admin/module/ListModules.vue");
        },
        meta: {
            title: "Liste des modules",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/module/:moduleId",
        name: "AdminModule",
        component: function() {
            return import("../views/main/admin/module/Module.vue");
        },
        meta: {
            title: "Configuration du module",
            parent: "AdminListModules",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },

    // ------------------------------- Module ARBO -----------------------------
    {
        path: "/admin/project/:projectId/arbo/:arboId",
        name: "AdminEditArboStructure",
        component: function() {
            return import("@/views/main/admin/project/arbo/EditStructure.vue");
        },
        meta: {
            title: "Gérer la structure de l'arborescence",
            parent: "AdminProjectListArbos",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/arbo/:arboId/data",
        name: "AdminEditArboData",
        component: function() {
            return import("@/views/main/admin/project/arbo/EditData.vue");
        },
        meta: {
            title: "Configurer les données de l'arborescence",
            parent: "AdminProjectListArbos",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/arbo/:arboId/style/:configId",
        name: "AdminEditArboStyle",
        component: function() {
            return import("@/views/main/admin/project/arbo/EditStyle.vue");
        },
        meta: {
            title: "Éditer le style de l'arborescence",
            parent: "AdminProjectListArbos",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    // ---------------------------------------- Module DATA --------------------
    {
        path: "/admin/project/:projectId/datasources",
        name: "AdminProjectListDatasources",
        component: function() {
            return import("../views/main/admin/project/ListDatasources.vue");
        },
        meta: {
            title: "Liste des sources de données",
            parent: "AdminProject",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    {
        path: "/admin/project/:projectId/datasource/:datasourceId",
        name: "AdminDataSource",
        component: function() {
            return import("../views/main/admin/project/Datasource");
        },
        meta: {
            title: "Editer la source de données",
            parent: "AdminProjectListDatasources",
            layout: "default",
            member: true,
        },
    },

    // ------------------------------- Module CONFIGURATION --------------------
    {
        path: "/admin/project/:projectId/config/:configId",
        name: "AdminApplicationConfig",
        component: function() {
            return import("../views/main/admin/project/ApplicationConfig.vue");
        },
        meta: {
            title: "Configuration de l'application",
            parent: "AdminProject",
            layout: "default",
            kalkin: true,
            admin: true,
        },
    },
    // ----------------------------- Module ARBOBUILD --------------------------
    {
        path: "/admin/arbobuilds",
        name: "AdminListArbobuilds",
        component: function() {
            return import("../views/main/admin/arbobuild/ListArbobuilds.vue");
        },
        meta: {
            title: "Historique de génération",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },

    // ----------------------------- Module SITLIST --------------------------
    {
        path: "/admin/ListSit",
        name: "AdminListSit",
        component: function() {
            return import("../views/main/admin/listsit/ListSIT.vue");
        },
        meta: {
            title: "Liste des source d'information",
            layout: "no-breadcrumb",
            kalkin: true,
            admin: true,
        },
    },

    // #########################################################################
    // ##################################################### Routes BETA
    // #########################################################################
    {
        path: "/project/:projectId/dispos",
        name: "Dispo",
        component: function() {
            return import("../views/main/project/dispo/DispoHome.vue");
        },
        meta: {
            title: "Disponibilités des hébergements",
            parent: "Project",
            layout: "default",
            kalkin: true,
            membre: true,
        },
    },
    {
        path: "/project/:projectId/dispos",
        name: "SeeDispo",
        component: function() {
            return import("../views/main/project/dispo/DispoView.vue");
        },
        meta: {
            title: "Disponibilités des hébergements",
            parent: "Project",
            layout: "default",
            kalkin: true,
            membre: true,
        },
    },
    // #########################################################################
    // ##################################################### AUTRES
    // #########################################################################
    {
        path: "/suspended",
        name: "SuspendedAccount",
        component: function() {
            return import("../views/errors/SuspendedAccount.vue");
        },
        meta: {
            layout: "light",
            suspended: true,
        },
    },
    {
        path: "/403",
        name: "Forbidden",
        component: function() {
            return import("../views/errors/Forbidden.vue");
        },
        meta: {
            layout: "light",
        },
    },
    {
        path: "/404",
        name: "NotFound",
        component: function() {
            return import("../views/errors/NotFound.vue");
        },
        meta: {
            layout: "light",
        },
    },
    {
        path: "*",
        redirect: { name: "NotFound" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    // On récupere l'utilisateur dans le store (persistant)
    const user = store.state.user.user;
    let needProjectCredentials = false;

    // On récupère le projet, et on le garde dans le store (appel API)
    if (to.matched.some(r => r.meta.project)) {
        await store.dispatch("project/loadProject", {
            id: to.params.projectId,
        });
        needProjectCredentials = true;
    }

    // On traite toutes les situations d'exclusion
    // --> Si la page est réservée aux invités
    if (to.matched.some(r => r.meta.guest) && user) {
        return next({ name: "Home" });
    }
    // --> Si la page est réservée aux utilisateurs bannis
    else if (to.matched.some(r => r.meta.suspended)) {
        if (!user) {
            return next({ name: "Login" });
        } else if (user.credentials >= 0) {
            return next({ name: "Home" });
        }
    }
    // --> Si la page est réservée aux nouveaux utilisateurs
    else if (to.matched.some(r => r.meta.new)) {
        if (!user) {
            return next({ name: "Login" });
        } else if (!user.mustSetPassword) {
            return next({ name: "Home" });
        }
    }
    // --> Si la page est réservée aux membres
    else if (to.matched.some(r => r.meta.member)) {
        if (!user) {
            return next({ name: "LandingPage" });
        } else if (user.mustSetPassword) {
            return next({ name: "UpdatePassword" });
        } else if (user.credentials < 1) {
            return next({ name: "Forbidden" });
        }

        // --> Si la page est liée à un projet spécifique
        if (needProjectCredentials && user.credentials < 5) {
            if (!store.getters["project/hasReaderCredentials"]) {
                return next({ name: "Forbidden" });
            }
        }
    }
    // --> Si la page est réservée à Kalkin
    else if (to.matched.some(r => r.meta.kalkin)) {
        if (!user) {
            return next({ name: "LandingPage" });
        } else if (user.mustSetPassword) {
            return next({ name: "UpdatePassword" });
        } else if (user.credentials < 5) {
            return next({ name: "Forbidden" });
        }
    }
    // --> Si la page est réservée aux admins
    else if (to.matched.some(r => r.meta.admin)) {
        if (!user) {
            return next({ name: "LandingPage" });
        } else if (user.mustSetPassword) {
            return next({ name: "UpdatePassword" });
        } else if (user.credentials < 10) {
            return next({ name: "Forbidden" });
        }
    }

    // Pas de rejet, ou si la page est accessible à tous
    next();
});

export default router;
