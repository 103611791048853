import HTTP from "./http-common";

export default {
    async getMe() {
        const response = await HTTP({
            method: "GET",
            url: `/user/me`,
        });
        return response.data.data;
    },

    async getMyProjectCreds(projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/user/myProjectCreds`,
            params: { projectId },
        });
        return response.data.data;
    },

    async createUser(mail, firstName, lastName, phone, credentials) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/users`,
            data: { mail, firstName, lastName, phone, credentials },
        });
        return response.data.data;
    },

    async resetPassword(mail) {
        const response = await HTTP({
            method: "POST",
            url: `/resetpassword`,
            data: { mail },
        });
        return response.data.data;
    },

    async resetPasswordValidate(mail, password, token) {
        const response = await HTTP({
            method: "PUT",
            url: `/resetpassword`,
            data: { mail, password, token },
        });
        return response.data.data;
    },

    async getAllUsers() {
        const response = await HTTP({
            method: "GET",
            url: "/admin/users",
        });
        return response.data.data;
    },

    async countFSPUsers(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: "/admin/users",
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPUsers(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/users",
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    // TODO remove
    async getAllFiltered(filterValue) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/users",
            params: {
                filter: `firstName startsWith ${filterValue} or lastName startsWith ${filterValue}`,
            },
        });
        return response.data.data;
    },

    async deleteUser(id) {
        const response = await HTTP({
            method: "DELETE",
            url: `admin/user/${id}`,
        });
        return response.data.data;
    },

    async getUser(id) {
        const response = await HTTP({
            method: "GET",
            url: `admin/user/${id}`,
        });
        return response.data.data;
    },

    async updatePassword(oldPassword, password) {
        const response = await HTTP({
            method: "PUT",
            url: "user/me",
            data: { oldPassword, password },
        });
        return response.data.data;
    },

    async updateUser(id, firstName, lastName, phone, credentials) {
        const response = await HTTP({
            method: "PUT",
            url: `admin/user/${id}`,
            data: { firstName, lastName, phone, credentials },
        });
        return response.data.data;
    },

    async updateMe(oldPassword, password, firstName, lastName, phone) {
        const response = await HTTP({
            method: "PUT",
            url: "user/me",
            data: { oldPassword, password, firstName, lastName, phone },
        });
        return response.data.data;
    },

    async getUserProjects(userId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/user/${userId}/projects`,
        });
        return response.data.data;
    },

    async countFSPUserProjects(userId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/user/${userId}/projects`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPUserProjects(userId, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/user/${userId}/projects`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getLastUsers(n) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/users`,
            params: {
                sortby: "-createdAt",
                limit: n,
            },
        });
        return response.data.data;
    },
};
