const _ = require("lodash");
// Formats :
//     - CORE: description de format de l'API CORE
//     - FORM: description de format du composant GenericForm (vue-form-generator)

export default {
    convertCOREtoFORM(input = []) {
        let fields = [];
        let groups = {};
        for (const item of input) {
            let field = {
                id: item.id,
                model: item.id,
                label: item.name,
                placeholder: item.name,
                required: !!item.required,
                hint: item.description,
                dependsOn: item.dependsOn,
            };

            // Compatibilité legacy
            if (item.defaultValue !== undefined) {
                field.default = item.defaultValue;
            }

            if (item.default != "") {
                field.default = item.default;
            }

            switch (item.type) {
                case "string":
                    field = {
                        ...field,
                        type: "input",
                        inputType: "text",
                        maxlength: 255,
                        validator: "string",
                    };
                    break;
                case "string.readonly":
                    field = {
                        ...field,
                        type: "label",
                    };
                    break;
                case "text":
                    field = {
                        ...field,
                        type: "textArea",
                        rows: 3,
                        validator: "string",
                    };
                    break;
                case "boolean":
                    field = {
                        ...field,
                        type: "switch",
                        textOn: "Oui",
                        textOff: "Non",
                    };
                    break;
                case "integer":
                    field = {
                        ...field,
                        type: "input",
                        inputType: "number",
                        validator: "integer",
                    };
                    if (item.default) {
                        field.default = parseInt(item.default);
                    }
                    break;
                case "number":
                    field = {
                        ...field,
                        type: "input",
                        inputType: "number",
                        validator: "number",
                    };
                    if (item.default) {
                        field.default = parseFloat(item.default);
                    }
                    break;
                case "choice":
                    field = {
                        ...field,
                        type: "radios",
                        values: _.get(item, "config.items", []),
                        radiosOptions: {
                            value: "id",
                        },
                        validator: "required",
                    };
                    break;
                case "select.one":
                    field = {
                        ...field,
                        type: "select",
                        values: _.get(item, "config.items", []),
                        selectOptions: {
                            noneSelectedText: "-- Aucun élément sélectionné --",
                            value: "id",
                        },
                        validator: "required",
                    };
                    break;
                case "select":
                    field = {
                        ...field,
                        type: "vueMultiSelect",
                        selectOptions: {
                            multiple: true,
                            trackBy: "value",
                            key: "value",
                            label: "name",
                            hideSelected: true,
                            closeOnSelect: false,
                            searchable: false,
                            selectLabel: `Appuyez sur "Entrée" pour sélectionner`,
                        },
                        values: _.get(item, "config.items", []).map(i => {
                            return { name: i.name, value: i.id };
                        }),
                    };
                    break;
                case "emprise":
                    field = {
                        ...field,
                        type: "MapAreaSelector",
                    };
                    break;
                case "json":
                    field = {
                        ...field,
                        type: "JSONTextArea",
                    };
                    break;
                case "array.number":
                    field = {
                        ...field,
                        type: "ArrayNumberInput",
                        min: _.get(item, "config.min", 0),
                        max: _.get(item, "config.max", 255),
                    };
                    break;
                case "array.string":
                    field = {
                        ...field,
                        type: "ArrayTextInput",
                        min: _.get(item, "config.min", 0),
                        max: _.get(item, "config.max", 255),
                    };
                    break;
                case "array.form":
                    field = {
                        ...field,
                        type: "ArrayFormInput",
                        min: _.get(item, "config.min", 0),
                        max: _.get(item, "config.max", 10),
                        config: _.get(item, "config.form", []),
                        vertical: _.get(item, "config.vertical", false),
                    };
                    break;
                case "form":
                    field = {
                        ...field,
                        type: "FormInput",
                        config: _.get(item, "config.form", []),
                        vertical: _.get(item, "config.vertical", false),
                    };
                    break;
                case "vector2":
                    field = {
                        ...field,
                        type: "VectorInput",
                        dimension: 2,
                    };
                    break;
                case "vector3":
                    field = {
                        ...field,
                        type: "VectorInput",
                        dimension: 3,
                    };
                    break;
                case "vector4":
                    field = {
                        ...field,
                        type: "VectorInput",
                        dimension: 4,
                    };
                    break;
                case "color":
                    field = {
                        ...field,
                        type: "ColorInput",
                    };
                    break;
                case "url":
                    field = {
                        ...field,
                        type: "URLInput",
                        target: _.get(item, "config.target", "project"),
                        collection: _.get(item, "config.collection"),
                        accept: _.get(item, "config.accept"),
                    };
                    break;
                case "array.url":
                    field = {
                        ...field,
                        type: "ArrayURLInput",
                        min: _.get(item, "config.min", 0),
                        max: _.get(item, "config.max", 10),
                        target: _.get(item, "config.target", "project"),
                        collection: _.get(item, "config.collection"),
                        accept: _.get(item, "config.accept"),
                    };
                    break;
                case "icon":
                    field = {
                        ...field,
                        type: "IconPicker",
                    };
                    break;
                case "module.media.collection":
                    field = {
                        default: null,
                        ...field,
                        type: "MediaCollectionSelect",
                    };
                    break;
                case "module.media.list":
                    field = {
                        default: null,
                        ...field,
                        type: "MediaPicker",
                    };
                    break;
                default:
                    continue;
            }

            if (item.group) {
                if (!groups[item.group]) {
                    groups[item.group] = {
                        legend: item.group,
                        fields: [],
                    };
                }
                groups[item.group].fields.push(field);
            } else {
                fields.push(field);
            }
        }
        return { fields, groups: Object.values(groups) };
    },

    createDefaultFORMObject(schema, obj = {}) {
        const _parseField = field => {
            if (
                _.get(obj, field.model) === undefined &&
                field.default !== undefined
            ) {
                if (_.isFunction(field.default)) {
                    _.set(obj, field.model, field.default(field, schema, obj));
                } else if (
                    _.isObject(field.default) ||
                    _.isArray(field.default)
                ) {
                    _.set(obj, field.model, _.cloneDeep(field.default));
                } else _.set(obj, field.model, field.default);
            }
        };

        // Pour les champs
        _.each(schema.fields, _parseField);

        // Pour les groupes
        _.each(schema.groups, group => _.each(group.fields, _parseField));

        return obj;
    },
};
