import mediaService from "../../services/media";
import APIError from "../../tools/APIError";

export default {
    namespaced: true,

    actions: {
        async getMedias(context, { collectionId, collectionName }) {
            try {
                return await mediaService.getMedias(
                    collectionId,
                    collectionName,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createCollection(context, { name, type, description, mime }) {
            try {
                await mediaService.createCollection(
                    name,
                    type,
                    description,
                    mime,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPCollections(context, { filter }) {
            try {
                return await mediaService.countFSPCollections(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPCollections(context, { filter, sortby, limit, offset }) {
            try {
                return await mediaService.getFSPCollections(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getCollection(context, { collectionId }) {
            try {
                return await mediaService.getCollection(collectionId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateCollection(context, { id, name, type, description, mime }) {
            try {
                await mediaService.updateCollection(
                    id,
                    name,
                    type,
                    description,
                    mime,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPMedias(context, { collectionId, filter }) {
            try {
                return await mediaService.countFSPMedias(collectionId, filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPMedias(
            context,
            { collectionId, filter, sortby, limit, offset },
        ) {
            try {
                return await mediaService.getFSPMedias(
                    collectionId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createMedia(context, { collectionId, file, name, metadata }) {
            try {
                return await mediaService.createMedia(
                    collectionId,
                    file,
                    name,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMedia(context, { collectionId, mediaId, file, metadata }) {
            try {
                return await mediaService.updateMedia(
                    collectionId,
                    mediaId,
                    file,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteCollection(context, { collectionId }) {
            try {
                await mediaService.deleteCollection(collectionId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMedia(context, { collectionId, mediaId }) {
            try {
                await mediaService.deleteMedia(collectionId, mediaId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMedia(context, { collectionId, mediaId }) {
            try {
                return await mediaService.getMedia(collectionId, mediaId);
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
