import HTTP from "./http-common";

export default {
    async getFilters() {
        const response = await HTTP({
            method: "GET",
            url: `/config/filters`,
        });
        return response.data.data;
    },

    async getModuleTypes() {
        const response = await HTTP({
            method: "GET",
            url: `/config/moduletypes`,
        });
        return response.data.data;
    },
};
