<template>
    <div class="w-100">
        <b-button
            class="btn-add"
            @click="addRow"
            :disabled="this.localModel.length >= this.max"
        >
            <b-icon-plus-circle /> Ajouter un element au tableau
        </b-button>
        <template v-for="(item, index) in localModel">
            <div class="k-row" :key="index">
                <slot
                    :item="item"
                    :index="index"
                    :onInput="
                        v => {
                            localModel[index] = v;
                            save();
                        }
                    "
                    :onValidate="
                        v => {
                            isContentValid[index] = v;
                            validateContent();
                        }
                    "
                />
                <b-button class="btn-delete" @click="deleteRow(index)">
                    <b-icon-trash />
                </b-button>
            </div>
        </template>
        <div class="m-2" v-show="localModel.length === 0">
            <small><i>Tableau vide</i></small>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        default: { required: true },
        value: { required: true },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
    },

    data: function() {
        return {
            localModel: [...(this.value || [])], // clone
            isContentValid: [],
        };
    },

    computed: {
        isValid() {
            return (
                this.localModel.length >= this.min &&
                this.localModel.length <= this.max
            );
        },
    },

    watch: {
        value() {
            this.localModel = [...(this.value || [])];
        },
    },

    methods: {
        addRow() {
            this.localModel.push(this.default);
            this.save();
        },

        deleteRow(idx) {
            this.localModel.splice(idx, 1);
            this.isContentValid.splice(idx, 1);
            this.save();
            this.validateContent();
        },

        save() {
            this.$emit("validate", this.isValid);
            this.$emit("input", this.localModel);
        },

        validateContent() {
            this.$emit(
                "validate-content",
                this.isContentValid.every(i => i),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.k-row {
    display: flex !important;
    margin: 2px 0;
}

.btn-delete {
    font-size: 12px !important;
    width: 30px !important;
    height: 25px !important;
    padding: 0 !important;
    margin: auto 5px !important;
    color: #c00000 !important;
    border-color: #c00000 !important;
}
.btn-delete:hover {
    color: white !important;
    background: #c00000 !important;
}

.btn-add {
    font-size: 12px !important;
    padding: 3px 10px !important;
    color: $secondary-color !important;
    border-color: $secondary-color !important;
}
.btn-add:hover {
    color: white !important;
    background: $secondary-color !important;
}
</style>
