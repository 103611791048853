import arboService from "../../services/arbo";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        // ---------------------------------------------------------------- ARBO

        async createTranslation(context, { projectId, arboId, langId }) {
            try {
                return await arboService.createTranslation(
                    projectId,
                    arboId,
                    langId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteTranslation(context, { projectId, arboId, langId }) {
            try {
                return await arboService.deleteTranslation(
                    projectId,
                    arboId,
                    langId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ---------------------------------------------------------------- MENU

        async getMenu(context, { projectId, arboId }) {
            try {
                return await arboService.getMenu(projectId, arboId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMenus(context, { projectId, arboId, includeData }) {
            try {
                return await arboService.getMenus(
                    projectId,
                    arboId,
                    includeData,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async addRootMenu(context, payload) {
            const { projectId, arboId, templateId } = payload;
            const { name, notes, config, sort, isVisible } = payload;
            try {
                await arboService.addRootMenu(
                    projectId,
                    arboId,
                    name,
                    notes,
                    config,
                    sort,
                    isVisible,
                    templateId,
                );
                notifier.success(`Nouveau menu ajouté`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async addChildMenu(context, payload) {
            const { projectId, menuId, templateId } = payload;
            const { name, notes, config, sort, isVisible } = payload;
            try {
                await arboService.addChildMenu(
                    projectId,
                    menuId,
                    name,
                    notes,
                    config,
                    sort,
                    isVisible,
                    templateId,
                );
                notifier.success(`Nouveau menu ajouté`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMenu(context, payload) {
            const { projectId, menuId, templateId } = payload;
            const { name, notes, config, sort, isVisible } = payload;
            try {
                await arboService.updateMenu(
                    projectId,
                    menuId,
                    name,
                    notes,
                    config,
                    sort,
                    isVisible,
                    templateId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async moveMenu(context, { projectId, menuId, order, parentId }) {
            try {
                await arboService.moveMenu(projectId, menuId, order, parentId);
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMenu(context, { projectId, menuId }) {
            try {
                await arboService.deleteMenu(projectId, menuId);
                notifier.success(`Menu supprimé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMenuTranslations(context, { projectId, menuId }) {
            try {
                return await arboService.getMenuTranslations(projectId, menuId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createMenuTranslation(
            context,
            { projectId, menuId, langId, name },
        ) {
            try {
                await arboService.createMenuTranslation(
                    projectId,
                    menuId,
                    langId,
                    name,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMenuTranslation(
            context,
            { projectId, menuId, langId, name },
        ) {
            try {
                await arboService.updateMenuTranslation(
                    projectId,
                    menuId,
                    langId,
                    name,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMenuTranslation(context, { projectId, menuId, langId }) {
            try {
                await arboService.deleteMenuTranslation(
                    projectId,
                    menuId,
                    langId,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getApplicationConfigs(context, { projectId, arboId }) {
            try {
                return await arboService.getApplicationConfigs(
                    projectId,
                    arboId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
