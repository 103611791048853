<template>
    <b-modal
        :id="modalId"
        scrollable
        size="xl"
        title="Mes fichiers"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        body-class="p-0"
        hide-footer
    >
        <MediaBank :context="context" v-on="$listeners" />
    </b-modal>
</template>
<script>
import MediaBank from "./MediaBank.vue";
export default {
    components: {
        MediaBank,
    },

    props: {
        modalId: { type: String },
        context: { type: Object, required: true },
    },

    methods: {},
};
</script>
