import adminService from "../../services/admin";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        // ------------------------------------------------------------ Activity

        async getActivity() {
            try {
                return await adminService.getActivity();
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------- Installations

        async getInstallations() {
            try {
                return await adminService.getInstallations();
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ---------------------------------------------------------- ArboBuilds

        async countFSPArboBuilds(context, { filter }) {
            try {
                return await adminService.countFSPArboBuilds(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPArboBuilds(context, { filter, sortby, limit, offset }) {
            try {
                return await adminService.getFSPArboBuilds(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getArboBuild(context, { id }) {
            try {
                return await adminService.getArboBuild(id);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------------- Modules

        async getModules() {
            try {
                return await adminService.getModules();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createModule(
            context,
            { name, description, config, state, type },
        ) {
            try {
                await adminService.createModule(
                    name,
                    description,
                    config,
                    state,
                    type,
                );
                notifier.success(`Le module <b>${name}</b> a bien été créé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getModule(context, { moduleId }) {
            try {
                return await adminService.getModule(moduleId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateModule(
            context,
            { moduleId, name, description, config, state, type, version },
        ) {
            try {
                await adminService.updateModule(
                    moduleId,
                    name,
                    description,
                    config,
                    state,
                    type,
                    version,
                );
                notifier.success(`Le module a bien été mis à jour`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteModule(context, { moduleId }) {
            try {
                let response = await adminService.deleteModule(moduleId);
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------Project Modules

        async getProjectModules(context, { projectId }) {
            try {
                return await adminService.getProjectModules(projectId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createProjectModule(context, { projectId, moduleId }) {
            try {
                await adminService.createProjectModule(projectId, moduleId);
                notifier.success(`Le module a bien été ajouté`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getProjectModule(context, { projectId, moduleId }) {
            try {
                return await adminService.getProjectModule(projectId, moduleId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateProjectModule(
            context,
            { projectId, moduleId, target, config, data, enabled },
        ) {
            try {
                await adminService.updateProjectModule(
                    projectId,
                    moduleId,
                    target,
                    config,
                    data,
                    enabled,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteProjectModule(context, { projectId, moduleId }) {
            try {
                let response = await adminService.deleteProjectModule(
                    projectId,
                    moduleId,
                );
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ------------------------------------------------------Module Projects

        async countFSPProjectModules(context, { moduleId, filter }) {
            try {
                return await adminService.countFSPProjectModules(
                    moduleId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPProjectModules(
            context,
            { moduleId, filter, sortby, limit, offset },
        ) {
            try {
                return await adminService.getFSPProjectModules(
                    moduleId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        // ---------------------------------------------------------- TeamViewer

        async getTeamViewerGroups() {
            try {
                return await adminService.getTeamViewerGroups();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTeamViewerDevices(context, { groupid }) {
            try {
                return await adminService.getTeamViewerDevices(groupid);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTeamViewerDevice(context, { deviceId }) {
            try {
                return await adminService.getTeamViewerDevice(deviceId);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------------------------------- AdvancedSearch

        async advancedSearchGetAll(context, { search }) {
            try {
                return await adminService.advancedSearchGetAll(search);
            } catch (error) {
                APIError.handle(error);
            }
        },

        // --------------------------------------------------------------- Tools

        async cloneArbo(context, { config }) {
            try {
                await adminService.cloneArbo(config);
                notifier.success(`Les objets ont bien été dupliqués`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async cloneProject(context, { config }) {
            try {
                await adminService.cloneProject(config);
                notifier.success(`Le projet a bien été dupliqué`);
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
