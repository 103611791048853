export default function duration(ms) {
    const total = Math.floor(ms / 1000);

    const hours = Math.floor(total / 3600);
    const minutes = Math.floor((total % 3600) / 60);
    const seconds = total % 60;

    let result = "";
    if (hours) {
        result += hours + " h ";
        result += minutes + " min ";
    } else if (minutes) {
        result += minutes + " min ";
    }
    result += seconds + " s";

    return result;
}
