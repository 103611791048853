<template>
    <div id="main-container">
        <div class="k-flex-row k-flex-fill mb-2">
            <div>
                <small class="text-kalkin-1">Choix de la collection :</small>
                <b-select
                    size="sm"
                    v-model="selectedCollection"
                    :options="bankListOptions"
                    @change="loadSelectedCollection"
                />
            </div>
            <div>
                <small class="text-kalkin-1">Rechercher une icône :</small>
                <b-input
                    size="sm"
                    placeholder="Veuillez saisir un nom ou un mot-clé"
                    v-model="search"
                    trim
                ></b-input>
            </div>
        </div>

        <div id="content">
            <perfect-scrollbar class="h-100 w-100">
                <div id="grid">
                    <template v-for="media in filteredMediaList">
                        <div
                            class="icon-cell"
                            :class="{ selected: selectedMedia === media.url }"
                            :key="`${media.url}-cell`"
                            @click="selectedMedia = media.url"
                            @mouseenter="hoveredMedia = media.url"
                            @mouseleave="hoveredMedia = null"
                        >
                            <div
                                class="btn-quick-select"
                                v-if="hoveredMedia === media.url"
                                @click.stop="selectMedia(media.url)"
                            >
                                <b-icon-question-square-fill />
                                <b-icon-check-square-fill />
                            </div>
                            <b-img :src="`${media.url}?notrack`"></b-img>
                        </div>
                        <div
                            class="icon-row"
                            :key="`${media.url}-row`"
                            v-if="selectedMedia === media.url"
                        >
                            <div class="text-left">
                                <div>
                                    Nom de l'image :
                                    <b class="text-capitalize text-kalkin-1">
                                        {{ media.name | formatImageName }}
                                    </b>
                                </div>
                                <div>
                                    Mot-clés :
                                    <template v-for="tag in media.tags">
                                        <b-badge
                                            class="mr-1 pointer"
                                            variant="kalkin-1"
                                            :key="tag"
                                            @click="search = tag"
                                            >{{ tag }}</b-badge
                                        >
                                    </template>
                                    <template v-if="!media.tags">
                                        <i class="text-kalkin-1">Aucun</i>
                                    </template>
                                </div>
                            </div>

                            <b-button
                                size="sm"
                                variant="kalkin-1"
                                @click="selectMedia(media.url)"
                            >
                                Choisir cette image
                            </b-button>
                        </div>
                    </template>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import notifier from "@/tools/notifier";
import { deburr as _deburr, lowerCase as _lowerCase } from "lodash";

export default {
    props: {
        bankList: {
            type: Array,
            default: function() {
                return [];
            },
        },
    },

    data: function() {
        return {
            selectedCollection: null,
            collectionData: [],
            selectedMedia: null,
            hoveredMedia: null,
            search: "",
        };
    },

    computed: {
        bankListOptions() {
            return this.bankList.map(bank => {
                return { value: bank.url, text: bank.name };
            });
        },

        filteredMediaList() {
            return this.collectionData.filter(media => {
                let cleanedSearch = _lowerCase(_deburr(this.search));
                return media.search.some(term => term.includes(cleanedSearch));
            });
        },
    },

    watch: {
        bankList() {
            this.initCollections();
        },
    },

    methods: {
        async initCollections() {
            if (!this.selectedCollection && this.bankList.length) {
                this.selectedCollection = this.bankList[0].url;
                await this.loadSelectedCollection();
            }
        },

        async loadSelectedCollection() {
            try {
                const response = await axios.get(`${this.selectedCollection}`);
                this.collectionData = response.data.data;
                this.collectionData.forEach(media => {
                    let name = _lowerCase(_deburr(media.name));
                    let tags = (media.tags || []).map(tag =>
                        _lowerCase(_deburr(tag)),
                    );
                    media.search = [name, ...name.split(" "), ...tags];
                });
                this.selectedMedia = null;
            } catch (error) {
                notifier.error("Echec du chargement de la collection d'icônes");
            }
        },

        selectMedia(url) {
            this.$emit("icon-selected", url);
        },
    },

    async created() {
        await this.initCollections();
    },

    filters: {
        formatImageName(name) {
            return name.replace(/\.[^/.]+$/, "").replaceAll("_", " ");
        },
    },
};
</script>
<style lang="scss" scoped>
$cell-size: 70px;

#main-container {
    display: flex;
    flex-direction: column;

    background: #fff;
    padding: 15px 0;
    width: 100%;
    height: 100%;
}

#content {
    overflow: hidden;
    background: #eee;
    flex-grow: 1;
    padding: 2px;
    margin: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

#grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, $cell-size);
    grid-auto-flow: dense;
    justify-content: center;
    gap: 10px;

    margin: 8px;
    padding: 10px;
}

.icon-cell {
    position: relative;
    height: $cell-size;
    padding: 5px;
    border: 1px solid $primary-color-darker;
    border-radius: 6px;

    background: #888;
    cursor: pointer;
}
.icon-cell.selected {
    border: 4px solid $primary-color;
}
.icon-cell.selected::after {
    content: "";
    position: relative;
    display: block;
    width: 0;
    top: 9px;
    left: calc(#{$cell-size} / 2 - 19px);

    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid $primary-color;
}
.icon-cell:hover {
    background: #999;
}
.icon-cell:active {
    background: #aaa;
}

.icon-cell > img {
    width: 100%;
    height: 100%;
}

.icon-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-column: 1 / -1;

    width: 100%;
    height: 100px;
    border: 2px solid $primary-color;
    border-radius: 5px;

    background: #ddd;
    box-shadow: 0 0 5px #888;
}

.btn-quick-select {
    position: absolute;
    right: 5px;
    top: 5px;
    text-align: center;
    width: 20px;
    line-height: 20px;
}
.btn-quick-select svg {
    border-radius: 6px;
    color: $primary-color;
    background-color: white;
}
.btn-quick-select svg:first-child {
    display: inline;
}
.btn-quick-select svg:last-child {
    display: none;
}
.btn-quick-select:hover svg:first-child {
    display: none;
}
.btn-quick-select:hover svg:last-child {
    display: inline;
    font-size: 20px;
}
</style>
