<template>
    <div class="card-grid" :style="cssVars" v-if="data.length">
        <div v-for="item in data" :key="item.id">
            <slot :data="item" />
        </div>
        <slot name="last-card" />
    </div>
    <div class="p-5 text-center" v-else>
        <em>{{ empty }}</em>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Array, required: true },
        empty: { type: String, default: "Aucune donnée" },
        minCardSize: { type: String, default: "300px" },
        gap: { type: String, default: "10px" },
    },

    computed: {
        cssVars() {
            return {
                "--card-size": this.minCardSize,
                "--gap": this.gap,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--card-size), 1fr));
    gap: var(--gap);

    padding: 10px;
}
</style>
