<template>
    <MapAreaSelector v-model="value" />
</template>

<script>
import { abstractField } from "vue-form-generator";
import MapAreaSelector from "@/components/map/MapAreaSelector";

export default {
    components: {
        MapAreaSelector,
    },
    methods: {
        validate(calledParent) {
            if (this.disabled) return true;

            let isValid = false;

            this.clearValidationErrors();

            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText ||
                        "Veuillez selectionner une emprise géographique",
                );
            }

            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>
