import HTTP from "./http-common";

export default {
    async getBuildsStats() {
        const response = await HTTP({
            method: "GET",
            url: `/stats/builds`,
        });
        return response.data.data;
    },

    async getInstallationsStats() {
        const response = await HTTP({
            method: "GET",
            url: `/stats/installations`,
        });
        return response.data.data;
    },

    async getUsersStats() {
        const response = await HTTP({
            method: "GET",
            url: `/stats/users`,
        });
        return response.data.data;
    },
};
