<template>
    <b-modal
        :id="modalId"
        scrollable
        size="xl"
        title="Bibliothèque d'icônes"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <IconBank :bank-list="bankList" @icon-selected="onIconSelected" />
    </b-modal>
</template>
<script>
import IconBank from "./IconBank.vue";
export default {
    components: {
        IconBank,
    },

    props: {
        modalId: String,
        bankList: Array,
    },

    methods: {
        onIconSelected(icon) {
            this.$bvModal.hide(this.modalId);
            this.$emit("icon-selected", icon);
        },
    },
};
</script>
