export default function momentSinceISO(input) {
    const d = new Date(input);
    const d0 = new Date(input).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const diffDays = Math.round(Math.abs((today - d0) / 86400000));

    switch (diffDays) {
        case 0:
            return `Aujourd'hui à ${d.toLocaleTimeString()}`;
        case 1:
            return `Hier à ${d.toLocaleTimeString()}`;
        default:
            return `Le ${d.toLocaleDateString()} à ${d.toLocaleTimeString()}`;
    }
}
