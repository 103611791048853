const UNDEF = "Inconnu";
const ROLES = [
    "Suspendu",
    "Désactivé",
    UNDEF,
    "Membre",
    "Membre basique",
    "Membre vérifié",
    UNDEF,
    "Kalkin",
    "Kalkin Consultant",
    "Kalkin Manager",
    "Kalkin Développeur",
    UNDEF,
];
const OFFSET = 2;

export default function userRoleFromCreds(value) {
    if (value >= 10) {
        return "Administrateur";
    }
    return ROLES[value + OFFSET] || UNDEF;
}
