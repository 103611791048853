const filterFiles = require.context("@/filters/scripts", true, /\.js$/);
const filters = [];
for (const file of filterFiles.keys()) {
    const name = file.replace(/^\.\//, "").replace(/\.\w+$/, "");
    filters.push([name, import(`@/filters/scripts/${name}.js`)]);
}

export default {
    async install(Vue) {
        for (const [file, exported] of filters) {
            Vue.filter(file, (await exported).default);
        }
    },
};
