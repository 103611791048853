import Vue from "vue";
import Vuex from "vuex";

import userModule from "./modules/user";
import projectModule from "./modules/project";
import contactModule from "./modules/contact";
import arboModule from "./modules/arbo";
import arbosnapshotModule from "./modules/arbosnapshot";
import datasourceModule from "./modules/datasource";
import dispoModule from "./modules/dispo";
import applicationModule from "./modules/application";
import mediaModule from "./modules/media";
import cronjobModule from "./modules/cron_job";
import notifyModule from "./modules/notify";
import configModule from "./modules/config";
import statsModule from "./modules/stats";
import serverModule from "./modules/server";
import adminModule from "./modules/admin";
import kbsitModule from "./modules/kbsit";

import createPersistedState from "vuex-persistedstate";
import NotifyPlugin from "./plugins/notify";

import notifier from "../tools/notifier";

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        appVersion: process.env.APP_VERSION || "?",
    },

    getters: {
        appVersion: state => {
            return state.appVersion;
        },
    },

    modules: {
        user: userModule,
        project: projectModule,
        contact: contactModule,
        arbo: arboModule,
        arbosnapshot: arbosnapshotModule,
        datasource: datasourceModule,
        dispo: dispoModule,
        application: applicationModule,
        media: mediaModule,
        cronjob: cronjobModule,
        notify: notifyModule,
        config: configModule,
        stats: statsModule,
        server: serverModule,
        admin: adminModule,
        kbsit: kbsitModule,
    },

    plugins: [
        NotifyPlugin,
        createPersistedState({
            paths: ["user"],
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key),
            },
        }),
        createPersistedState({
            paths: ["notify"],
            storage: sessionStorage,
        }),
    ],
});

notifier.attachStore(store);

export default store;
