const fieldFiles = require.context(
    "@/components/form-generator/fields",
    false,
    /\.vue$/,
);

const fields = [];
for (const file of fieldFiles.keys()) {
    const name = file.replace(/^\.\//, "").replace(/\.\w+$/, "");
    fields.push([
        `field${name}`,
        import(`@/components/form-generator/fields/${name}.vue`),
    ]);
}

export default {
    async install(Vue) {
        for (const [file, exported] of fields) {
            Vue.component(file, (await exported).default);
        }
    },
};
