<template>
    <ArrayInput
        default="1.0"
        v-model="array"
        :min="schema.min"
        :max="schema.max"
        @validate="isArrayValid = $event"
    >
        <template v-slot="{ item, onInput }">
            <b-form-input
                class="k-input"
                type="number"
                :value="item"
                @change="onInput"
            ></b-form-input>
        </template>
    </ArrayInput>
</template>

<script>
import { abstractField } from "vue-form-generator";
import ArrayInput from "./common/ArrayInput.vue";

export default {
    components: {
        ArrayInput,
    },

    data: function() {
        return {
            isArrayValid: true,
        };
    },

    computed: {
        array: {
            get() {
                return this.value;
            },
            set(v) {
                this.value = v.map(item => Number(item));
            },
        },
    },

    methods: {
        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (this.value && !this.isArrayValid) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText ||
                        "La taille du tableau est incorrecte!",
                );
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>
