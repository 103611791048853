<template>
    <div class="k-flex-row k-flex-fill w-100">
        <div>
            <label class="k-text-sm">Sélection du module</label>
            <b-form-select
                :value="selectedModule"
                @input="onModuleChanged"
                :options="moduleOptions"
            />
        </div>
        <div>
            <label class="k-text-sm">Sélection de la collection</label>
            <b-form-select
                :value="selectedCollection"
                @input="value = $event"
                :options="collectionOptions"
                :disabled="!selectedModule"
            />
        </div>
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";
import { get as _get } from "lodash";

export default {
    data: function() {
        return {
            projectId: this.$route.params.projectId,

            modules: [],
            selectedModule: null,

            collectionOptions: [],
        };
    },

    computed: {
        moduleOptions() {
            return [
                {
                    value: null,
                    text: "-- Choisir un module --",
                    disabled: true,
                },
                ...this.modules.map(m => ({
                    value: m.ProjectModule,
                    text: m.name,
                })),
            ];
        },

        selectedCollection() {
            return this.value || null;
        },

        isValid() {
            return this.value !== null;
        },
    },

    methods: {
        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire !",
                );
            } else if (this.value && !this.isValid) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText ||
                        "Tous les champs doivent avoir une valeur !",
                );
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },

        async fetchModules() {
            const modules = await this.$store.dispatch(
                "project/getProjectModules",
                {
                    projectId: this.projectId,
                },
            );

            if (modules) {
                this.modules = modules.used;
            }
        },

        async onModuleChanged(selected) {
            this.selectedModule = selected;

            const serviceId = _get(selected, "data.config.service");
            const collectionIds = _get(selected, "data.config.collections");

            if (serviceId && Array.isArray(collectionIds)) {
                const collections = await Promise.all(
                    collectionIds.map(async c => {
                        const collection = await this.$store.dispatch(
                            "project/getMediaCollection",
                            {
                                projectId: this.projectId,
                                serviceId,
                                collectionId: c,
                            },
                        );
                        collection.service = serviceId;
                        return collection;
                    }),
                );
                this.collectionOptions = [
                    {
                        value: null,
                        text: "-- Choisir une collection --",
                        disabled: true,
                    },
                    ...collections.map(c => {
                        return {
                            value: {
                                id: c.id,
                                service: c.service,
                                module: this.selectedModule.ModuleId,
                            },
                            text: c.name,
                        };
                    }),
                ];
            } else {
                this.value = null;
                this.collectionOptions = [
                    {
                        value: null,
                        text: "-- Module non valide --",
                        disabled: true,
                    },
                ];
            }
        },
    },

    async beforeMount() {
        if (this.value === undefined) {
            this.value = 0;
        }
        if (this.projectId) {
            await this.fetchModules();
            if (this.value.module) {
                const selected = this.modules.find(
                    m => m.id === this.value.module,
                );
                if (selected) {
                    await this.onModuleChanged(selected.ProjectModule);
                }
            }
        }
    },

    mixins: [abstractField],
};
</script>
