<template>
    <div class="w-100 mb-2">
        <v-swatches
            class="swatches-picker"
            v-model="rgb"
            swatch-size="35"
            show-fallback
            fallback-input-type="color"
            fallback-input-class="btn-color-picker"
            show-border
            inline
            :swatches="swatches"
        ></v-swatches>
        <div class="w-100 d-flex">
            <b-form-input
                id="input-hexa"
                type="text"
                size="sm"
                v-model="hex"
            ></b-form-input>

            <div id="div-alpha">
                <div id="div-alpha-row-1">Alpha</div>
                <div id="div-alpha-row-2">
                    <b-form-input id="k-range" v-model="alpha" type="range" />
                    <small>{{ alpha }}%</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { abstractField } from "vue-form-generator";

function _intToHex(n) {
    return n
        .toString(16)
        .toUpperCase()
        .padStart(2, "0");
}
function _hexToInt(h) {
    return parseInt(h, 16);
}
function parseDomColor(c) {
    let ret = "#";
    c.split(",").map(function(value, index) {
        if (index < c.split(",").length - 1) {
            ret = ret + _intToHex(parseInt(value.trim(), 10));
        }
    });
    return ret;
}

export default {
    data: function() {
        this.swatches = ["#000000", "#FFFFFF"];
        // Recupere les couleurs de tout les menus
        var menus = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent
            .menus;

        for (var key in menus) {
            if (menus[key].MenuStyles[0]) {
                // Formatte la string pour recuperation des RGB
                var background = menus[
                    key
                ].MenuStyles[0].style.global.background_color.replace(
                    /[()]/g,
                    "",
                );
                var foreground = menus[
                    key
                ].MenuStyles[0].style.global.foreground_color.replace(
                    /[()]/g,
                    "",
                );
                // Push les couleurs des menus dans les swatches pour acces rapide si pas dans l'array
                if (!this.swatches.includes(parseDomColor(background))) {
                    this.swatches.push(parseDomColor(background));
                }
                if (!this.swatches.includes(parseDomColor(foreground))) {
                    this.swatches.push(parseDomColor(foreground));
                }
            }
        }

        return {
            isValid: true,
        };
    },

    computed: {
        // color works as middleware if we need to change output format
        color: {
            get() {
                let color = [255, 255, 255, 255];
                try {
                    return JSON.parse(`[${this.value.slice(1, -1)}]`);
                } catch (e) {
                    /* do nothing */
                }
                return color;
            },
            set(input) {
                this.isValid = true;
                this.value = `(${JSON.stringify(input).slice(1, -1)})`;
            },
        },

        rgb: {
            get() {
                const c = this.color;
                return (
                    "#" + _intToHex(c[0]) + _intToHex(c[1]) + _intToHex(c[2])
                );
            },
            set(input) {
                let c = this.color;
                c[0] = _hexToInt(input.substr(1, 2));
                c[1] = _hexToInt(input.substr(3, 2));
                c[2] = _hexToInt(input.substr(5, 2));
                this.color = c;
            },
        },

        alpha: {
            get() {
                const c = this.color;
                return Math.round(c[3] / 2.55);
            },
            set(input) {
                let c = this.color;
                c[3] = Math.round(input * 2.55);
                this.color = c;
            },
        },

        hex: {
            get() {
                const c = this.color;
                return (
                    "#" +
                    _intToHex(c[0]) +
                    _intToHex(c[1]) +
                    _intToHex(c[2]) +
                    _intToHex(c[3])
                );
            },
            set(input) {
                const match = input.match(/^(#[0-9a-fA-F]{8})$/i);
                if (match) {
                    this.isValid = true;
                    let c = this.color;
                    c[0] = _hexToInt(input.substr(1, 2));
                    c[1] = _hexToInt(input.substr(3, 2));
                    c[2] = _hexToInt(input.substr(5, 2));
                    c[3] = _hexToInt(input.substr(7, 2));

                    let ret =
                        "#" +
                        _intToHex(c[0]) +
                        _intToHex(c[1]) +
                        _intToHex(c[2]) +
                        _intToHex(c[3]);
                    this.swatches.push(ret);
                    this.color = c;
                } else {
                    this.isValid = false;
                    this.validate();
                }
            },
        },
    },

    methods: {
        validate(calledParent) {
            if (this.disabled) return true;

            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (!this.isValid) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "La couleur saisie est invalide!",
                );
            }
            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    mixins: [abstractField],
};
</script>

<style lang="scss">
.btn-color-picker {
    width: 55px !important;
    height: 55px !important;
}

.vue-swatches__swatch {
    margin-bottom: 10px;
}

.btn-color-picker:hover {
    cursor: pointer;
}
.swatches-picker > div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
</style>

<style lang="scss" scoped>
#input-hexa {
    width: 75px;
    padding: 3px;
    text-align: center;
    font-family: Consolas, "Courier New", Courier, monospace;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
}

#div-alpha {
    margin-left: 10px;
    width: calc(100% - 60px);
    height: 30px;
}
#div-alpha-row-1 {
    width: 100%;
    height: 40%;
    vertical-align: top;
    font-size: 12px;
    color: #999;
}

#div-alpha-row-2 {
    width: 100%;
    height: 60%;
}
#div-alpha-row-2 > input {
    width: calc(100% - 50px);
}
#div-alpha-row-2 > small {
    width: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: #999;
}
</style>
