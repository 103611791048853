<template>
    <div>
        <div class="k-flex-row mb-2">
            <b-form inline>
                Période
                <b-form-select
                    class="mx-2"
                    v-model="periodSelected"
                    :options="periodOptions"
                />
                du
                <DatePicker
                    class="mx-2"
                    v-if="periodSelected == 'custom'"
                    v-model="periodCustom"
                    type="date"
                    range
                    range-separator=" au "
                    placeholder="Sélectionner les dates de la période"
                    format="DD/MM/YYYY"
                    value-type="YYYY-MM-DD"
                    :clearable="false"
                />
                <template v-else>
                    {{ dateFrom | dateFromISO(true) }} au
                    {{ dateTo | dateFromISO(true) }}
                </template>
            </b-form>
        </div>
        <div class="grid-row-results k-card-1" style="background:#ddd">
            <div class="k-text-25px">Satisfaction moyenne</div>
            <div>
                <b-form-rating
                    :value="total.sum / total.count"
                    variant="kalkin-1"
                    readonly
                    inline
                    show-value
                    show-value-max
                    precision="1"
                />
                <div>
                    <small>{{ total.count }} évaluations</small>
                </div>
            </div>
            <div>
                <ChartBarNotation
                    canvas-id="chart~total"
                    :data="total.data"
                    :total="total.count"
                />
            </div>
        </div>
        <div
            class="grid-row-results k-card-1 mx-3"
            v-for="q in stats"
            :key="q.id"
        >
            <div>
                <i>{{ q.label }}</i>
            </div>
            <div>
                <b-form-rating
                    :value="q.sum / q.count"
                    variant="kalkin-1"
                    size="sm"
                    readonly
                    inline
                    show-value
                    show-value-max
                    precision="1"
                />
                <div>
                    <small>{{ q.count }} évaluations</small>
                </div>
            </div>
            <div>
                <ChartBarNotation
                    :canvas-id="`chart-${q.id}`"
                    :data="q.data"
                    :total="total.count"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import ChartBarNotation from "../components/satisfaction/ChartBarNotation.vue";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";
import { getDateFromToday } from "@/tools/dates";

const TIMESPAN_START = 1;

const PRESETS = {
    week: {
        text: "des 7 derniers jours",
        offset: TIMESPAN_START + 6,
    },
    month: {
        text: "des 30 derniers jours",
        offset: TIMESPAN_START + 29,
    },
    semester: {
        text: "des 6 derniers mois",
        offset: TIMESPAN_START + 182,
    },
    year: {
        text: "des 12 derniers mois",
        offset: TIMESPAN_START + 364,
    },
};

export default {
    components: {
        DatePicker,
        ChartBarNotation,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        config: { type: Object, required: true },
        data: { type: Object, required: true },
    },

    data: function() {
        return {
            configId: this.$route.params.configId,

            stats: [],
            total: {},

            periodSelected: "week",
            periodOptions: [
                ...Object.entries(PRESETS).reduce((acc, [key, val]) => {
                    acc.push({ value: key, text: val.text });
                    return acc;
                }, []),
                { value: "custom", text: "personnalisée" },
            ],
            periodCustom: [
                getDateFromToday(TIMESPAN_START + 150),
                getDateFromToday(TIMESPAN_START),
            ],
        };
    },

    computed: {
        dateFrom() {
            if (this.periodSelected === "custom") {
                return this.periodCustom[0];
            } else {
                return getDateFromToday(PRESETS[this.periodSelected].offset);
            }
        },

        dateTo() {
            if (this.periodSelected === "custom") {
                return this.periodCustom[1];
            } else {
                return getDateFromToday(TIMESPAN_START);
            }
        },
    },

    watch: {
        async dateFrom() {
            await this.fetchStats();
        },

        async dateTo() {
            await this.fetchStats();
        },
    },

    methods: {
        async fetchStats() {
            const installs = await this.$store.dispatch(
                "application/getInstallations",
                {
                    projectId: this.projectId,
                    configId: this.configId,
                },
            );
            const response = await this.$store.dispatch(
                "stats/getSatisfaction",
                {
                    projectId: this.projectId,
                    installations: installs.map(i => i.id),
                    start: this.dateFrom,
                    end: this.dateTo,
                },
            );

            let stats = this.data.questions.map(cur => {
                return {
                    id: cur.id,
                    label: cur.question,
                    data: {},
                    sum: 0,
                    count: 0,
                };
            }, {});
            let total = {
                sum: 0,
                count: 0,
                data: Array(6)
                    .fill()
                    .reduce((acc, cur, i) => ((acc[i] = 0), acc), {}),
            };

            for (const question of stats) {
                const votes = response[question.id] || {};
                question.data = Array(6)
                    .fill()
                    .reduce((acc, cur, i) => ((acc[i] = 0), acc), {});

                for (const [note, nb] of Object.entries(votes)) {
                    const inote = Math.round(note);
                    question.sum += note * nb;
                    total.sum += note * nb;
                    question.count += nb;
                    total.count += nb;
                    question.data[inote] = nb;
                    total.data[inote] = (total.data[inote] || 0) + nb;
                }
            }

            this.stats = stats;
            this.total = total;
        },
    },

    async beforeMount() {
        await this.fetchStats();
    },
};
</script>

<style lang="scss" scoped>
.grid-row-results {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    text-align: center;

    & > * {
        min-width: 0;
        min-height: 0;
    }

    grid-template-columns: 1fr;
    grid-template-areas:
        "title"
        "mark"
        "chart";

    & :nth-child(1) {
        grid-area: title;
    }
    & :nth-child(2) {
        grid-area: mark;
    }
    & :nth-child(3) {
        grid-area: chart;
    }
}

@media (min-width: 600px) {
    .grid-row-results {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "title mark"
            "chart chart";
    }
}

@media (min-width: 1200px) {
    .grid-row-results {
        grid-template-columns: 1fr 0.8fr 1.5fr;
        grid-template-areas: "title mark chart";
    }
}
</style>
