<template>
    <div id="page">
        <Sidebar
            width="250px"
            widthCollapsed="50px"
            @collapse="sidebarCollapsed = $event"
        />
        <div id="main" :class="{ collapsed: sidebarCollapsed }">
            <Breadcrumb id="breadcrumb" />
            <div id="content">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/misc/sidebar/Sidebar";
import Breadcrumb from "@/components/ui/Breadcrumb";

export default {
    components: {
        Sidebar,
        Breadcrumb,
    },

    data: function() {
        return {
            sidebarCollapsed: false,
        };
    },
};
</script>

<style lang="scss" scoped>
#page {
    display: flex;
    width: 100%;
    height: 100vh;
}

#main {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 0;
    width: calc(100% - 250px);

    &.collapsed {
        width: calc(100% - 50px);
    }
}

#breadcrumb {
    height: 40px;
}

#content {
    padding-left: 5px;
    overflow-y: auto;
    height: 100%;
}
</style>
