import contactService from "../../services/contact";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async getContact(context, { id }) {
            try {
                return await contactService.getContact(id);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createContact(context, { name, role, mail, address, phone }) {
            try {
                await contactService.createContact(
                    name,
                    role,
                    mail,
                    address,
                    phone,
                );
                notifier.success(`Le contact a bien été créé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateContact(
            context,
            { contactId, name, role, mail, address, phone },
        ) {
            try {
                await contactService.updateContact(
                    contactId,
                    name,
                    role,
                    mail,
                    address,
                    phone,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllContacts() {
            try {
                return await contactService.getAllContacts();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPContacts(context, { filter }) {
            try {
                return await contactService.countFSPContacts(filter);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPContacts(context, { filter, sortby, limit, offset }) {
            try {
                return await contactService.getFSPContacts(
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAllFiltred(context, { filterValue }) {
            try {
                return await contactService.getAllFiltered(filterValue);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteContact(context, { contactId }) {
            try {
                await contactService.deleteContact(contactId);
                notifier.success(`Le contact a bien été supprimé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPContactProjects(context, { contactId, filter }) {
            try {
                return await contactService.countFSPContactProjects(
                    contactId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPContactProjects(
            context,
            { contactId, filter, sortby, limit, offset },
        ) {
            try {
                return await contactService.getFSPContactProjects(
                    contactId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
