import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// *** Plugins ***
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSelectImage from "vue-select-image";
import VueFormGenerator from "vue-form-generator";
import PerfectScrollbar from "vue2-perfect-scrollbar";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSelectImage);
Vue.use(VueFormGenerator);
Vue.use(PerfectScrollbar);

import filters from "@/filters";
import projectModuleComponents from "@/components/project-module";
import formGeneratorFields from "@/components/form-generator";

Vue.use(filters);
Vue.use(projectModuleComponents);
Vue.use(formGeneratorFields);

// *** Components ***
import VSwatches from "vue-swatches";
import Multiselect from "vue-multiselect";
import DefaultLayout from "./layouts/default.vue";
import LightLayout from "./layouts/light.vue";
import NoBreadcrumbLayout from "./layouts/no-breadcrumb.vue";

Vue.component("v-swatches", VSwatches);
Vue.component("multiselect", Multiselect);
Vue.component("default-layout", DefaultLayout);
Vue.component("light-layout", LightLayout);
Vue.component("no-breadcrumb-layout", NoBreadcrumbLayout);

// *** i18n ***
import VFGTranslator from "@/i18n/VueFormGenerator";
VFGTranslator.translate(VueFormGenerator);

// *** CSS ***
import "@/assets/styles/reset.scss";
import "@/assets/styles/bootstrap-custom.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select-image/dist/vue-select-image.css";
import "vue-form-generator/dist/vfg.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "vue-swatches/dist/vue-swatches.css";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import "@/assets/styles/index.scss";

// *** Directives ***
Vue.directive("visible", function(el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
});

// *** Others ***
Vue.config.productionTip = false;

const vm = new Vue({
    router,
    store,
    render: function(h) {
        return h(App);
    },
}).$mount("#app");

export default vm;
