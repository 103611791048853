<template>
    <div class="k-card-3">
        <div class="d-flex">
            <ImagePreview
                width="100px"
                height="100px"
                fontSize="15px"
                :url="url"
                :extension="extension"
                :downloadable="true"
            />
            <div class="d-flex flex-column ml-2">
                <div class="flex-grow-1 text-kalkin-2">
                    <b>{{ name }}</b>
                </div>

                <div>
                    <small>
                        Créé le
                        <span class="text-kalkin-2">
                            {{ media.createdAt | dateFromISO(true) }}
                        </span>
                    </small>
                </div>
                <div>
                    <small>
                        Modifié le
                        <span class="text-kalkin-2">
                            {{ media.updatedAt | dateFromISO(true) }}
                        </span>
                    </small>
                </div>
            </div>
        </div>
        <div class="k-flex-row flex-nowrap">
            <div class="k-flex-row">
                <b-badge
                    class="mx-1"
                    variant="secondary"
                    v-for="tag in tags"
                    :key="tag"
                >
                    {{ tag }}
                </b-badge>
            </div>
            <b-button
                size="sm"
                variant="kalkin-1"
                @click="$emit('select', media)"
            >
                Sélectionner
            </b-button>
        </div>
    </div>
</template>

<script>
import ImagePreview from "@/components/ui/item/ImagePreview.vue";
export default {
    components: {
        ImagePreview,
    },

    props: {
        media: Object,
    },

    data: function() {
        return {
            canEdit: this.$store.getters["project/hasWriterCredentials"],
        };
    },

    computed: {
        name() {
            return (
                (this.media.metadata && this.media.metadata.title) ||
                this.media.name
            );
        },

        extension() {
            return this.media.name.split(".").pop();
        },

        url() {
            return this.media.url + "?notrack=true&time=" + Date.now();
        },

        tags() {
            return this.media.metadata && this.media.metadata.tags;
        },
    },
};
</script>
