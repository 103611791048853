import statsService from "../../services/stats";
import APIError from "../../tools/APIError";

export default {
    namespaced: true,

    actions: {
        async getActivity(context, { projectId, installations, start, end }) {
            try {
                return await statsService.getActivity(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesTotal(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getTouchesTotal(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesPerDay(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getTouchesPerDay(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesPerWeekday(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getTouchesPerWeekday(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesPerHour(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getTouchesPerHour(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getSessionsTotal(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getSessionsTotal(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getSessionsPerDay(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getSessionsPerDay(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getSessionsDuration(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getSessionsDuration(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesOnFiches(
            context,
            { projectId, installations, start, end, limit },
        ) {
            try {
                return await statsService.getTouchesOnFiches(
                    projectId,
                    installations,
                    start,
                    end,
                    limit,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getTouchesOnMenus(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getTouchesOnMenus(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getLastContact(context, { projectId, installations }) {
            try {
                return await statsService.getLastContact(
                    projectId,
                    installations,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getSatisfaction(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getSatisfaction(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getSatisfactionPerDay(
            context,
            { projectId, installations, start, end },
        ) {
            try {
                return await statsService.getSatisfactionPerDay(
                    projectId,
                    installations,
                    start,
                    end,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
