import HTTP from "./http-common";

export default {
    async getContact(contactId) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/contact/${contactId}`,
        });
        return response.data.data;
    },

    async createContact(name, role, mail, address, phone) {
        const response = await HTTP({
            method: "POST",
            url: `/admin/contacts`,
            data: { name, role, mail, address, phone },
        });
        return response.data.data;
    },

    async updateContact(contactId, name, role, mail, address, phone) {
        const response = await HTTP({
            method: "PUT",
            url: `/admin/contact/${contactId}`,
            data: { name, role, mail, address, phone },
        });
        return response.data.data;
    },

    async getAllContacts() {
        const response = await HTTP({
            method: "GET",
            url: "/admin/contacts",
        });
        return response.data.data;
    },

    async countFSPContacts(filter) {
        const response = await HTTP({
            method: "HEAD",
            url: "/admin/contacts",
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPContacts(filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/contacts",
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    //TODO Remove
    async getAllFiltered(filterValue) {
        const response = await HTTP({
            method: "GET",
            url: "/admin/contacts",
            params: {
                filter: `name substring ${filterValue}`,
            },
        });
        return response.data.data;
    },

    async deleteContact(contactId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/admin/contact/${contactId}`,
        });
        return response.data.data;
    },

    async countFSPContactProjects(contactId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/admin/contact/${contactId}/projects`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPContactProjects(contactId, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/admin/contact/${contactId}/projects`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },
};
