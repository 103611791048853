<template>
    <GridCardBasic
        :data="medias"
        empty="Aucun média dans la collection"
        minCardSize="250px"
    >
        <template v-slot="{ data }">
            <CardMedia :media="data" @update="$emit('update-media', data)" />
        </template>
    </GridCardBasic>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardMedia from "./CardMedia.vue";
export default {
    components: {
        GridCardBasic,
        CardMedia,
    },

    props: {
        projectId: [String, Number],
        serviceId: String,
        collectionId: [String, Number],
    },

    data: function() {
        return {
            medias: [],
        };
    },

    methods: {
        async fetchMedias() {
            this.medias = await this.$store.dispatch("project/getMedias", {
                projectId: this.projectId,
                serviceId: this.serviceId,
                collectionId: this.collectionId,
            });
        },
    },

    async beforeMount() {
        await this.fetchMedias();
    },
};
</script>

<style></style>
