import HTTP from "./http-common";

export default {
    async getInstances(role) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/`,
        });
        return response.data.data;
    },

    async getFormat(role, sit) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/format`,
        });
        return response.data.data;
    },

    async countFSPProjects(role, sit, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/kbsit/${role}/${sit}/projects`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPProjects(role, sit, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/projects`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getProject(role, sit, projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}`,
        });
        return response.data.data;
    },

    async createProject(role, sit, name, meta) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/projects/`,
            data: { name, meta },
        });
        return response.data.data;
    },

    async updateProject(role, sit, projectId, name, meta) {
        const response = await HTTP({
            method: "PUT",
            url: `/kbsit/${role}/${sit}/project/${projectId}`,
            data: { name, meta },
        });
        return response.data.data;
    },

    async countFSPItems(role, sit, projectId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/kbsit/${role}/${sit}/project/${projectId}/items`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPItems(role, sit, projectId, filter, sortby, limit, offset) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/items`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async createItem(role, sit, projectId, name, visible, data) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/items`,
            data: { name, visible, data },
        });
        return response.data.data;
    },

    async updateItem(role, sit, projectId, ficheId, name, visible, data) {
        const response = await HTTP({
            method: "PUT",
            url: `/kbsit/${role}/${sit}/project/${projectId}/item/${ficheId}`,
            data: { name, visible, data },
        });
        return response.data.data;
    },

    async deleteItem(role, sit, projectId, ficheId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/kbsit/${role}/${sit}/project/${projectId}/item/${ficheId}`,
        });
        return response.data.data;
    },

    //----------------------------------------------------------- DataSelections

    async countFSPDataSelections(role, sit, projectId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/kbsit/${role}/${sit}/project/${projectId}/dataselections`,
            params: {
                filter,
            },
        });
        return response.headers["pagination-count"];
    },

    async getFSPDataSelections(
        role,
        sit,
        projectId,
        filter,
        sortby,
        limit,
        offset,
    ) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/dataselections`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async createSelection(role, sit, projectId, name) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/dataselections`,
            data: { name },
        });
        return response.data.data;
    },

    async updateSelection(role, sit, projectId, selectionId, name) {
        const response = await HTTP({
            method: "PUT",
            url: `/kbsit/${role}/${sit}/project/${projectId}/dataselection/${selectionId}`,
            data: { name },
        });
        return response.data.data;
    },

    async deleteSelection(role, sit, projectId, selectionId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/kbsit/${role}/${sit}/project/${projectId}/dataselection/${selectionId}`,
        });
        return response.data.data;
    },

    //-------------------------------------------------------------------- Assos

    async getAssosItems(role, sit, projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/selecteditems/items`,
        });
        return response.data.data;
    },

    async getAssosDataSelections(role, sit, projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/selecteditems/dataselections`,
        });
        return response.data.data;
    },

    async getAssos(role, sit, projectId) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/selecteditems`,
        });
        return response.data.data;
    },

    async createAsso(role, sit, projectId, itemId, selectionId) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/selecteditems`,
            data: { itemId, selectionId },
        });
        return response.data.data;
    },

    async deleteAsso(role, sit, projectId, itemId, selectionId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/kbsit/${role}/${sit}/project/${projectId}/selecteditems/${selectionId}/${itemId}`,
        });
        return response.data.data;
    },

    //------------------------------------------------------------------- Medias

    async createMediaCollection(role, sit, projectId, data) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/collections`,
            data: { data },
        });
        return response.data.data;
    },

    async countFSPMedias(role, sit, projectId, collectionId, filter) {
        const response = await HTTP({
            method: "HEAD",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/medias`,
            params: { filter },
        });
        return response.headers["pagination-count"];
    },

    async getFSPMedias(
        role,
        sit,
        projectId,
        collectionId,
        filter,
        sortby,
        limit,
        offset,
    ) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/medias`,
            params: { filter, sortby, limit, offset },
        });
        return response.data.data;
    },

    async getMedia(role, sit, projectId, collectionId, mediaId) {
        const response = await HTTP({
            method: "GET",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/media/${mediaId}`,
        });
        return response.data.data;
    },

    async createMedia(
        role,
        sit,
        projectId,
        collectionId,
        file,
        name,
        metadata,
    ) {
        let bodyFormData = new FormData();
        bodyFormData.set("name", name);
        if (metadata) {
            bodyFormData.set("metadata", JSON.stringify(metadata));
        }
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/medias`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },

    async updateMedia(
        role,
        sit,
        projectId,
        collectionId,
        mediaId,
        file,
        metadata,
    ) {
        let bodyFormData = new FormData();
        bodyFormData.set("metadata", JSON.stringify(metadata));
        bodyFormData.append("file", file);
        const response = await HTTP({
            method: "PUT",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/media/${mediaId}`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data.data;
    },

    async deleteMedia(role, sit, projectId, collectionId, mediaId) {
        const response = await HTTP({
            method: "DELETE",
            url: `/kbsit/${role}/${sit}/project/${projectId}/media/${collectionId}/media/${mediaId}`,
        });
        return response.data.data;
    },

    //------------------------------------------------------------------- Gsheet

    async runGsheetsCommand(role, sit, projectId, command) {
        const response = await HTTP({
            method: "POST",
            url: `/kbsit/${role}/${sit}/project/${projectId}/gsheets/${command}`,
        });
        return response.data.data;
    },
};
