<template>
    <div id="page">
        <Sidebar
            width="250px"
            widthCollapsed="50px"
            @collapse="sidebarCollapsed = $event"
        />
        <div id="main" :class="{ collapsed: sidebarCollapsed }">
            <slot />
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/misc/sidebar/Sidebar";

export default {
    components: {
        Sidebar,
    },

    data: function() {
        return {
            sidebarCollapsed: true,
        };
    },
};
</script>

<style lang="scss" scoped>
#page {
    display: flex;
    width: 100%;
    height: 100vh;
}

#main {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 15px 10px;
    overflow-y: auto;

    width: calc(100% - 250px);

    &.collapsed {
        width: calc(100% - 50px);
    }
}
</style>
