import kbsitService from "../../services/kbsit";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,

    actions: {
        async getInstances(context) {
            try {
                return await kbsitService.getInstances(
                    _getRoleFromContext(context),
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFormat(context, { sit }) {
            try {
                return await kbsitService.getFormat(
                    _getRoleFromContext(context),
                    sit,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPProjects(context, { sit, filter }) {
            try {
                return await kbsitService.countFSPProjects(
                    _getRoleFromContext(context),
                    sit,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPProjects(context, { sit, filter, sortby, limit, offset }) {
            try {
                return await kbsitService.getFSPProjects(
                    _getRoleFromContext(context),
                    sit,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getProject(context, { sit, projectId }) {
            try {
                return await kbsitService.getProject(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createProject(context, { sit, name, meta }) {
            try {
                await kbsitService.createProject(
                    _getRoleFromContext(context),
                    sit,
                    name,
                    meta,
                );
                notifier.success(`Le projet a bien été créé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateProject(context, { sit, projectId, name, meta }) {
            try {
                await kbsitService.updateProject(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    name,
                    meta,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPItems(context, { sit, projectId, filter }) {
            try {
                return await kbsitService.countFSPItems(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPItems(
            context,
            { sit, projectId, filter, sortby, limit, offset },
        ) {
            try {
                return await kbsitService.getFSPItems(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createItem(context, { sit, projectId, name, visible, data }) {
            try {
                await kbsitService.createItem(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    name,
                    visible,
                    data,
                );
                notifier.success(`La fiche a bien été créée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateItem(
            context,
            { sit, projectId, ficheId, name, visible, data },
        ) {
            try {
                await kbsitService.updateItem(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    ficheId,
                    name,
                    visible,
                    data,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteItem(context, { sit, projectId, ficheId }) {
            try {
                await kbsitService.deleteItem(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    ficheId,
                );
                notifier.success(`La fiche a bien été supprimée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        //------------------------------------------------------- DataSelections

        async countFSPDataSelections(context, { sit, projectId, filter }) {
            try {
                return await kbsitService.countFSPDataSelections(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPDataSelections(
            context,
            { sit, projectId, filter, sortby, limit, offset },
        ) {
            try {
                return await kbsitService.getFSPDataSelections(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createSelection(context, { sit, projectId, name }) {
            try {
                await kbsitService.createSelection(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    name,
                );
                notifier.success(`La sélection a bien été créée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateSelection(context, { sit, projectId, selectionId, name }) {
            try {
                await kbsitService.updateSelection(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    selectionId,
                    name,
                );
                notifier.saved();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteSelection(context, { sit, projectId, selectionId }) {
            try {
                await kbsitService.deleteSelection(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    selectionId,
                );
                notifier.success(`La sélection a bien été supprimée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        //---------------------------------------------------------------- Assos

        async getAssosItems(context, { sit, projectId }) {
            try {
                return await kbsitService.getAssosItems(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAssosDataSelections(context, { sit, projectId }) {
            try {
                return await kbsitService.getAssosDataSelections(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getAssos(context, { sit, projectId }) {
            try {
                return await kbsitService.getAssos(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createAsso(context, { sit, projectId, itemId, selectionId }) {
            try {
                await kbsitService.createAsso(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    itemId,
                    selectionId,
                );
                return true;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteAsso(context, { sit, projectId, itemId, selectionId }) {
            try {
                await kbsitService.deleteAsso(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    itemId,
                    selectionId,
                );
                return true;
            } catch (error) {
                APIError.handle(error);
            }
        },

        //--------------------------------------------------------------- Medias

        async createMediaCollection(context, { sit, projectId, data }) {
            try {
                await kbsitService.createMediaCollection(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    data,
                );
                notifier.success(`La collection a bien été créée`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        async countFSPMedias(
            context,
            { sit, projectId, collectionId, filter },
        ) {
            try {
                return await kbsitService.countFSPMedias(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    filter,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getFSPMedias(
            context,
            { sit, projectId, collectionId, filter, sortby, limit, offset },
        ) {
            try {
                return await kbsitService.getFSPMedias(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    filter,
                    sortby,
                    limit,
                    offset,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getMedia(context, { sit, projectId, collectionId, mediaId }) {
            try {
                return await kbsitService.getMedia(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    mediaId,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async createMedia(
            context,
            { sit, projectId, collectionId, file, name, metadata },
        ) {
            try {
                return await kbsitService.createMedia(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    file,
                    name,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async updateMedia(
            context,
            { sit, projectId, collectionId, mediaId, file, metadata },
        ) {
            try {
                return await kbsitService.updateMedia(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    mediaId,
                    file,
                    metadata,
                );
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMedia(context, { sit, projectId, collectionId, mediaId }) {
            try {
                await kbsitService.deleteMedia(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    collectionId,
                    mediaId,
                );
                notifier.success(`La média a bien été supprimé`);
            } catch (error) {
                APIError.handle(error);
            }
        },

        //--------------------------------------------------------------- Gsheet

        async runGsheetsCommand(context, { sit, projectId, command }) {
            try {
                await kbsitService.runGsheetsCommand(
                    _getRoleFromContext(context),
                    sit,
                    projectId,
                    command,
                );
                notifier.saved();
                return true;
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};

function _getRoleFromContext({ rootState }) {
    try {
        const creds = rootState.user.user.credentials;
        if (creds >= 5) {
            return "admin";
        }
    } catch (error) {
        //do nothing
    }
    return "member";
}
