<template>
    <GenericForm
        class="k-sub-form"
        :class="{ vertical: schema.vertical }"
        :schema="genericFormSchema"
        :model="localData"
        :payload="payload"
        @updated="onUpdate"
        @validated="onValidation"
    />
</template>

<script>
import Vue from "vue";
import { abstractField } from "vue-form-generator";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import notifier from "@/tools/notifier";

export default {
    components: {
        GenericForm,
    },

    data: function() {
        let schema = this.createSchema();
        this.initLocalData(schema);
        return {
            isFormValid: true,
            payload: this.formOptions.payload,

            genericFormSchema: schema,
            localData: this.initLocalData(schema),
        };
    },

    watch: {
        formOptions(v) {
            this.payload = v.payload;
        },
    },

    methods: {
        createSchema() {
            try {
                return {
                    ...Formatter.convertCOREtoFORM(this.schema.config),
                };
            } catch (error) {
                notifier.error("Une erreur s'est produite, essayez plus tard.");
                return { fields: [] };
            }
        },

        initLocalData(schema) {
            if (this.value) {
                return Vue.util.extend({}, this.value);
            } else {
                return Formatter.createDefaultFORMObject(schema);
            }
        },

        onUpdate(data) {
            this.localData = data;
            this.value = data;
        },

        onValidation(state) {
            this.isFormValid = state;
            this.validate(); //Pour synchro children/parent
        },

        validate(calledParent) {
            if (this.disabled) return true;
            let isValid = true;
            this.clearValidationErrors();
            if (this.schema.required && !this.value) {
                isValid = false;
                this.errors.push(
                    this.schema.errorText || "Ce champ est obligatoire!",
                );
            }
            if (this.value) {
                if (!this.isFormValid) {
                    isValid = false;
                    this.errors.push(
                        this.schema.errorText || "Erreur(s) dans le formulaire",
                    );
                }
            }

            if (this.schema.onValidated) {
                this.schema.onValidated.call(
                    this,
                    this.model,
                    this.errors,
                    this.schema,
                );
            }
            if (!calledParent)
                this.$emit("validated", isValid, this.errors, this);

            return this.errors;
        },
    },

    beforeMount() {
        this.validate();
    },

    mixins: [abstractField],
};
</script>

<style lang="scss">
.k-sub-form {
    width: 100%;
    border: 1px solid $secondary-color;
    border-radius: 6px;
    border-left-width: 4px;
    padding: 10px;
    margin: 5px;
    background: lighten($secondary-color, 45);

    & fieldset {
        display: flex;
        flex: 1;
    }
    &.vertical fieldset {
        flex-direction: column;
    }
}
</style>
