import serverService from "../../services/server";
import APIError from "../../tools/APIError";

export default {
    namespaced: true,

    actions: {
        async getBuildsStats() {
            try {
                return await serverService.getBuildsStats();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getInstallationsStats() {
            try {
                return await serverService.getInstallationsStats();
            } catch (error) {
                APIError.handle(error);
            }
        },

        async getUsersStats() {
            try {
                return await serverService.getUsersStats();
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
