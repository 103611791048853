<template>
    <div>
        <div class="k-flex-row k-flex-fill">
            <div class="text-center">
                <ImagePreview
                    width="min(50vh,50vw)"
                    height="min(50vh,50vw)"
                    fontSize="25px"
                    :url="previewURL"
                />
            </div>
            <div class="k-flex-col align-self-stretch">
                <div>
                    <b-form-group>
                        <label>Fichier</label>
                        <b-form-file
                            v-model="localMedia.file"
                            :accept="context.mime"
                            :placeholder="localMedia.name"
                            browse-text="Choisir un fichier"
                        />
                        <b-form-invalid-feedback :state="isFileValid">
                            Veuillez sélectionner un fichier
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                        <label>Titre du média (optionnel)</label>
                        <b-form-input
                            v-model="localMedia.title"
                            type="text"
                            placeholder="Aucun titre"
                        />
                    </b-form-group>

                    <b-form-group>
                        <label>Mots-clés (optionnel)</label>
                        <TagInput v-model="localMedia.tags" />
                    </b-form-group>

                    <b-form-group v-if="isEditMode && isAdmin">
                        <label>URL publique</label>
                        <b-form-input :value="media.url" disabled />
                    </b-form-group>
                </div>
                <div class="flex-grow-1" />
                <div class="k-flex-row k-flex-center">
                    <b-button
                        v-if="!isEditMode"
                        variant="kalkin-2"
                        @click="createMedia()"
                    >
                        <b-icon-folder-plus /> Créer le média
                    </b-button>

                    <b-button
                        v-if="isAdmin && isEditMode"
                        size="sm"
                        variant="outline-danger"
                        v-b-modal.ModalDanger
                    >
                        <b-icon-trash /> Supprimer le média
                    </b-button>

                    <b-button
                        v-if="isEditMode"
                        size="sm"
                        variant="kalkin-2"
                        @click="updateMedia()"
                    >
                        <b-icon-check-circle-fill /> Enregistrer les
                        modifications
                    </b-button>
                </div>
            </div>
        </div>
        <ModalDanger
            message="Voulez-vous vraiment supprimer ce média?"
            @confirmed="deleteMedia"
        />
    </div>
</template>
<script>
import ImagePreview from "@/components/ui/item/ImagePreview.vue";
import TagInput from "@/components/ui/item/TagInput.vue";
import ModalDanger from "@/components/modal/ModalDanger";
export default {
    components: {
        ImagePreview,
        TagInput,
        ModalDanger,
    },

    props: {
        context: { type: Object, required: true },
        media: { type: Object, required: false },
    },

    data: function() {
        let data = {
            isEditMode: false,
            localMedia: {
                name: "Aucun fichier",
                metadata: {},
                file: undefined,
                tags: [],
                title: undefined,
            },
        };

        if (this.media) {
            data.isEditMode = true;
            const metadata = this.media.metadata || {};
            data.localMedia = {
                ...data.localMedia,
                ...this.media,
                tags: [...(metadata.tags || [])],
                title: metadata.title,
            };
        }
        return data;
    },

    computed: {
        isAdmin() {
            return this.context.target === "media";
        },

        previewURL() {
            if (this.localMedia.file) {
                return URL.createObjectURL(this.localMedia.file);
            }
            return this.localMedia.url + "?notrack=true&time=" + Date.now();
        },

        isFileValid() {
            return !(!this.localMedia.file && !this.localMedia.filename);
        },

        isValid() {
            return this.isFileValid;
        },
    },

    methods: {
        createMedia: async function() {
            if (this.isValid) {
                const res = await this.$store.dispatch(
                    this.context.target + "/createMedia",
                    {
                        ...this.context,
                        file: this.localMedia.file,
                        name: this.localMedia.file.name,
                        metadata: {
                            ...this.localMedia.metadata,
                            tags: this.localMedia.tags,
                            title: this.localMedia.title,
                        },
                    },
                );
                this.$emit("saved", res);
            }
        },

        updateMedia: async function() {
            if (this.isValid) {
                await this.$store.dispatch(
                    this.context.target + "/updateMedia",
                    {
                        ...this.context,
                        mediaId: this.media.id,
                        file: this.localMedia.file,
                        metadata: {
                            ...this.localMedia.metadata,
                            tags: this.localMedia.tags,
                            title: this.localMedia.title,
                        },
                    },
                );
                this.$emit("saved");
            }
        },

        deleteMedia: async function() {
            if (this.isAdmin) {
                await this.$store.dispatch("media/deleteMedia", {
                    ...this.context,
                    mediaId: this.media.id,
                });
                this.$emit("saved");
            }
        },
    },
};
</script>
