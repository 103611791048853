export default {
    namespaced: true,
    state: {
        messages: [],
        currentMessage: null,
    },
    getters: {
        hasMessageUnsent(state) {
            return state.messages.length;
        },
    },
    actions: {
        pushMessage({ commit, state }, payload) {
            if (!state.currentMessage) {
                commit("newMessage", payload);
            } else {
                commit("push", payload);
            }
        },

        ackMessage({ commit, state, rootState }) {
            // On va regarder si un utilisateur est connecté
            if (rootState.user.loggedIn) {
                // Si oui, on passe au message suivant
                const message = state.messages.shift();
                if (message) {
                    return commit("newMessage", message);
                }
            } else {
                // Si non, on flush la pile de notifications
                commit("flush");
            }
            // Si on atteint cette ligne, c'est qu'il n'y a aucun message
            commit("clearMessage");
        },
    },
    mutations: {
        push(state, payload) {
            state.messages.push(payload);
        },

        newMessage(state, payload) {
            state.currentMessage = payload;
        },

        clearMessage(state) {
            state.currentMessage = null;
        },

        flush(state) {
            state.messages = [];
        },
    },
};
